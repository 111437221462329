/*
 * @Author: LiuJiazhu 
 * @Date: 2019-02-18 20:52:54 
 * @Last Modified by: LiuJiazhu
 * @Last Modified time: 2019-03-20 16:31:18
 */

import React from 'react';
import './index.scss';
import { Form, Input, Select, Button, Row, Col, Table, Modal, Icon, Upload, message, Cascader, Tag, Radio } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import axios from '../../../api'
import { TABS } from '../../../store/action';
import { connect } from 'react-redux';
import { commonCloseTab } from '../../../util/tabs';
import util from '../../../util';
const { Option } = Select;
const uploadButton = (
    <div>
        <Icon type='plus' />
        <div className="ant-upload-text">上传</div>
    </div>
);
const sortFieldNames = {
    label: 'categoryName',
    value: 'id'
}
const MENUKEY = 'AddOrEditGoods';
class AddOrEditGoods extends React.Component {
    constructor() {
        super();
        let that = this;
        this.state = {
            saveLoading: false,
            updateForm: {},
            goodsId: '',
            editorState: BraftEditor.createEditorState(null),
            goodsDetailUrl: '',
            newDataTags: [],
            skuList: [],
            formLayout: 'horizontal',
            showSelect: false,
            sortList: [],
            arrStandard: [{
                attrValue: '',
                tags: [],
                inputVisible: false,
                inputValue: '',
                closeable: true,
            }],
            compareList: [],
            fileList: [],
            arrBrand: [],
            goodsSpecs: [],
            columnsSku: that.initColumHeader(),
            arrParameter: [{ k: '', v: '' }]
        }
        this.oldBarCodeList = [];
    }
    async componentDidMount() {
        const { id } = this.getParams();
        let tab = this.props.tabList.find((v) => v.authorityUrl === MENUKEY);
        if (id) {
            this.setState({
                goodsId: id
            });
            this.getInfoById(id);
            tab.params.reload = false;
        }
        this.handleSort();
    }
    componentWillReceiveProps(nextProps) {
        const { reload } = this.getParams(nextProps);
        const { id } = this.getParams(nextProps);
        const nextActiveKey = nextProps.activeKey;
        if (nextActiveKey === MENUKEY && reload) {
            this.oldBarCodeList = [];
            let tab = this.props.tabList.find((v) => v.authorityUrl === MENUKEY);
            tab.params.reload = false;
            this.resetAllFromInfo();
            if (id) {
                this.setState({
                    goodsId: id
                });
                this.getInfoById(id);
                return false;
            }
        }

    }
    // 获取页面参数
    getParams = (props = this.props) => {
        const { tabList } = props;
        const tabItem = tabList.find((v) => v.authorityUrl === MENUKEY);
        return tabItem.params;
    }
    beforeUpload = (file) => {
        if(!util.validateFileSize(file)){
          return false;
        }
        return true;
    }
    // 失焦校验
    barCodeBlur = async (index, e) => {
        const { value } = e.target;
        const arr = this.state.skuList;
        let isOnly = true;
        if (value !== '' && this.oldBarCodeList[index] !== value) {
            isOnly = await this.checkBarCode(value);
            arr.forEach((item, idx) => {
                if (item['barCode'] === value && idx !== index) {
                    isOnly = false;
                }
            })
        }
        arr[index]['isOnly'] = isOnly;
        this.setState({
            skuList: arr
        });
    }
    // 初始化表头
    initColumHeader = () => {
        return [{
            title: '商品图片',
            align: 'center',
            dataIndex: 'productLogo',
            key: 'productLogo',
            render: (text, record, index) => {
                return (<Upload
                    beforeUpload={this.beforeUpload}
                    customRequest={file => this.updateImg(file, record, index)}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                >
                    {text ? <img src={text} alt="avatar" /> : uploadButton}
                </Upload>)
            }
        }, {
            title: '条形码',
            align: 'center',
            dataIndex: 'barCode',
            key: 'barCode',
            render: (text, record, index) => {
                return (<>
                    <Input value={text} onBlur={this.barCodeBlur.bind(null, index)} onChange={this.handleSkuChange.bind(this, record, index, 'barCode')} />
                    {record.hasOwnProperty('isOnly') && !record.isOnly && <p style={{ color: 'red' }}>条形码重复</p>}
                </>)
            }
        }, {
            title: '市场价',
            align: 'center',
            dataIndex: 'marketPrice',
            key: 'marketPrice',
            render: (text, record, index) => {
                return (<Input value={text} onChange={this.handleSkuChange.bind(this, record, index, 'marketPrice')} />)
            }
        }]
    }
    resetAllFromInfo = () => {
        this.setState({
            goodsId: '',
            updateForm: {},
            arrStandard: [{
                attrValue: '',
                tags: [],
                inputVisible: false,
                inputValue: '',
                closeable: true,
            }],
            skuList: [],
            arrParameter: [{ k: '', v: '' }],
            goodsSpecs: [],
            columnsSku: this.initColumHeader(),
            editorState: BraftEditor.createEditorState(null),
            goodsDetailUrl: '',
            fileList: []
        });
    }
    // 根据id获取商品详情
    getInfoById = (id) => {
        axios.PublicGoods.getInfo({ id }).then(res => {
            this.setState({
                arrStandard: []
            }, () => {
                const data = res.data;
                if (data.code !== 1000) {
                    return false;
                }
                const { goodsName, goodsTitle, keyword, goodsWeight, categoryId, twoCategoryId, imageList, goodsParameterList, oneCategoryId, brandId, goodsStatus, goodsSpecsList, productList } = data.result;
                const categoryIds = [oneCategoryId, twoCategoryId, categoryId];
                this.getChooseSortList(categoryIds);
                this.getBrandVal(categoryId);
                if (goodsSpecsList && goodsSpecsList.length > 0) {
                    goodsSpecsList.forEach(v => {
                        let obj = {};
                        obj.attrValue = v.k;
                        let arr = [];
                        v.v.forEach(item => {
                            let list = {};
                            list.closeable = false;
                            list.name = item;
                            arr.push(list);
                        });
                        obj.tags = arr;
                        obj.inputVisible = false;
                        obj.inputValue = '';
                        this.state.arrStandard.push(obj);
                    })
                }
                this.showProductList(productList, this.state.arrStandard);
                let obj = {
                    goodsName,
                    goodsStatus: goodsStatus.toString(),
                    brandId,
                    goodsTitle,
                    keyword,
                    goodsWeight,
                    // goodsExpressType: goodsExpressType.toString(),
                    imageList,
                    categoryId: categoryIds,
                };
                let fileList = [];
                if (imageList) {
                    imageList.forEach(v => {
                        fileList.push({ id: v.id, uid: v.id, url: v.imageBigUrl });
                    });
                } else {
                    fileList = [];
                }

                this.setState({
                    goodsDetailUrl: data.result.goodsDetailUrl,
                    editorState: BraftEditor.createEditorState(data.result.goodsDetailContent),
                    updateForm: Object.assign({}, this.state.updateForm, obj),
                    arrStandard: this.state.arrStandard,
                    arrParameter: goodsParameterList,
                    fileList,
                    goodsSpecs: goodsSpecsList
                });
            });

        });
    }
    // 处理显示编辑货品列表
    showProductList = (list, standard) => {
        const { columnsSku } = this.state;
        // 缓存货品信息
        let arrList = [];
        list.forEach(item => {
            let obj = {};
            obj.id = item.id;
            obj.productLogo = item.productLogo;
            obj.barCode = item.barCode;
            obj.marketPrice = item.marketPrice / 100;
            obj.productSpecs = [];
            item.productSpecsList.forEach(items => {
                obj[items.k] = items.v;
                let obj1 = {};
                obj1.k = items.k;
                obj1.v = items.v;
                obj.productSpecs.push(obj1);
            });
            let arr = [];
            item.productSpecsList.forEach(v => {
                let string = v.v
                arr.push(string);
            });
            obj.compare = arr.toString()
            arrList.push(obj);
        });
        // 回显货品数据
        if (list.length > 0) {
            list.forEach(v => {
                let obj = {};
                obj.id = v.id;
                obj.productLogo = v.productLogo;
                obj.barCode = v.barCode;
                obj.marketPrice = v.marketPrice / 100;
                obj.productSpecs = [];
                v.productSpecsList.forEach(item => {
                    obj[item.k] = item.v;
                    let obj1 = {};
                    obj1.k = item.k;
                    obj1.v = item.v;
                    obj.productSpecs.push(obj1);
                });

                this.state.skuList.push(obj);
            });
            this.oldBarCodeList = this.state.skuList.map(item => item.barCode);
        }
        //动态生成表头
        let obj = {};
        const headerTitle = [];
        columnsSku.map(item => {
            return headerTitle.push(item.title);
        })
        standard.forEach((v) => {
            obj[v.attrValue] = v.tags;
        })
        Object.keys(obj).forEach((v, index) => {
            if (headerTitle.indexOf(v) < 0) {
                columnsSku.splice(2, 0, {
                    title: v, align: 'center', key: v, dataIndex: v, render: (text, record) => {
                        return (<Input defaultValue={text} disabled style={{ width: 100 }} />)
                    }
                });
            }
        });
        this.setState({
            columnsSku,
            skuList: this.state.skuList,
            compareList: arrList,
        });
    }

    // 获取选中的分类列表和品牌列表
    getChooseSortList = (goodsCategoryIds) => {
        const firstSortId = goodsCategoryIds[0];
        const secondSortId = goodsCategoryIds[1];
        axios.GoodsCategory.getCategoryList({ parentId: 0, level: 1, name: '' }).then(res => {
            const data = res.data;
            let sortOption = [];
            if (data.code !== 1000) {
                return false;
            }
            data.result.forEach(v => {
                v.isLeaf = false;
                v.level = 1;
            });
            sortOption = data.result;
            axios.GoodsCategory.getCategoryList({ parentId: firstSortId, level: 2, name: '' }).then(secondRes => {
                const resData = secondRes.data;
                if (resData.code !== 1000) {
                    // message.error(resData.chDesc);
                    return false;
                }
                const sortItem = sortOption.find((v) => v.id === firstSortId);
                resData.result.forEach(v => {
                    v.isLeaf = false;
                    v.level = 2;
                });
                sortItem.children = resData.result;
                axios.GoodsCategory.getCategoryList({ parentId: secondSortId, level: 3, name: '' }).then(thirdRes => {
                    const thirdData = thirdRes.data;
                    if (thirdData.code !== 1000) {
                        // message.error(thirdData.chDesc);
                        return false;
                    }
                    const secondSortItem = sortItem.children.find((v) => v.id === secondSortId);
                    secondSortItem.children = thirdData.result;
                    this.setState({ sortList: sortOption });
                });
            });
        });
    }

    // 校验条形码唯一
    checkBarCode = (barCode) => {
        return axios.PublicGoods.checkBarCode(barCode).then(res => {
            const data = res.data;
            return Promise.resolve(data.result);
        });
    }

    // 获取输入的SKU信息
    handleSkuChange = (record, index, attr, e) => {
        const { value } = e.target;
        let reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if (attr === 'barCode') {
            reg = /^\d/g;
        }
        if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            let arr = this.state.skuList;
            arr[index][attr] = value;
            this.setState({
                skuList: arr
            });
        } else {
            message.info("请输入数值");
        }

    }
    // 批量同步sku信息
    handleSku = () => {
        let obj = this.state.skuList[0];
        let arr = [];
        [...this.state.skuList].forEach((item, index) => {
            arr.push(Object.assign({}, item, { barCode: index === 0 ? obj.barCode : '' }, { marketPrice: obj.marketPrice }, { productLogo: obj.productLogo }))
        });
        this.setState({
            skuList: arr
        });
    }

    // 编辑富文本编辑器
    handleEditorChange = (editorState) => {
        this.setState({ editorState })
    }

    combination(arr) {
        var keyArr = Object.keys(arr);
        var count = keyArr.length - 1;
        var tmp = {};
        var totalArr = [];
        return combinationCallback(arr, 0);
        function combinationCallback(arr, curr_index) {
            var attr = keyArr[curr_index];
            if (arr[attr].length !== 0) {
                for (var val of arr[attr]) {
                    tmp[attr] = val;
                    if (curr_index < count) {
                        combinationCallback(arr, curr_index + 1);
                    } else {
                        totalArr.push(tmp);
                    }
                    var oldTmp = tmp;
                    tmp = JSON.parse(JSON.stringify(oldTmp));
                }
            } else {
                if (curr_index < count) {
                    combinationCallback(arr, curr_index + 1);
                } else {
                    totalArr.push(tmp);
                }
            }
            return totalArr;
        }
    }

    // 选择分类
    onChangeSort = (value, selectedOptions) => {
        if (selectedOptions.length === 3) {
            this.setState({
                showSelect: false,
                newDataTags: selectedOptions[selectedOptions.length - 1],
            }, () => {
                this.getBrandVal(this.state.newDataTags.id);
            });
        }
    }

    // 通过三级分类Id获取规格属性值
    getBrandVal = (id) => {
        axios.GoodsBrand.getBrandsList({ brandName: '', categoryFirstId: '', categorySecondId: '', categoryThirdId: id, page: '', size: '' }).then(res => {
            const data = res.data;
            if (data.code !== 1000) {
                this.setState({
                    arrBrand: [],
                });
                return false;
            }
            this.setState({
                arrBrand: data.result.list,
            });
        });
    }

    // 选择分类
    handleSort = (changeParent = { id: 0, level: 0 }) => {
        let { id, level } = changeParent;
        const requestData = {
            level: ++level,
            parentId: id,
            name: ''
        }
        axios.GoodsCategory.getCategoryList(requestData).then(res => {
            const data = res.data;
            changeParent.loading = false;
            if (data.code !== 1000) {
                if (data.chDesc === '无法获取有效值') {
                    // message.error("该分类下没有三级分类无法关联");
                }
                return false;
            }
            data.result.forEach((v) => {
                v.level = level;
                v.key = v.id;
                if (level !== 3) {
                    v.isLeaf = false;
                }
            })
            if (level === 1) {
                this.setState({ sortList: data.result });
                return;
            }
            changeParent.children = data.result;
            this.setState({
                sortList: [...this.state.sortList],
            });
        })
    }

    // 加载分类
    loadSort = (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        this.handleSort(targetOption);
    }

    updateImg = (files, record, index) => {
        const { file } = files;
        const form = new FormData();
        form.append('file', file);
        form.append('catalog', 4);
        axios.Upload.uploadImg(form).then(res => {
            const data = res.data;
            if (data.code !== 1000) {
                return false;
            }
            let goodLoge = this.state.skuList[index];
            goodLoge.productLogo = data.result;
            this.setState({
                skuList: this.state.skuList
            });
        });
    }
    // 上传商品图片
    updateImgList = (files) => {
        const { file } = files;
        const form = new FormData();
        form.append('file', file);
        form.append('catalog', 4);
        axios.Upload.uploadImg(form).then(res => {
          const data = res.data;
          if (data.code !== 1000) {
            return false;
          }
          this.state.fileList.push({
            uid: `rc-upload-${new Date().getTime()}`,
            status: 'done',
            url: data.result
          })
          this.setState({
            fileList: [...this.state.fileList]
          });
        });
    }
    // 预览图片
    handlePreview = (file) => {
        this.setState({
          previewImage: file.url || file.thumbUrl,
          previewVisible: true,
        });
    }
    // 取消预览图片
    handleCanceModel = () => this.setState({ previewVisible: false })
    handleChangeList = ({ fileList, event }) => {
      // this.setState({ fileList })
      // console.log(event)
    }
    myUploadFn = (files) => {
        const { file } = files;
        const form = new FormData();
        form.append('file', file);
        form.append('catalog', 4);
        axios.Upload.uploadImg(form).then(res => {
            files.success({
                url: res.data.result
            })
        })
    }
    // 显示新增规格值
    showInput = (index) => {
        const arrStandard = [...this.state.arrStandard];
        arrStandard[index].inputVisible = true;
        this.setState({
            arrStandard
        }, () => {
            this.input.focus();
        });
    }
    saveInputRef = input => this.input = input;
    //规格属性输入框
    handleAttrInputChange = (index, e) => {
        const arrStandard = [...this.state.arrStandard];
        arrStandard[index].attrValue = e.target.value;
        this.setState({
            arrStandard
        });
    }
    // 规格值输入框
    handleInputChange = (index, e) => {
        const arrStandard = [...this.state.arrStandard];
        arrStandard[index].inputValue = e.target.value;
        this.setState({
            arrStandard
        });
    }
    // 回车，市区焦点自动生成tag
    handleInputConfirm = (index) => {
        const arrStandard = [...this.state.arrStandard];
        let arr = [];
        arrStandard[index].tags.forEach(item => { arr.push(item.name) });
        if (arrStandard[index].inputValue && arr.indexOf(arrStandard[index].inputValue) === -1) {
            arrStandard[index].tags = [...arrStandard[index].tags, { name: arrStandard[index].inputValue, closeable: true }];
        }
        arrStandard[index].inputVisible = false;
        arrStandard[index].inputValue = '';
        this.setState({
            arrStandard
        });
    }
    // 关闭规格值tag
    handleClose = (removedTag, parentIndex) => {
        const arrStandard = this.state.arrStandard;
        const newTagList = arrStandard[parentIndex].tags.filter(tag => tag.name !== removedTag.name);
        arrStandard[parentIndex].tags = newTagList;
        this.setState({
            arrStandard: [...arrStandard]
        });
    }
    // 新增规格属性
    addNewAttr = () => {
        if (this.props.editFlag) {
            message.warning("编辑时不可以新增规格属性！");
            return false;
        }
        if (this.state.arrStandard.length >= 5) {
            message.warning("最多添加五个规格属性！");
            return false;
        }
        let val = this.state.arrStandard.find(item => item.attrValue === '' || item.tags.length <= 0);
        if (val) {
            message.warning("请填写完一条完整的规格属性好继续新增！");
            return;
        }
        this.setState({
            arrStandard: [...this.state.arrStandard, {
                attrValue: '',
                tags: [],
                inputVisible: false,
                inputValue: ''
            }]
        });
    }
    // 删除新增规格属性
    delAddNewAttr = (index) => {
        if (this.props.editFlag) {
            message.warning("编辑时不可以删除规格属性！");
            return false;
        }
        const arrStandard = [...this.state.arrStandard];
        let that = this;
        if (arrStandard.length <= 1) {
            message.error("至少保留一条规格属性!");
        } else {
            Modal.confirm({
                title: '确定删除该条规格属性?',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    arrStandard.splice(index, 1);
                    that.setState({
                        arrStandard
                    });
                },
            });
        }

    }
    //删除商品参数
    delParamerAttr = (index) => {
        const arrParameter = [...this.state.arrParameter];
        let that = this;
        Modal.confirm({
            title: '确定删除该条商品参数?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                arrParameter.splice(index, 1);
                that.setState({
                    arrParameter
                });
            },
        });
    }
    // 移出图片
    delImageList = (index) => {
			if (index.id) {
				axios.PublicGoods.del(index.id).then(res => {
					const data = res.data;
					if (data.code !== 1000) {
						return false;
					}
					let arr = [];
					this.state.fileList.forEach(item => {
						if (item.uid !== index.uid) {
							arr.push(item);
						}
					});
					this.setState({
						fileList: arr
					})
				});
			}
			this.setState({ index })
			this.state.fileList.forEach( (v, d) => {
				if (v.url === index.url) {
					this.state.fileList.splice(d, 1)
				}
			})
    }
    // 商品参数输入
    handleParameterInputChange = (index, name, e) => {
        const arrParameter = [...this.state.arrParameter];
        if (name === 'k') {
            arrParameter[index].k = e.target.value;
        } else if (name === 'v') {
            arrParameter[index].v = e.target.value;
        }
        this.setState({
            arrParameter
        });
    }
    // 商品参数输入框
    addParameter = () => {
        let val = this.state.arrParameter.find(item => item.k === '' || item.v === '');
        if (val) {
            message.warning("请填写完一条完整的商品参数继续新增！");
            return;
        }
        this.setState({
            arrParameter: [...this.state.arrParameter, {
                k: '',
                v: ''
            }]
        });
    }
    // 生成SkuList
    buildSkuList = () => {
        this.setState({
            columnsSku: this.initColumHeader(),
            skuList: []
        }, () => {
            const { arrStandard, columnsSku } = this.state;
            let i = 0;
            let arr = [];
            arrStandard.forEach(item => {
                if (arr.indexOf(item.attrValue) < 0) {
                    arr.push(item.attrValue);
                } else {
                    i++
                }
            });
            if (i > 0) {
                message.warning("规格属性不可以重复!");
                return false;
            }
            let listGoodsSpecs = [];
            arrStandard.forEach(v => {
                let arr = [];
                v.tags.forEach(v => {
                    arr.push(v.name);
                });
                listGoodsSpecs.push({ k: v.attrValue, v: arr });
            });
            let val = arrStandard.find(item => item.attrValue === '' || item.tags.length <= 0);
            if (val) {
                message.warning("有未新增完的规格属性，和属性值!");
                return false;
            }
            let obj = {};
            arrStandard.forEach((v) => {
                let arr = [];
                v.tags.forEach(v => {
                    arr.push(v.name);
                });
                obj[v.attrValue] = arr;
            })
            const values = this.combination(obj);
            // 动态的生成表头
            const headerTitle = [];
            columnsSku.map(item => {
                return headerTitle.push(item.title);
            })
            Object.keys(obj).forEach((v, index) => {
                if (headerTitle.indexOf(v) < 0) {
                    columnsSku.splice(2, 0, {
                        title: v, align: 'center', key: v, dataIndex: v, render: (text, record) => {
                            return (<Input defaultValue={text} disabled style={{ width: 100 }} />)
                        }
                    });
                }
            });
            if (this.props.editFlag) {
                let arr = [];
                values.forEach((v, index) => {
                    let obj = {};
                    obj.value = Object.values(v).toString();
                    obj.key = Object.keys(v).toString();
                    obj.index = v;
                    arr.push(obj);
                });
                let arr4 = [];
                this.state.compareList.forEach(item => {
                    arr.forEach(v => {
                        if (v.value === item.compare) {
                            arr4.push(v.index);
                            this.state.skuList.push(item);
                        }
                    });
                });
                let list = values.filter(item => !arr4.includes(item));
                list.map((item, index) => {
                    const valueArr = [];
                    for (let attr in item) {
                        const attrItem = {};
                        attrItem.k = attr;
                        attrItem.v = item[attr]
                        valueArr.push(attrItem);
                    }
                    let list = Object.assign({}, {
                        productLogo: "",
                        barCode: "",
                        marketPrice: "",
                    }, item, { id: `add_${index}` }, { productSpecs: valueArr });
                    return this.state.skuList.push(list);
                });
            } else {
                // 动态生成SKu列表
                values.map((item, index) => {
                    const valueArr = [];
                    for (let attr in item) {
                        const attrItem = {};
                        attrItem.k = attr;
                        attrItem.v = item[attr]
                        valueArr.push(attrItem);
                    }
                    let list = Object.assign({}, {
                        productLogo: "",
                        barCode: "",
                        marketPrice: "",
                    }, item, { id: `add_${index}` }, { productSpecs: valueArr });
                    return this.state.skuList.push(list);
                });
            }
            this.setState({
                goodsSpecs: listGoodsSpecs,
                skuList: this.state.skuList,
                columnsSku
            });
        });

    }

    // 取消编辑或新增
    sureCancel = () => {
        const tabObj = commonCloseTab(this.props.tabList, MENUKEY);
        this.props.pushTabs(tabObj);
    }
    // 新增商品
    saveAllGoodsInfo = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.state.skuList.length <= 0) {
                    message.info("请至少生成一条货品信息后保存!");
                    return false;
                }
                const isCurrentSkuList = this.state.skuList.every(v => v.marketPrice);
                if (!isCurrentSkuList) {
                    message.info("请为每一个货品填写市场价");
                    return false;
                }

                if (this.state.fileList.length <= 0) {
                    message.info("请至少上传一张图片");
                    return false;
                }
                this.setState({saveLoading: true});
                let dataForm = {};
                if (this.state.goodsId) {
                    dataForm.goodsId = this.state.goodsId
                }
                dataForm.goodsName = values.goodsName;
                dataForm.goodsTitle = values.goodsTitle;
                dataForm.keyword = values.keyword;
                dataForm.goodsWeight = values.goodsWeight;
                // dataForm.goodsExpressType = values.goodsExpressType;
                dataForm.categoryId = values.categoryId[2];
                dataForm.goodsSpecs = values.goodsSpecs;
                dataForm.brandId = values.brandId;
                dataForm.goodsStatus = values.goodsStatus;
                dataForm.goodsSpecs = this.state.goodsSpecs;
                dataForm.goodsParameter = this.state.arrParameter;
                dataForm.goodsDetailUrl = this.state.goodsDetailUrl;
                dataForm.imageList = [];
                dataForm.goodsDetailContent = this.state.editorState.toHTML();
                this.state.fileList.forEach((v, index) => {
                    let obj = {};
                    if (v.id) {
                      obj.id = v.id;
                    }
                    obj.sortId = index;
                    obj.imageBigUrl = v.url;
                    dataForm.imageList.push(obj);
                });
                dataForm.productList = [];
                this.state.skuList.forEach(v => {
                    let obj = {};
                    let str = v.id.toString();
                    if (this.props.editFlag && v.id && str.indexOf('add_') < 0) {
                        obj.productId = v.id;
                    }
                    obj.productLogo = v.productLogo;
                    obj.barCode = v.barCode;
                    obj.marketPrice = v.marketPrice * 100;
                    obj.productSpecs = v.productSpecs;
                    dataForm.productList.push(obj);
                });
                const self = this;
                axios.PublicGoods.addGoodsList(dataForm).then(res => {
                    this.setState({saveLoading: false});
                    const data = res.data;
                    if (data.code !== 1000) {
                        return false;
                    }
                    Modal.success({
                        title: '提示',
                        content: self.props.editFlag ? '修改商品信息成功！' : '新增商品成功！',
                        onOk() {
                            const tabObj = commonCloseTab(self.props.tabList, MENUKEY);
                            self.props.pushTabs(tabObj);
                        }
                    });
                });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { editorState, updateForm, saveLoading } = this.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 2 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const buttonItemLayout = this.state.formLayout === 'horizontal' ? {
            wrapperCol: { span: 14, offset: 4 },
        } : null;

        return (
            <Form>
                <Form.Item
                    {...formItemLayout}
                    label="商品名称"
                >
                    {getFieldDecorator('goodsName', {
                        initialValue: updateForm.goodsName,
                        rules: [{ required: true, message: '请输入商品名称!' }],
                    })(
                        <Input placeholder="请输入商品名称" />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="商品副标题"
                >
                    {getFieldDecorator('goodsTitle', {
                        initialValue: updateForm.goodsTitle
                    })(
                        <Input placeholder="请输入商品副标题" />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="关键字"
                >
                    {getFieldDecorator('keyword', {
                        initialValue: updateForm.keyword
                    })(
                        <Input placeholder="每个关键字最长15个字符必须以‘,’逗号分隔符" />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="商品重量"
                >
                    {getFieldDecorator('goodsWeight', {
                        initialValue: updateForm.goodsWeight,
                        rules: [{ required: true, message: '请输入商品中重量!' }],
                        getValueFromEvent: (e) => {
                            return parseInt(e.target.value) || ''
                        }
                    })(
                        <Input addonAfter="克" placeholder="请输入商品重量（单位：克）" />
                    )}
                </Form.Item>
                {/* <Form.Item
                    {...formItemLayout}
                    label="配送方式"
                    >
                    {getFieldDecorator('goodsExpressType',{
                        rules: [{ required: true, message: '请选择配送方式!' }],
                        initialValue: updateForm.goodsExpressType
                    })(
                        <Select>
                            <Option value="3">快递</Option>
                            <Option value="1">自提</Option>
                            <Option value="2">配送上门</Option>
                        </Select>
                    )}
                    <span className="addNewApply">点击添加新供应商</span> 
                </Form.Item> */}
                <Form.Item
                    {...formItemLayout}
                    label="一级分类"
                >
                    {getFieldDecorator('categoryId', {
                        initialValue: updateForm.categoryId,
                        rules: [{ type: 'array', len: 3, required: true, message: '请选择分类' }],
                    })(
                        <Cascader
                            disabled={this.props.editFlag}
                            className={this.state.showSelect ? null : "hideCascader"}
                            fieldNames={sortFieldNames}
                            options={this.state.sortList}
                            displayRender={this.displayRender}
                            loadData={this.loadSort}
                            onChange={this.onChangeSort}
                        />
                    )}
                    {/* <span className="addNewApply">添加新分类</span> */}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="所属品牌"
                >
                    {getFieldDecorator('brandId', {
                        initialValue: updateForm.brandId,
                        rules: [{ required: true, message: '请选择所属品牌!' }],
                    })(
                        <Select disabled={this.props.editFlag}>{
                            this.state.arrBrand.map(item => {
                                return (<Option key={item.id} value={item.id}>{item.brandName}</Option>);
                            })
                        }
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="是否上架"
                >
                    {getFieldDecorator('goodsStatus', {
                        initialValue: updateForm.goodsStatus || '0',
                        rules: [{ required: true, message: '请选择是否上架!' }],
                    })(
                        <Radio.Group>
                            <Radio value="0">立即上架</Radio>
                            <Radio value="1">下架</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="商品规格"
                >
                    {getFieldDecorator('standard')(
                        <div className="standardAttr">
                            <Button type="primary" size="small" onClick={this.addNewAttr}>新增规格属性</Button><Button type="primary" size="small" onClick={this.buildSkuList}>生成SKU列表</Button><Button size="small" type="primary" onClick={this.handleSku}>同步SKU</Button>
                            <div id="standardAttr">
                                <Row gutter={24} className="m-input-th">
                                    <Col span={6}>规格属性</Col>
                                    <Col span={14}>规格值</Col>
                                    <Col span={4}>操作</Col>
                                </Row>
                                {this.state.arrStandard.map(
                                    (v, index) => {
                                        return (
                                            <Row gutter={24} className="m-specification-row" key={index}>
                                                <Col span={6}><Input size="small" value={v.attrValue} onChange={this.handleAttrInputChange.bind(this, index)} /></Col>
                                                <Col span={14}>{
                                                    v.tags.length > 0 && v.tags.map((tag) => {
                                                        return (<Tag closable={tag.closeable} key={tag.name} onClose={() => this.handleClose(tag, index)}>{tag.name}</Tag>)
                                                    })
                                                }
                                                    {v.inputVisible && (
                                                        <Input
                                                            ref={this.saveInputRef}
                                                            type="text"
                                                            size="small"
                                                            style={{ width: 78 }}
                                                            value={v.inputValue}
                                                            onChange={this.handleInputChange.bind(this, index)}
                                                            onBlur={this.handleInputConfirm.bind(this, index)}
                                                            onPressEnter={this.handleInputConfirm.bind(this, index)}
                                                        />
                                                    )}
                                                    {
                                                        !v.inputVisible && (
                                                            <Tag
                                                                onClick={this.showInput.bind(this, index)}
                                                                style={{ background: '#fff', borderStyle: 'dashed' }}
                                                            >
                                                                <Icon type="plus" /> 新增规格值
                                                    </Tag>
                                                        )
                                                    }
                                                </Col>
                                                <Col span={4}><span className="addNewApply" onClick={this.delAddNewAttr.bind(this, index)}>删除</span></Col>
                                            </Row>)
                                    }
                                )}
                            </div>

                        </div>
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="SKU列表">
                    <Table bordered rowKey='id' size="small" id="associateGoods" columns={this.state.columnsSku} dataSource={this.state.skuList} pagination={false} />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="商品参数"
                >
                    {getFieldDecorator('goodsParameter')(
                        <div className="standardAttr">
                            <Button type="primary" size="small" onClick={this.addParameter}>新增商品参数</Button>
                            <div id="standardAttr">
                                <Row gutter={24} className="m-input-th">
                                    <Col span={6}>商品参数</Col>
                                    <Col span={14}>参数值</Col>
                                    <Col span={4}>操作</Col>
                                </Row>
                                {this.state.arrParameter.map(
                                    (v, index) => {
                                        return (
                                            <Row gutter={24} className="m-specification-row" key={index} >
                                                <Col span={6}><Input size="small" style={{ width: '80%' }} value={v.k} onChange={this.handleParameterInputChange.bind(this, index, 'k')} /></Col>
                                                <Col span={14}>
                                                    <Input size="small" value={v.v} onChange={this.handleParameterInputChange.bind(this, index, 'v')} />
                                                </Col>
                                                <Col span={4}><span className="addNewApply" onClick={this.delParamerAttr.bind(this, index)}>删除</span></Col>
                                            </Row>)
                                    }
                                )}
                            </div>

                        </div>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="商品图片"
                >
                    {getFieldDecorator('imageList', {
                        initialValue: updateForm.imageList,
                        rules: [{ required: true, message: '请上传商品图片!' }],
                    })(
                        <div>
                            <Upload
                                multiple = {true}
                                customRequest={this.updateImgList}
                                listType="picture-card"
                                fileList={this.state.fileList}
                                onRemove={this.delImageList.bind(this)}
                                onPreview={this.handlePreview}
                                onChange={this.handleChangeList}
                            >
                                {uploadButton}
                            </Upload>
                            <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCanceModel}>
                                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                            </Modal>
                        </div>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="详情"
                >
                    <div className="braft-editor my-component">
                        <BraftEditor
                            value={editorState}
                            onChange={this.handleEditorChange}
                            media={{
                                validateFn: (file) => {
                                    return file.size < 10 * 1024 * 1024
                                },
                                uploadFn: this.myUploadFn,
                                accepts: {
                                    image: 'image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg'
                                }
                            }}

                        />
                    </div>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button style={{ marginRight: '20px' }} type="primary" className="m-r" loading={saveLoading} onClick={this.saveAllGoodsInfo}>保存</Button>
                    <Button onClick={this.sureCancel}>取消</Button>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateProps = (state) => ({
    tabList: state.main.tabList,
    activeKey: state.main.activeKey,
    editFlag: state.addOrEditGoods.editFlag
});

const mapDispatchProps = (dispatch) => ({
    pushTabs: (value) => {
        TABS.main = {
            tabList: value.tabList,
            activeKey: value.activeKey
        }
        dispatch(TABS);
    }
});



const AddOrEditGoods1 = Form.create({ name: 'addoreditgoods' })(AddOrEditGoods);

export default connect(
    mapStateProps,
    mapDispatchProps
)(AddOrEditGoods1);