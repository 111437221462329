// 路由组件出口文件
import Login from './login';
import Home from './home';
import MerchantList from './merchant/list';
import FunctionList from './merchant/functionList';
import GoodsSort from './basic/goodsSort';
import GoodsBrand from './basic/goodsBrand';
import Specification from './basic/specification';
import AddSpecification from './basic/specification/add';
import UserManager from './system/userManager';
import RoleManager from './system/roleManager';
import GoodsTag from './basic/goodsTag';
import Service from './basic/service';
import AddOrEditGoods from './publicLibrary/addOrEditGoods';
import GoodsList from './publicLibrary/goodsList';
import SysAccount from './finance/sysAccount';
import SysCharge from './finance/sysCharge';
import SysChargeDetail from './finance/sysChargeDetail';

export default {
  Login, Home, MerchantList, GoodsSort, GoodsBrand, Specification, AddSpecification, GoodsTag, Service, AddOrEditGoods, GoodsList, UserManager, RoleManager, FunctionList, SysAccount, SysCharge, SysChargeDetail
}