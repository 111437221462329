import React from 'react';
import AllPages from '../../app';
const Component = AllPages['Home'];
const panes = [{authorityName: '首页', authorityUrl: 'Home', closable: false, content: <Component/>, params: null}];
let defaults = {
  tabList: panes,
  activeKey: 'Home'
}
const main = (state = defaults, action) => {
  switch (action.type) {
    case 'TABS':
      return Object.assign({}, state, action.main);
    default: 
      return state;
  }
}
export default main;