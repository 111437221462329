import React from 'react';
import {Form, Input, Button} from 'antd';
class SearchForm extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {
       
    }
  }
  
  render () {
    const {data, search} = this.props;
    const {getFieldDecorator} = this.props.form;
    return (
      <Form layout="inline" onSubmit={search}>
        <Form.Item label="标签名称">
          {getFieldDecorator('name', {
            initialValue: data.name
          })(
            <Input placeholder="请输入标签名称"/>
          )}
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" ghost>搜索</Button>
        </Form.Item>
      </Form>
    )
  }
}
export default Form.create('search_tag_form')(SearchForm);