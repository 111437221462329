import util from '../util';
export default {
    menus: util.getSessionStorage('loginUserInfo') ? JSON.parse(util.getSessionStorage('loginUserInfo')).menuList : [],
//   menus: [ // 菜单相关路由
//       // { key: '/main/index', title: '首页', icon: 'mobile', component: 'Dashboard' },
//       {
//           key: '/main/merchant', title: '商户管理', icon: 'shop',
//           subs: [
//               { key: 'MerchantList', title: '商户列表', component: 'MerchantList'},
//           ],
//       },
//       {
//         key: '/main/basic', title: '基础数据', icon: 'book',
//         subs: [
//             { key: '/main/basic/goodsSort', title: '商品分类', component: 'GoodsSort'},
//             { key: '/main/basic/specification', title: '规格类型', component: 'Specification'},
//             { key: '/main/basic/goodsBrand', title: '商品品牌', component: 'GoodsBrand'},
//             { key: '/main/basic/goodsTag', title: '商品标签', component: 'GoodsTag'},
//             { key: '/main/basic/service', title: '服务支持', component: 'Service'},
//         ],
//       },
//       {
//         key: '/main/publicLibrary', title: '公共库', icon: 'book',
//         subs: [
//             { key: '/main/publicLibrary/GoodsList', title: '商品列表', component: 'GoodsList'},
//             { key: '/main/publicLibrary/addOrEditGoods', title: '添加/编辑商品', component: 'AddOrEditGoods'},
//         ],
//       },
//       {
//         key: '/main/system', title: '系统管理', icon: 'book',
//         subs: [
//             { key: '/main/system/userManager', title: '用户管理', component: 'UserManager'},
//             { key: '/main/system/roleManager', title: '角色管理', component: 'RoleManager'},
//         ],
//       }
//   ],
  others: [], // 非菜单相关路由
  tabMenus: [
      {key: 'AddSpecification', authorityName: '添加/编辑规格类型', authorityUrl: 'AddSpecification', params: null},
      { key: 'AddOrEditGoods', authorityName: '添加/编辑商品', authorityUrl: 'AddOrEditGoods'},
  ]
}