import React from 'react';
import ReactDOM from 'react-dom';
import {LocaleProvider} from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import './assets/style.scss';
import Page from './Page';
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import stores from './store';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

let store = createStore(stores);
ReactDOM.render(
  <Provider store={store}>
      <LocaleProvider locale={zhCN}>
        <Page store={store}/>
      </LocaleProvider>
    </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
