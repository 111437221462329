import React from 'react';
import {Button, Table, message, Modal} from 'antd';
import EditServiceModal from './components/editServiceModal';
import SearchForm from './components/searchForm';
import axios from '../../../api';
const confirm = Modal.confirm;
// 表格列字段
const columns = [
  {
    title: '图片',
    dataIndex: 'serviceSupportImgUrl',
    key: 'serviceSupportImgUrl',
    render: src => src ? <img style={{width:50}} alt="img" src={src}/> : <span>暂无图片</span>,
  }, {
    title: '服务支持名称',
    dataIndex: 'serviceSupportName',
    key: 'serviceSupportName',
  }, {
    title: '简介',
    dataIndex: 'profile',
    key: 'profile',
  }, {
    title: '排序',
    dataIndex: 'sortId',
    key: 'sortId',
  }, 
];
class Service extends React.Component{
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      searchForm: {
        name: ''
      },
      updateForm: {
        serviceSupportName: '',
        serviceSupportImgUrl: '',
        profile: '',
        sortId: '',
        id: '',
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        onChange (page, size) {
          _this.handlePage(page, size);
        },
        onShowSizeChange (current, size) {
          _this.handlePage(current, size);
        }
      },
      selectedRowKeys: null,
      data: [],
      loading: true,
      isUpdate: false,
      editVisible: false
    }
  }
  componentDidMount () {
    this.getSupportList();
  }
  // 搜索
  search = (e) => {
    e.preventDefault();
    const value = this.searchForm.props.form.getFieldsValue();
    const newPagination = Object.assign({}, this.state.pagination, {current: 1, pageSize: 10});
    this.setState({
      searchForm: value,
      pagination: newPagination
    }, () => {
      this.getSupportList();
    })
  }

  // 切换页码
  handlePage (page, size) {
    const newPagination = Object.assign({}, this.state.pagination, {current: page, pageSize: size});
    this.setState({
      pagination: newPagination
    }, () => {
      this.getSupportList();
    });
  }
  
  // 获取服务支持列表
  getSupportList () {
    const {searchForm, pagination} = this.state;
    this.setState({
      loading: true
    });
    axios.Support.getList({page: pagination.current, size: pagination.pageSize, serviceSupportName: searchForm.name}).then(res => {
      this.setState({
        loading: false
      });
      const data = res.data;
      if (data.code !== 2001 && data.code !== 1000) {
        return false;
      }
      const newPagination = Object.assign(pagination, {total: data.result.total})
      if (data.code === 2001) {
        this.setState({
          data: [],
          pagination: newPagination
        });
        return false;
      }
      this.setState({
        data: data.result.list,
        pagination: newPagination
      });
    })
  }

  // 勾选表格多选框事件
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  // 删除记录按钮点击事件
  delete = (e) => {
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要删除的数据');
      return false;
    }
    const _this = this;
    confirm({
      title: '提示',
      content: '确认要删除这些记录吗？',
      onOk() {
        axios.Support.del(_this.state.selectedRowKeys.toString()).then(res => {
          const data = res.data;
          if (data.code !== 1000) {
            return false;
          }
          _this.setState({
            selectedRowKeys: null
          });
          _this.getSupportList();
        });
      }
    })
  }

  // 打开添加页面
  openAdd = () => {
    this.setState({
      editVisible: true,
      isUpdate: false,
      updateForm: {
        serviceSupportName: '',
        serviceSupportImgUrl: '',
        profile: '',
        sortId: '',
        id: '',
      }
    })
  }
  // 上传图片
  updateImg = (obj) => {
    const {file} = obj;
    const form = new FormData();
    const {updateForm} = this.state;
    form.append('file', file);
    form.append('catalog', 2);
    axios.Upload.uploadImg(form).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        return false;
      }
      this.setState({
        updateForm: Object.assign({}, updateForm, {serviceSupportImgUrl: data.result})
      });
    });
  }

  // 打开编辑页面
  openEdit = () => {
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要编辑的数据');
      return false;
    }
    if(this.state.selectedRowKeys.length > 1) {
      message.warning('请选择一个您要编辑的数据');
      return false;
    }
    const chooseKey = this.state.selectedRowKeys[0];
    const item = this.state.data.find((v) => v.id === chooseKey);
    const {serviceSupportName, serviceSupportImgUrl, profile, sortId, id} = item;
    this.setState({
      editVisible: true,
      isUpdate: true,
      updateForm: {
        serviceSupportName,
        serviceSupportImgUrl,
        profile,
        sortId,
        id,
      }
    })
  }

  // 关闭编辑弹框
  handleCancel = () => {
    this.setState({
      editVisible: false
    })
  }

  // 确定更新服务支持
  handleUpdate = () => {
    this.editForm.props.form.validateFields((err, values) => {
      if (err) {
        return false;
      }
      this.sureUpdate(values);
    });
  }
  
  // 更新调取接口
  sureUpdate = (data) => {
    const {updateForm, isUpdate} = this.state;
    const {serviceSupportName, profile, sortId} = data;
    const {serviceSupportImgUrl} = updateForm;
    const requestData = {
      serviceSupportName,
      serviceSupportImgUrl,
      profile,
      sortId: Number(sortId),
      id: updateForm.id
    };
    let methodName = 'add';
    if (isUpdate) {
      methodName = 'edit';
    }
    axios.Support[methodName](requestData).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        return false;
      }
      this.setState({
        selectedRowKeys: null,
        editVisible: false,
      });
      this.getSupportList();
    });
  }
  render () {
    const { selectedRowKeys, searchForm, pagination, isUpdate, updateForm, editVisible, data } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <SearchForm data={searchForm} search={this.search} wrappedComponentRef={(form) => this.searchForm = form}/>
        <div className="m-btn-group">
          <Button type="primary" onClick={this.openAdd}>添加</Button>
          <Button type="primary" onClick={this.openEdit}>编辑</Button>
          <Button type="danger" onClick={this.delete}>删除</Button>
        </div>
        <Table rowKey='id' size="middle" pagination={pagination} rowSelection={rowSelection} columns={columns} dataSource={data}/>
        <EditServiceModal wrappedComponentRef={(form) => this.editForm = form} uploadImg={this.updateImg} updateServiceMsg={updateForm} editTitle={isUpdate ? '修改服务支持' : '添加服务支持'} editVisible={editVisible} handleCancel={this.handleCancel} handleUpdate={this.handleUpdate}/>
      </div>
    )
  }
}

export default Service;