import React from 'react';
import {connect} from 'react-redux';
import {Steps} from 'antd';
import AddFirstForm from './components/addFirstForm';
import AddSecondForm from './components/addSecondForm';
import AddThirdForm from './components/addThirdForm';
import {SPECIFICATION} from '../../../store/action';
import axios from '../../../api';
import './index.scss';
const Step = Steps.Step;
const MENUKEY = 'AddSpecification';
class AddSpecification extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      stepList: [{
        title: '编辑规格类型信息',
      }, {
        title: '编辑规格属性',
      }, {
        title: '编辑规格参数',
      }],
      sortOption: [],
      brandList: [],
      specificationValueList: [],
      resetUpdateForm: {
        id: null,
        currentStep: 0,
        name: null,
        describe: null,
        sort: [],
        brand: [],
        attrList: [
          {
            name: '',
            rename: '',
            valueList: [
              {value: '', isEdit: true}
            ],
            sort: ''
          }
        ],
        paramList: [{
          name: '',
          rename: ''
        }]
      }
    }
  }
  componentDidMount () {
    const {id} = this.getParams();
    if (id) {
      this.getChooseSortList();
    }
    this.getSortList();
  }
  componentWillReceiveProps (nextProps) {
    const {reload} = this.getParams(nextProps);
    const {id} = this.getParams(nextProps);
    const nextActiveKey = nextProps.activeKey;
    if (nextActiveKey === MENUKEY && reload) {
      let tab = this.props.tabList.find((v) => v.authorityUrl === MENUKEY);
      tab.params.reload = false;
      document.getElementById('tag0_0').value = null;
      this.addFirstForm.props.form.resetFields();
      if (id) {
        this.getChooseSortList();
        return false;
      }
      this.getSortList();
      this.props.updateForm(Object.assign({}, this.state.resetUpdateForm));
    }
  }

  // 获取选中的分类列表和品牌列表
  getChooseSortList = () => {
    const {specification} = this.props;
    const firstSortId = specification.sort[0];
    const secondSortId = specification.sort[1];
    const thirdSortId = specification.sort[2];
    axios.GoodsCategory.getCategoryList({parentId: 0, level: 1, name: ''}).then(res => {
      const data = res.data;
      let sortOption = [];
      if (data.code !== 1000) {
        return false;
      }
      data.result.forEach(v => {
        v.isLeaf = false;
        v.level = 1;
      });
      sortOption = data.result;
      axios.GoodsCategory.getCategoryList({parentId: firstSortId, level: 2, name: ''}).then(secondRes => {
        const resData = secondRes.data;
        if (resData.code !== 1000) {
          // message.error(resData.chDesc);
          return false;
        }
        const sortItem = sortOption.find((v) => v.id === firstSortId);
        resData.result.forEach(v => {
          v.isLeaf = false;
          v.level = 2;
        });
        sortItem.children = resData.result;
        axios.GoodsCategory.getCategoryList({parentId: secondSortId, level: 3, name: ''}).then(thirdRes => {
          const thirdData = thirdRes.data;
          if (thirdData.code !== 1000) {
            // message.error(thirdData.chDesc);
            return false;
          }
          const secondSortItem = sortItem.children.find((v) => v.id === secondSortId);
          secondSortItem.children = thirdData.result;
          this.setState({sortOption}, () => {
            this.getBrandList(thirdSortId);
          });
        });
      });
    });
  }
  
  // 获取页面参数
  getParams = (props = this.props) => {
    const {tabList} = props;
    const tabItem = tabList.find((v) => v.authorityUrl === MENUKEY);
    return tabItem.params;
  }

  // 获取分类列表
  getSortList = (selectParent ={id: 0, level: 0}, callback) => {
    let {level, id} = selectParent;
    const requestData = {
      level: ++level,
      parentId: id,
      name: ''
    }
    axios.GoodsCategory.getCategoryList(requestData).then(res => {
      const data =res.data;
      selectParent.loading = false;
      if (data.code !== 1000) {
        return false;
      }
      data.result.forEach((v) => {
        v.level = level;
        v.key = v.id;
        if (level !== 3) {
          v.isLeaf = false;
        }
      })
      if (level === 1) {
        this.setState({sortOption: data.result}, () => {
          if (callback) {
            callback();
          }
        });
        return;
      }
      selectParent.children = data.result;
      
      this.setState({
        sortOption: [...this.state.sortOption],
      }, () => {
        if (callback) {
          callback();
        }
      });
    });
  }
  // 选择分类
  changeSort = (value, selectedOptions) => {
    if (value.length === 3) {
      this.getBrandList(value[value.length - 1]);
    }
  }
  // 获取品牌列表
  getBrandList = (categoryThirdId) => {
    axios.GoodsBrand.getBrandsList({categoryFirstId: '', categorySecondId: '', categoryThirdId, brandName: '', page: 0, size: 0}).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        this.setState({
          brandList: []
        });
        return false;
      }
      this.setState({
        brandList: data.result.list
      });
    });
  }
  // 加载分类
  loadSort = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    this.getSortList(targetOption);
  }

  // 渲染视图
  render() {
    const {stepList, sortOption, brandList} = this.state;
    const {currentStep} = this.props.specification;
    return (
      <div>
        <Steps current={currentStep}>
          {stepList.map(item => <Step key={item.title} title={item.title}/>)}
        </Steps>
        <div>
          <AddFirstForm loadSort={this.loadSort} changeSort={this.changeSort} sortOption={sortOption} brandList={brandList} wrappedComponentRef={(form) => this.addFirstForm = form} parentRef={this} visible={currentStep === 0 }/>
          <AddSecondForm  wrappedComponentRef={(form) => this.addSecondForm = form} parentRef={this} visible={currentStep === 1 }/>
          <AddThirdForm visible={currentStep === 2 } wrappedComponentRef={(form) => this.addThirdForm = form}/>
        </div>
      </div>
    )
  }
}

const mapStateProps = (state) => ({
  specification: state.specification,
  tabList: state.main.tabList,
  activeKey: state.main.activeKey
});

const mapDispatchProps = (dispatch) => ({
  updateForm: (value) => {
    SPECIFICATION.specification = value;
    dispatch(SPECIFICATION);
  }
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(AddSpecification);