import React from 'react';
import { Button, Table, message, Modal } from 'antd';
import EditForm from './components/editForm';
import SearchForm from './components/searchForm';
import ResetPasswordForm from './components/resetPasswordForm';
import moment from 'moment';
import axios from '../../../api';
const confirm = Modal.confirm;
// 表格列字段
const columns = [
  {
    title: '姓名',
    dataIndex: 'realName',
    key: 'realName',
  }, {
    title: '联系电话',
    dataIndex: 'contactPhone',
    key: 'contactPhone',
  }, {
    title: '角色名称',
    dataIndex: 'roleName',
    key: 'roleName',
  }, {
    title: '是否有效',
    dataIndex: 'adminManagerStatus',
    key: 'adminManagerStatus',
  }, {
    title: '最后登录时间',
    dataIndex: 'lastUpdateTime',
    key: 'lastUpdateTime',
  },
];
class UserManager extends React.Component {
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      data: [],
      searchForm: {
        name: '',
        managerStatus:'',
        contactPhone: '',
      },
      queryInfo: {
        page: 1,
        size:20,
      },
      isAdd: true,
      isOpen: false,
      item:{
        id: '',
        loginName: '',
        userName: '',
        mobile: '',
        roleName: '',
        isUsed: '',
        lastLoginTime: ''
      },
      roleList: [],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        onChange (page, size) {
          _this.onPageChange(page, size);
        },
        onShowSizeChange (current, size) {
          _this.onPageChange(current, size);
        }
      },
      selectedRowKeys: [],
      visibled: false,
    }
  }
  componentDidMount () {
    this.getUserManagerList();
   
  }
  getRoleList() {
    axios.Role.getRoleInfoList().then(res => {
      const data = res.data;
      if (data.code !== 2001 && data.code !== 1000) {
        return false;
      }
      this.setState({
        roleList: data.result,
      })
    })
  }
  getUserManagerList () {
    const {searchForm, pagination} = this.state;
    axios.User.getManagerInfoList({page: pagination.current, size: pagination.pageSize, name: searchForm.name, contactPhone: searchForm.contactPhone, managerStatus:searchForm.managerStatus}).then(res => {
      this.setState({
        loading: false,
        selectedRowKeys: []
      });
      const data = res.data;
      if (data.code !== 2001 && data.code !== 1000) {
        return false;
      }
     
      if (data.code === 2001) {
        const newPagination = Object.assign(pagination, {total: 0});
        this.setState({
          data: [],
          pagination: newPagination
        });
        return false;
      }
      if(data.code === 1000){
        const newPagination = Object.assign(pagination, {total: data.result.total});
        let lists = data.result.list.map(v => ({
          adminManagerStatus:v.adminManagerStatus===0 ? "有效" : "无效",
          id: v.id,
          accountNumber: v.accountNumber,
          realName: v.realName,
          contactPhone: v.contactPhone,
          roleId: v.roleId,
          roleName: v.roleName,
          password: v.password,
          lastUpdateTime:  moment(v.lastUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
        }));
        this.setState({
          data: lists,
          pagination: newPagination
        });
      }

    })
  }
  // 添加
  handleAdd = () => {
    this.getRoleList();
    const _item = {
      adminManagerStatus: '0'
    }
    this.setState({
      item: _item
    }, ()=> {
      this.setState({
        isAdd: true,
        isOpen: true,
      })
    })
  }
  handleEdit = () => {
    if (this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要编辑的记录');
      return false;
    }
    if (this.state.selectedRowKeys.length > 1) {
      message.warning('编辑记录只能选择一条');
      return false;
    }
    this.getRoleList();
    const chooseKey = this.state.selectedRowKeys[0];
    const items = this.state.data.find((v) => v.id === chooseKey);
    let o2 = Object.assign({}, items)
    o2.adminManagerStatus = items.adminManagerStatus === "有效" ? "0" : "1"
    this.setState({
      isAdd: false,
      isOpen: true,
      item: o2
    })
  }
  //删除
  handleDelete = () => {
    const _this = this;
    if (this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要删除的记录');
      return false;
    }
    confirm({
      title: '提示',
      content: '确认要删除选中记录吗？',
      onOk() {
        axios.User.delManagerInfo(_this.state.selectedRowKeys.toString()).then(res => {
          const data = res.data;
          if (data.code === 1000 && data.result) {
            message.success("删除成功")
            _this.setState({
              selectedRowKeys: []
            });
            _this.getUserManagerList();
            return false;
          }
        });
      }
    })
  }
  handleResetPassword = () => {
    if (this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要重置密码的记录');
      return false;
    }
    if (this.state.selectedRowKeys.length > 1) {
      message.warning('重置密码只能选择一条');
      return false;
    }
    this.setState({
      visibled:true,
    })
  }
  // 勾选表格多选框事件
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }
  //分页
  onPageChange = (current, pageNumber) => {
    this.setState({
      pagination: {current: current, pageSize: pageNumber}
    }, () => {
      this.getUserManagerList();
    });
  }

handleOk = () =>{
  this.editForm.props.form.validateFields((err, values) => {
    if (err) {
      return false;
    }
    this.submitData(values);
  });
 
}
handleCancel = (values) => {
  this.setState({
    isAdd: true,
    isOpen: false,
  })
  this.editForm.props.form.resetFields();
}
submitData = (data) => {
  const {item, isAdd} = this.state;
  const { password, realName, contactPhone, adminManagerStatus, roleId} = data;
  const requestData = {
    password, 
    realName, 
    contactPhone,
    adminManagerStatus: Number(adminManagerStatus), 
    roleId: roleId.toString(),
    id: isAdd ? undefined : item.id
  };
  let methodName = 'addManagerInfo';
  if (!isAdd) {
    methodName = 'updateManagerInfo';
  }
  axios.User[methodName](requestData).then(res => {
    const data = res.data;
    if (data.code === 1000 && data.result) {
      message.success("操作成功")
      this.editForm.props.form.resetFields();
      this.setState({
        isOpen:false,
        selectedRowKeys: [],
      },()=>{
        this.getUserManagerList();
      })
    return false;
    }
      return false;
    // this.setState({
    //   selectedRowKeys: null,
    //   editVisible: false,
    // });
   
  });
}
search = (e) => {
  e.preventDefault();
  const value = this.searchForm.props.form.getFieldsValue();
  this.setState({
    searchForm: value,
    pagination: {current: 1, pageSize: 10}
  }, () => {
    this.getUserManagerList();
  })
}
handleReset = (e) => {
  e.preventDefault();
  const value = this.resetPasswordForm.props.form.getFieldsValue();
  this.submitPasswordData(value);
}
handleResetCancel = () => {
  this.setState({
    visibled:false,
  })
  this.resetPasswordForm.props.form.resetFields();
}
submitPasswordData = (value) => { 
  const _this = this;
  const initPassord = '123456';
  const _password = value.password === '' ? initPassord : value.password;
  let _id = {
    id: Number(_this.state.selectedRowKeys[0]),
    password: _password
  }

  axios.User.resetPasswordManagerInfo(_id).then(res => {
    const data = res.data;
    if (data.code === 1000 && data.result) {
      message.success("重置成功");
      this.resetPasswordForm.props.form.resetFields();
      _this.setState({
        selectedRowKeys: [],
        visibled:false,
      });
      _this.getUserManagerList();
    
      return false;
    }
  });
}
  render() {
    const { selectedRowKeys, data, item , isAdd, isOpen, pagination, roleList, searchForm,visibled } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <SearchForm data={searchForm} search={this.search} wrappedComponentRef={(form) => this.searchForm = form}/>
        <div className="m-btn-group">
          <Button type="primary" onClick={this.handleAdd}>添加</Button>
          <Button type="primary" onClick={this.handleEdit}>编辑</Button>
          <Button type="danger" onClick={this.handleDelete}>删除</Button>
          <Button type="primary" onClick={this.handleResetPassword}>重置密码</Button>
        </div>
        <div>
          <Table
            pagination={pagination}
            loading={this.state.loading}
            rowKey={record => record.id}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
        </div>
        <EditForm wrappedComponentRef={(form) => this.editForm = form}  isAdd={isAdd} isOpen={isOpen} content={item} roleList={roleList} handleOk={this.handleOk} handleCancel = {this.handleCancel}/>
        <ResetPasswordForm wrappedComponentRef={(form) => this.resetPasswordForm = form} visibled={visibled} handleReset={this.handleReset} handleResetCancel={this.handleResetCancel}/>
      </div>
    )
  }
}

export default UserManager;