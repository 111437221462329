import React from 'react';
import {Modal, Form, Input} from 'antd';
class EditTabModal extends React.Component{
  render () {
    const {editTitle, editVisible, handleUpdate, handleCancel, tagMsg} = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        sm: { span: 6 },
      },
      wrapperCol: {
        sm: { span: 18 },
      },
    }
    return (
      <Modal destroyOnClose title={editTitle} visible={editVisible} onOk={handleUpdate} onCancel={handleCancel}>
        <Form>
          <Form.Item {...formItemLayout} label="标签名称">
            {getFieldDecorator('labelName', {
              initialValue: tagMsg && tagMsg.labelName,
                rules: [{
                  required: true, message: '请填写标签名称',
              }],
              })(
                <Input />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="排序">
            {getFieldDecorator('sortId', {
              initialValue: tagMsg && tagMsg.sortId,
              rules:[{
                required:false,
                pattern: new RegExp(/^(0)|([1-9]\d*)$/, "g"),
                message: '请输入正确的排序数字'
            }],
            })(
              <Input />
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
export default Form.create('edit_tag_form')(EditTabModal);