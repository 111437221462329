import React from 'react';
import { Form, Modal, Input, Radio, Divider, message } from 'antd';
import axios from '../../../../api';
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
class UpdateForm extends React.Component{
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  componentDidMount () {
    const {merchantId} = this.props;
    if (merchantId) {
      this.getInfo(merchantId);
    }
  }
  componentDidUpdate (prevProps, prevState) {
    const {form, merchantId} = this.props;
    if (prevProps.merchantId !== merchantId ) {
      form.resetFields();
      if (merchantId) {
        this.getInfo(merchantId);
      }
    }
  }

  // 获取商户信息
  getInfo = (id) => {
    axios.Merchant.getMerchantInfoById(id).then(res => {
      const {data} = res;
      if (data.code !== 1000) {
        this.props.onCancel();
        return false;
      }
      this.props.form.setFieldsValue(data.result);
    })
  }

  onOk = (e) => {
    const {merchantId, form} = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return false;
      }
      if (merchantId) {
        values.id = merchantId;
      }
      values.merchantCode = Number(values.merchantCode);
      values.merchantStoreCount = Number(values.merchantStoreCount);
      Object.keys(values).forEach(key => {
        if (typeof values[key] === 'object' && Object.keys(values[key]).every(item => !values[key][item])) {
          delete values[key];
        }
      });
      axios.Merchant.saveOrUpdateMerchantInfo(values).then(res => {
        const {data} = res;
        if (data.code !== 1000) {
          return false;
        }
        message.success('添加成功');
        this.props.onOk();
      })
    });
  }
  
  render () {
    const {visible, form, onCancel, merchantId} = this.props;
    const {getFieldDecorator} = form;
    return (
      <Modal width={700} visible={visible} title="新增/修改商户信息" onCancel={onCancel} onOk={this.onOk}>
        <Form style={{height: '400px', overflow: 'auto'}} {...formItemLayout}>
          <Form.Item label="商户名称">
            {getFieldDecorator('merchantName', {
              rules: [{
                required: true,
                message: '请输入商户名称'
              }]
            })(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="ServiceId">
            {getFieldDecorator('merchantCode', {
              rules: [{
                required: true,
                message: '请输入ServiceId'
              }]
            })(
              <Input disabled={merchantId} onBlur={this.validateMerchantCode}/>
            )}
          </Form.Item>
          <Form.Item label="联系人">
            {getFieldDecorator('merchantContact')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="联系电话">
            {getFieldDecorator('merchantContactPhone')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="门店数">
            {getFieldDecorator('merchantStoreCount', {
              rules: [{
                required: true,
                message: '请输入门店数'
              }]
            })(
              <Input type="number"/>
            )}
          </Form.Item>
          <Form.Item label="版本">
            {getFieldDecorator('versionType', {
              rules: [{
                required: true,
                message: '请选择版本'
              }]
            })(
              <Radio.Group>
                <Radio value={1}>平台版</Radio>
                <Radio value={2}>企业版</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="状态">
            {getFieldDecorator('merchantStatus', {
              rules: [{
                required: true,
                message: '请选择状态'
              }]
            })(
              <Radio.Group>
                <Radio value={1}>正常</Radio>
                <Radio value={0}>冻结</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="备注">
            {getFieldDecorator('remark')(
              <Input/>
            )}
          </Form.Item>
          <Divider>服务域名配置</Divider>
          <Form.Item label="商户服务域名">
            {getFieldDecorator('backgroundServicePack.merchantServiceUrl')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="公共库服务域名">
            {getFieldDecorator('backgroundServicePack.adminServiceUrl')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="微信能力中心服务域名">
            {getFieldDecorator('backgroundServicePack.weChatServiceUrl')(
              <Input/>
            )}
          </Form.Item>
          <Divider>DB数据库配置</Divider>
          <Form.Item label="Host">
            {getFieldDecorator('databasePack.host')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Port">
            {getFieldDecorator('databasePack.port')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Database">
            {getFieldDecorator('databasePack.database')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="UserName">
            {getFieldDecorator('databasePack.userName')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('databasePack.password')(
              <Input/>
            )}
          </Form.Item>
          <Divider>OpenSearch</Divider>
          <Form.Item label="应用名称">
            {getFieldDecorator('openSearchPack.opAppName')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Access Key">
            {getFieldDecorator('openSearchPack.opAccessKey')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Access Secret">
            {getFieldDecorator('openSearchPack.opAccessSecret')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="api地址">
            {getFieldDecorator('openSearchPack.opAccessApiUrl')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="表名">
            {getFieldDecorator('openSearchPack.opTableName')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="下拉框名字">
            {getFieldDecorator('openSearchPack.opSuggestName')(
              <Input/>
            )}
          </Form.Item>
          <Divider>Redis</Divider>
          <Form.Item label="Host">
            {getFieldDecorator('redisPack.redisHost')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('redisPack.redisPassword')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Port">
            {getFieldDecorator('redisPack.redisPort')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="DB">
            {getFieldDecorator('redisPack.db')(
              <Input/>
            )}
          </Form.Item>
          <Divider>Oss</Divider>
          <Form.Item label="Access Key">
            {getFieldDecorator('ossPack.ossAccessKey')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Access Secret">
            {getFieldDecorator('ossPack.ossAccessSecret')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="Bucket">
            {getFieldDecorator('ossPack.ossBucket')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="EndPoint">
            {getFieldDecorator('ossPack.ossEndPoint')(
              <Input/>
            )}
          </Form.Item>
          <Form.Item label="ossEndPointInternal">
            {getFieldDecorator('ossPack.ossEndPointInternal')(
              <Input/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
export default Form.create('merchant_form')(UpdateForm);