import React from 'react';
import { Layout, Menu, Icon } from 'antd';
// import { Link } from 'react-router-dom';
import routes from '../../router/config';
const {Sider} = Layout;

class LeftMenu extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
       
    }
  }

  renderSubMenu (item) {
    return (
      <Menu.SubMenu
          key={item.id}
          title={
              <span>
                  {item.iconName && <Icon type={item.iconName} />}
                  <span className="nav-text">{item.authorityName}</span>
              </span>
          }
      >
          {item.children.map(item => this.renderMenuItem(item))}
      </Menu.SubMenu>
    )
  }

  renderMenuItem (item) {
    return (
      <Menu.Item
          key={item.id} onClick={this.props.onMenuClick.bind(null, item)}>{item.authorityName}
          {/* <Link to={(item.route || item.key) + (item.query || '')}>   
              {item.icon && <Icon type={item.icon} />}
              <span className="nav-text">{item.title}</span>
          </Link> */}
      </Menu.Item>
    )
  }
  
  render () {
    return (
      <Sider style={{ background: '#fff' }}>
        <div className="m-logo"/>
        <Menu
          theme="dark"
          mode="inline"
          style={{ height: '100%', borderRight: 0 }}
        >
          {routes.menus && routes.menus.map(item => 
            item.children ? this.renderSubMenu(item) : this.renderMenuItem(item)
          )}
        </Menu>
      </Sider>
    )
  }
}
export default LeftMenu;