import React, { Component } from 'react'
import { Form, Input, Button, Table } from 'antd';
import axios from '../../../api';
import utils from '../../../util';

export class acount extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      tableData: [],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40'],
        total: 0,
        onChange: (page, size) => {
          this.handlePageChange(page, size);
        },
        onShowSizeChange: (current, size) => {
          this.handlePageChange(current, size);
        }
      },
    }
    this.columns = [{
      title: '商户编码',
      dataIndex: 'merchantCode',
    }, {
      title: '商户名称',
      dataIndex: 'merchantName',
    }, {
      title: '联系人',
      dataIndex: 'merchantContact',
    }, {
      title: '联系电话',
      dataIndex: 'merchantContactPhone',
    }, {
      title: '账户余额',
      align: 'right',
      dataIndex: 'accountBalance',
      render: cent => {
        cent = cent ? utils.centConvertYuan(cent) : 0;
        return utils.convertToMoney(cent);
      }
    }];
  }

  componentDidMount() {
    this.getList();
  }

  getList () {
    let {pagination} = this.state;
    const query = {
      ...this.props.form.getFieldsValue(),
      page: pagination.current,
      size: pagination.pageSize,
    };
    this.setState({ loading: true }, () => {
      axios.Finance.getMerchantPriceList(utils.getQueryString(query)).then(({data}) => {
        if (data.code !== 1000) {
          this.setState({ tableData: [], pagination: {...pagination, total: 0}, loading: false });
          return false;
        } 
        let { total, list: tableData } = data.result;
        this.setState({ tableData, pagination: {...pagination, total}, loading: false });
      });
    })
  }

  // 搜索
  search = (e) => {
    e.preventDefault();
    this.reGetList(1, this.state.pagination.pageSize);
  }

  //分页
  handlePageChange = (page, size) => {
    this.reGetList(page, size);
  }

  reGetList = (page, size) => {
    this.setState({ pagination: {...this.state.pagination, current: page, pageSize: size} }, () => {
      this.getList();
    });
  }

  // 导出
  download = () => {
    utils.exportXlsx(this.state.tableData, this.columns, '账户总览');
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const { tableData, pagination, loading } = this.state;
    return (
      <>
        <Form layout="inline">
          <Form.Item label="商户编码">
            {getFieldDecorator('merchantCode', { initialValue: '' })(<Input placeholder="商户编码" />)}
          </Form.Item>
          <Form.Item label="商户名称">
            {getFieldDecorator('merchantName', { initialValue: '' })(<Input placeholder="商户名称" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" ghost icon="search" onClick={this.search}>搜索</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.download}>导出当页</Button>
          </Form.Item>
        </Form>
        <Table rowKey="id" loading={loading} columns={this.columns} dataSource={tableData} pagination={pagination} />
      </>
    )
  }
}

export default Form.create()(acount);