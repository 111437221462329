import React from 'react';
import { Modal, Input, Form, Tree, Row, Col } from 'antd';
const { TextArea } = Input;
const { TreeNode } = Tree;
class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.isOpen || false,
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      treeSubmitKeys: []
    };
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate() {
  
  }
  handleComfirm = (e) => {
    const { handleOk } = this.props;
      handleOk()
  }
  renderTreeNodes = data => data.map((item) => {
    if (item.children) {
      return (
        <TreeNode title={item.authorityName} key={item.id} dataRef={item}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode title={item.authorityName} key={item.id} />;
  })
  render() {
    const { isOpen, isAdd, authorityList , handleCancel, content, checkedKeys, onCheck, onExpand, expandedKeys  } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
   
    return (
      <div>
        <Modal
          title={isAdd ? "新增角色信息" : "编辑角色信息"}
          forceRender
          visible={isOpen}
          onOk={this.handleComfirm}
          onCancel={handleCancel}
          width="70%"
        >
          <Form>
            <Form.Item
              {...formItemLayout}
              label="角色名称"
            >
              {getFieldDecorator('roleName', {
                initialValue: content&&content.roleName,
                rules: [{
                  required: true, message: '请填写角色名称',
                }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="备注"
            >
              {getFieldDecorator('remark', {
                initialValue: content&&content.remark,
              })(
                <TextArea placeholder="" autosize />
              )}
            </Form.Item>
          </Form>
          <div>
            <Row>
              <Col xs={24} sm={6} style={{ textAlign: "right", fontSize: "14px", lineHeight: "39px", color: "rgba(0, 0, 0, 0.85)" }}>权限选择:</Col>
              <Col xs={24} sm={18}>
                <Tree
                  checkable
                  selectable
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  onCheck={onCheck}
                  checkedKeys={checkedKeys}
                >
                  { authorityList && this.renderTreeNodes(authorityList)}
                </Tree>

              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}
const WrappedNormalEditForm = Form.create()(EditForm);
export default WrappedNormalEditForm;