import axios from './axios';
export default {
  getList (data) {
    return axios.get('/goodsSpecification/getGoodsSpecificationListPage', {params: data});
  },
  add (data) {
    return axios.post('/goodsSpecification/saveGoodsSpecification', data);
  },
  validateSpecificationName (params) {
    return axios.get('/goodsSpecification/validGoodsSpecificationName', {params});
  },
  getInfo(data) {
    return axios.get(`/goodsSpecification/getGoodsSpecificationDetailById/${data.id}`);
  },
  updateSpecification(data) {
    return axios.put('/goodsSpecification/updateGoodsSpecification', data);
  },
  validateAttrIsUsed(data) {
    return axios.get(`/goodsSpecification/validGoodsSpecificationAttributeRelationGoodsById/${data.id}`);
  },
  updateAttribute(data) {
    return axios.put('/goodsSpecification/updateGoodsSpecificationAttribute', data);
  },
  updateParam(data) {
    return axios.put('/goodsSpecification/updateGoodsSpecificationParameter', data);
  },
  validateParamIsUsed(data) {
    return axios.get(`/goodsSpecification/validGoodsSpecificationParameterRelationGoods/${data.id}`);
  },
  validateAttrValueIsUsed(data) {
    return axios.get(`/goodsSpecification/validGoodsSpecificationAttributeValueRelationGoodsById/${data.id}`);
  },
  del(ids) {
    return axios.delete(`/goodsSpecification/deleteGoodsSpecification/${ids}`);
  }
}