import React from 'react';
import { Form, Input, Button, Table, message, DatePicker } from 'antd';
import axios from '../../../api';
import moment from 'moment';
import utils from '../../../util';

const { RangePicker } = DatePicker;
class SysChargeDetail extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      tableData: [],
      loading: false,
      pagination: {
				pageSizeOptions: ['10', '20', '30', '40'],
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        onChange: (page, size)=> {
          this.handlePageChange(page, size);
        },
        onShowSizeChange: (current, size)=> {
          this.handlePageChange(current, size);
        }
      }
    }
    this.columns = [{
      title: '交易编号',
      dataIndex: 'cashFlowNumber',
    }, {
      title: '交易时间',
      dataIndex: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD HH:MM:SS')
    }, {
      title: '商户编码',
      dataIndex: 'merchantCode',
    }, {
      title: '商户名称',
      dataIndex: 'merchantName',
    }, {
      title: '交易类型',
      dataIndex: 'cashFlowType',
      render: type => {
        const tradeType = {
          1: '消费',
          2: '充值',
        };
        return tradeType[type];
      }
    }, {
      title: '交易渠道',
      dataIndex: 'cashFlowChannel',
      render: val => {
        const tradeChannel = {
          1: '余额',
          2: '支付宝',
        };
        return tradeChannel[val];
      }
    }, {
      title: '交易渠道流水号',
      dataIndex: 'cashFlowCode',
    }, {
      title: '账单号',
      dataIndex: 'billCode',
    }, {
      title: '交易备注',
      dataIndex: 'note',
    }, {
      title: '交易金额',
      dataIndex: 'cashFlowPrice',
      align: 'right',
      render: (cent, record) => {
        cent = cent ? utils.centConvertYuan(cent) : 0;
        if (record.cashFlowType === 1) {
          cent = -cent;
          return utils.convertToMoney(cent);
        } else {
          return `+¥${cent}`
        }
      }
    }, {
      title: '账户余额',
      dataIndex: 'accountBalance',
      align: 'right',
      render: cent => {
        cent = cent ? utils.centConvertYuan(cent) : 0;
        return utils.convertToMoney(cent);
      }
    },];
  }
  componentDidMount() {
    this.getList()
  }

  getList = () => {
    let {pagination} = this.state;
    let {time, ...others } = this.props.form.getFieldsValue();
    let query =  {
      ...others,
      page: pagination.current,
      size: pagination.pageSize,
    };
    if(time) {
      const dataFormat = 'YYYY-MM-DD';
      let [ startTime, endTime ] = time;
      startTime = startTime.format(dataFormat);
      endTime = endTime.format(dataFormat);
      query = { ...query, startTime, endTime };
    }
    this.setState({ loading: true }, () => {
      axios.Finance.getMerchantCashFlowList(utils.getQueryString(query)).then(({data}) => {
        if (data.code !== 1000) {
          this.setState({ tableData: [], pagination: {...pagination, total: 0}, loading: false });
          return;
        }
        let { total, list: tableData } = data.result;
        this.setState({ tableData, pagination: {...pagination, total}, loading: false });
      })
    })
  }

  // 搜索
  search = (e) => {
    e.preventDefault();
    this.reGetList(1, this.state.pagination.pageSize);
  }

  //分页
  handlePageChange = (page, size) => {
    this.reGetList(page, size);
  }

  reGetList = (page, size) => {
    this.setState({ pagination: {...this.state.pagination, current: page, pageSize: size} }, () => {
      this.getList();
    });
  }
  
  // 导出
  download = () => {
    utils.exportXlsx(this.state.tableData, this.columns, '资金明细');
  }
  
  // 时间判断
  onChangeTime = (moment) => {
    if (moment.length > 0) {
      if (moment[1].diff(moment[0], 'days') > 30 || moment[1].diff(moment[0], 'days') < -30) {
        message.warning('使用时间范围不能超过一个月')
      }
    }
  }
  render() {
    const { loading, pagination, tableData } = this.state;
    const { getFieldDecorator } = this.props.form;
    return(
      <>
        <Form layout="inline">
          <Form.Item label="交易时间：" placeholder="请输入交易时间：">
            {getFieldDecorator('time', {
              getValueFromEvent: (e) => {
                return e.length > 0 ? (e[1].diff(e[0], 'days') > 30 ? '' : e) : ''
              },
            })(<RangePicker onChange={this.onChangeTime} format="YYYY-MM-DD" /> )
            }
          </Form.Item>
					<Form.Item label="交易编号">
            {getFieldDecorator('cashFlowNumber',{ initialValue: '' })(<Input placeholder="交易编号" />)}
          </Form.Item>
          <Form.Item label="账单号">
            {getFieldDecorator('billCode',{ initialValue: '' })(<Input placeholder="账单号" />)}
          </Form.Item>
          <Form.Item label="交易渠道流水号">
            {getFieldDecorator('cashFlowCode',{ initialValue: '' })(<Input placeholder="交易渠道流水号" />)}
          </Form.Item>
          <Form.Item label="商户编码">
            {getFieldDecorator('merchantCode',{ initialValue: '' })(<Input placeholder="商户编码" />)}
          </Form.Item>
          <Form.Item label="商户名称">
            {getFieldDecorator('merchantName',{ initialValue: '' })(<Input placeholder="商户名称" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary"  ghost icon="search" onClick={this.search}>搜索</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.download}>导出当页</Button>
          </Form.Item>
				</Form>
        <Table rowKey='id' loading={loading} pagination={pagination}  columns={this.columns} dataSource={tableData} />
      </>
    )
  }
}
export default Form.create()(SysChargeDetail)

