import React from 'react';
import { Layout, Icon } from 'antd';
import utils from '../../util';
import PropTypes from 'prop-types';
const {Header} = Layout;
class Head extends React.Component{
  constructor(props, context) {
    super(props, context);
  
    this.state = {
       
    };
  }
  
  logout = (e) => {
    utils.removeSessionStorage('loginUserInfo');
    this.context.router.history.push('/login');
  }
  render () {
    const loginUserInfo = utils.getSessionStorage('loginUserInfo');
    const userInfo = JSON.parse(loginUserInfo);
    return (
      <Header className="m-header">
        <div className="logo"/>
        <ul className="m-header-menu">
          <li>
            <Icon type="user"/>
            <span className="menu-title">{userInfo.realName}</span>
          </li>
          <li>
            <Icon type="logout"/>
            <span className="menu-title" onClick={this.logout}>退出</span>
          </li>
        </ul>
      </Header>
    )
  }
}
Head.contextTypes = {
  router: PropTypes.object
}
export default Head;