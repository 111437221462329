import React from 'react';
import welcomePng from '../../assets/images/welcome.png';
class Home extends React.Component{
  render () {
    return (
      <div style={{textAlign: 'center',paddingTop: '80px'}}>
        <img alt="welcome" src={welcomePng}/>
      </div>
    )
  }
}
export default Home;