import React from 'react';
import { Button, Table, message, Modal } from 'antd';
import EditTabModal from './components/editTabModal';
import SearchForm from './components/searchForm';
import axios from '../../../api';
const confirm = Modal.confirm;
// 表格列字段
const columns = [
  {
    title: '标签名称',
    dataIndex: 'labelName',
    key: 'labelName',
  }, {
    title: '排序',
    dataIndex: 'sortId',
    key: 'sortId',
  },
];
class GoodsTag extends React.Component{
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      updateForm: {
        labelName: '',
        sortId: '',
        id: ''
      },
      searchForm: {
        name: ''
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        onChange (page, size) {
          _this.handlePage(page, size);
        },
        onShowSizeChange (current, size) {
          _this.handlePage(current, size);
        }
      },
      selectedRowKeys: null,
      data: [],
      loading: true,
      editVisible: false,
      isUpdate: false
    }
  }
  componentDidMount () {
    this.getTagList();
  }
  handlePage = (page, size) => {
    const newPagination = Object.assign({}, this.state.pagination, {current: page, pageSize: size});
    this.setState({
      pagination: newPagination
    }, () => {
      this.getTagList();
    })
  }
  // 搜索
  search = (e) => {
    e.preventDefault();
    const value = this.searchForm.props.form.getFieldsValue();
    const newPagination = Object.assign({}, this.state.pagination, {current: 1, pageSize: 10});
    this.setState({
      searchForm: value,
      pagination: newPagination
    }, () => {
      this.getTagList();
    })
  }
  // 获取标签列表
  getTagList = () => {
    const {searchForm, pagination} = this.state;
    this.setState({
      loading: true
    });
    axios.GoodsTag.getGoodsTagList({page: pagination.current, size: pagination.pageSize, labelName: searchForm.name}).then(res => {
      this.setState({
        loading: false
      });
      const data = res.data;
      if (data.code !== 2001 && data.code !== 1000) {
        return false;
      }
      const newPagination = Object.assign(pagination, {total: data.result.total})
      if (data.code === 2001) {
        this.setState({
          data: [],
          pagination: newPagination
        });
        return false;
      }
      this.setState({
        data: data.result.list,
        pagination: newPagination
      });
    })
  }

  // 勾选表格多选框事件
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  // 删除记录按钮点击事件
  delete = (e) => {
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要删除的规格');
      return false;
    }
    const _this = this;
    confirm({
      title: '提示',
      content: '确认要删除这写记录吗？',
      onOk() {
        axios.GoodsTag.delGoodsTag(_this.state.selectedRowKeys.toString()).then(res => {
          const data = res.data;
          if (data.code !== 1000) {
            return false;
          }
          _this.setState({
            selectedRowKeys: null
          });
          _this.getTagList();
        });
      }
    })
  }

  // 打开添加页面
  openAdd = () => {
    this.setState({
      editVisible: true,
      isUpdate: false,
      updateForm: {
        labelName: '',
        sortId: '',
        id: ''
      }
    })
  }

  // 打开编辑页面
  openEdit = () => {
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要编辑的标签');
      return false;
    }
    if(this.state.selectedRowKeys.length > 1) {
      message.warning('请选择一个您要编辑的标签');
      return false;
    }
    const chooseKey = this.state.selectedRowKeys[0];
    const item = this.state.data.find((v) => v.id === chooseKey);
    const {labelName, sortId, id} = item;
    this.setState({
      editVisible: true,
      isUpdate: true,
      updateForm: {
        labelName,
        sortId,
        id,
      }
    })
  }

  // 关闭编辑商品标签
  handleCancel = () => {
    this.setState({
      editVisible: false
    })
  }
  handleUpdate = () =>{
    this.editTagForm.props.form.validateFields((err, values) => {
      if (err) {
        return false;
      }
      this.sureUpdate(values);
    });
  }
  // 确定更新标签
  sureUpdate = (data) => {
    const {updateForm, isUpdate} = this.state;
    const {labelName, sortId} = data;
    const requestData = {
      labelName,
      sortId: Number(sortId),
      id: updateForm.id
    };
    let methodName = 'addGoodsTag';
    if (isUpdate) {
      methodName = 'editGoodsTag';
    }
    axios.GoodsTag[methodName](requestData).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        return false;
      }
      this.setState({
        selectedRowKeys: null,
        editVisible: false,
      });
      this.getTagList();
    });
  }
  render () {
    // const {getFieldDecorator} = this.props.form;
    const { selectedRowKeys, searchForm, pagination } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <SearchForm data={searchForm} search={this.search} wrappedComponentRef={(form) => this.searchForm = form}/>
        <div className="m-btn-group">
          <Button type="primary" onClick={this.openAdd}>添加</Button>
          <Button type="primary" onClick={this.openEdit}>编辑</Button>
          <Button type="danger" onClick={this.delete}>删除</Button>
        </div>
        <Table rowKey='id' size="middle" pagination={pagination} rowSelection={rowSelection} columns={columns} dataSource={this.state.data} loading={this.state.loading}/>
        <EditTabModal wrappedComponentRef={(form) => this.editTagForm = form} editTitle={this.state.isUpdate ? '编辑商品标签' : '添加商品标签'} editVisible={this.state.editVisible} tagMsg={this.state.updateForm} handleCancel={this.handleCancel} handleUpdate={this.handleUpdate}/>
      </div>
    )
  }
}


export default GoodsTag;
