import axios from './axios';
export default {
  login (data) {
    return axios.post(`/manager/getManagerInfoByAccountNumAndPwd`, data);
  },
  getManagerInfoList(data) {
    return axios.get('/manager/getManagerInfoListByNameAndStatusWithPage', {params: data});
  },
  addManagerInfo(data) {
    return axios.post('/manager/saveManagerInfo',data);
  },
  updateManagerInfo(data) {
    return axios.post('/manager/updateManagerInfo',data);
  },
  delManagerInfo(ids) {
    return axios.delete(`/manager/deleteManagerInfoBatch/${ids}`);
  },
  resetPasswordManagerInfo(data) {
    return axios.post('manager/updateManagerPwd',data);
  },
}