import axios from './axios';
export default {
  getGoodsTagList (data) {
    return axios.get(`/goodsLabel/getGoodsLabelByConditionWithPage?page=${data.page}&size=${data.size}&labelName=${data.labelName}`);
  },
  addGoodsTag (data) {
    return axios.post('/goodsLabel/saveGoodsLabel', data);
  },
  editGoodsTag (data) {
    return axios.put('/goodsLabel/updateGoodsLabel', data);
  },
  delGoodsTag (ids) {
    return axios.delete(`/goodsLabel/deleteGoodsLabelById/${ids}`);
  }
}