/*
 * @Author: LiuJiazhu 
 * @Date: 2019-02-15 09:28:54 
 * @Last Modified by: LiuJiazhu
 * @Last Modified time: 2019-03-14 10:36:11
 */
import React, { Component } from 'react';
import { Modal, Form, Input, Upload, Icon, Cascader, Tag } from 'antd';
import util from '../../../util';
import axios from '../../../api'
import './index.scss';
const sortFieldNames = {
    label: 'categoryName',
    value: 'id'
  }
class ModelForm1 extends Component {
    state = {
      previewVisible: false,
    //   previewImage: '',
      fileList: [],
      sortList: [],
    }
    componentDidMount () {
        this.handleSort();
    }

    // 预览图片
    // handlePreview = (file) => {
    //     this.setState({
    //         previewImage: file.url || file.thumbUrl,
    //         previewVisible: true,
    //     });
    // }
    handleCancelPreview = () => {
      this.setState({
        previewVisible: false,
      });
    }
    // 上传请求
    // uploadAction = (files) => {
    //     const {file} = files;
    //     const form = new FormData();
    //     form.append('file', file);
    //     form.append('catalog', 1);
    //     axios.Upload.uploadImg(form).then(res => {
    //         const data = res.data;
    //         if (data.code !== 1000) {
    //             return false;
    //         }
    //         let fileList = this.state.fileList[this.state.fileList.length-1];
    //         fileList.status = 'done';
    //         fileList.url = data.result;
    //         this.setState({
    //             fileList: this.state.fileList,
    //             previewImage: data.result
    //         });
    //     })
    // }
    // 选择分类
    handleSort = (changeParent = {id: 0, level: 0}) => {
        let { id, level } = changeParent;
        const requestData = {
            level: ++level,
            parentId: id,
            name: ''
        }
        axios.GoodsCategory.getCategoryList(requestData).then(res => {
            const data =res.data;
            changeParent.loading = false;
            if (data.code !== 1000) {
                if(data.chDesc === '无法获取有效值'){
                    // message.error("该分类下没有三级分类无法关联");
                }
                return false;
            }
            data.result.forEach((v) => {
                v.level = level;
                v.key = v.id;
                if (level !== 3) {
                v.isLeaf = false;
                }
            })
            if (level === 1) {
                this.setState({sortList: data.result});
                return;
            }
            changeParent.children = data.result;
            this.setState({
                sortList: [...this.state.sortList],
            });
        })
    }
    
    // 加载分类
    loadSort = (selectedOptions) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        this.handleSort(targetOption);
    }
    // 获取最后一类
    displayRender = (label, selectedOptions) => {
        return label[label.length - 1];
    }
    beforeUpload = (file) => {
        if(!util.validateFileSize(file)){
          return false;
        }
        return true;
    }
    handleChange = ({ fileList }) => this.setState({ fileList })
    render() {
        const { defaultValue, showSelect, visibleAdd, handleCancel, handleCreate, handleChangePreview, modelTitle, brandMsg, handleUpdate, removeTags, handleKeyDown, autoFocus, newDataTags, onChangeSort } = this.props;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 4 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
        };
         const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <Modal
            title={modelTitle}
            width="60%"
            visible={visibleAdd}
            onOk={modelTitle === '编辑商品品牌' ? handleUpdate : handleCreate}
            onCancel={handleCancel}
            >
            <Form>
                <Form.Item
                {...formItemLayout}
                label="品牌名称"
                >
                {getFieldDecorator('brandName', {
                initialValue: brandMsg && brandMsg.brandName,
                    rules: [{
                    required: true, message: '请填写品牌名称',
                }],
                })(
                    <Input />
                )}
                </Form.Item>
                <Form.Item
                {...formItemLayout}
                label="英文名称"
                >
                {getFieldDecorator('brandEnName', {
                initialValue: brandMsg && brandMsg.brandEnName})(
                    <Input />
                )}
                </Form.Item>
                <Form.Item
                {...formItemLayout}
                label="官网地址"
                >
                {getFieldDecorator('brandWebsite', {
                initialValue: brandMsg && brandMsg.brandWebsite})(
                    <Input />
                )}
                </Form.Item>
                <Form.Item
                {...formItemLayout}
                label="LOGO"
                >
                {getFieldDecorator('brandImgUrl')(
                    <div className="clearfix">
                    <Upload listType="picture-card" className="avatar-uploader" beforeUpload={this.beforeUpload} customRequest={handleChangePreview} showUploadList={false}>
                    {brandMsg.brandImgUrl ? <img src={brandMsg.brandImgUrl} alt="图标"/> : uploadButton}
                    </Upload>
                </div>
                )}
                </Form.Item>
                <Form.Item
                {...formItemLayout}
                label="关联分类"
                >
                {getFieldDecorator('categoryIdList', {
                initialValue: brandMsg && brandMsg.categoryIdList})(
                  <div className="tag-box" id="tagHiddenId66">
                    <div className="tag-content" onClick={autoFocus}>
                      {(newDataTags && newDataTags.length > 0) ? newDataTags.map((text,index) => <Tag key={index} closable onClose={removeTags.bind(this, text.id)}>{text.categoryName}</Tag>) : null}
                      <input type="text" className="tagHidden" id="tagHiddenId" onKeyDown={ handleKeyDown.bind(this) }/>
                    </div>
                    <div className="tag-select">
                    <Cascader
                      value={defaultValue} 
                      className={ showSelect ? null : "hideCascader" }
                      fieldNames={sortFieldNames} 
                      options={this.state.sortList}
                      displayRender={this.displayRender}
                      loadData={this.loadSort}
                      onChange={onChangeSort}
                    />
                    </div>
                  </div>
                )}
                </Form.Item>
                <Form.Item
                {...formItemLayout}
                label="备注"
                >
                {getFieldDecorator('remark', {
                initialValue: brandMsg && brandMsg.remark})(
                    <Input/>
                )}
                </Form.Item>
                </Form>
            </Modal>
        )
    }
}
const ModelForm = Form.create({})(ModelForm1);
export default ModelForm;

