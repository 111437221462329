import React, { Component } from 'react';
import { Modal, Form, Radio, Row, Col, Checkbox, message } from 'antd';
import axios from '../../../../api';
import { versionOptions } from './permissionMenus';
import './settingModal.scss';

class SettingModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      visible: false,
      functionList: [],
      secMenus: {},
      proFunctionInfoList: [], // [2, 3, 4, 8, 9, 11, 14, 16, 18, 21, 22]
      flagshipFunctionInfoList: [], //[2, 3, 4, 6, 8, 9, 10, 11, 12, 14, 16, 18, 19, 20, 22, 25, 26]
      checkKeys: [],
      defaultCheckKeys: [],
      permissionId: null,
    }
  }

  componentDidMount () {
    this.getAllFunctionList();
    this.getAllVersionList();
  }

  componentWillReceiveProps(nextProps) {//componentWillReceiveProps方法中第一个参数代表即将传入的新的Props
    const {visible} = this.props;
    const {serviceId} = nextProps;
    if (nextProps.visible !== visible && serviceId && nextProps.visible) {
      this.getMerchantInfo(serviceId);
      this.getFunctionListByServiceId(serviceId);
    }
  }

  // 获取商户信息
  getMerchantInfo = (serviceId) => {
    let { onCancel, form } = this.props;
    axios.Merchant.getMerchantInfoById(serviceId).then(({data}) => {
      if (data.code !== 1000) {
        onCancel();
        return false;
      }
      let { versionType} = data.result;
      form.setFieldsValue({version: versionType});
    });
  }

  getFunctionListByServiceId (serviceId) {
    axios.FunctionalPermission.getFunctionListByServiceId({serviceId}).then(res => {
      let {code, result} = res.data;
      if (code === 1000) {
        this.setState({ permissionId: result.id });
        Object.keys(this.state.secMenus).forEach(key => {
          this.checkAllFirMenus(parseInt(key), result.functionIdList, true);
        });
      }
    })
  }

  getAllFunctionList () {
    axios.FunctionalPermission.getFunctionList().then(res => {
      if (res.data.code !== 1000) {
        return false;
      }
      let rowsAry = res.data.result;
      let secMenus = this.getSecMenus(rowsAry);
      this.setState({ functionList: rowsAry, secMenus });
    })
  }
  getAllVersionList () {
    axios.FunctionalPermission.getVersionList().then(res => {
      if (res.data.code !== 1000) {
        return false;
      }
      let { proFunctionInfoList, flagshipFunctionInfoList } = res.data.result;
      this.setState({
        flagshipFunctionInfoList,
        proFunctionInfoList,
      });
    })
  }

  getSecMenus (functionList) {
    let obj = {};
    functionList.forEach(item => {
      if (item.children && item.children.length > 0) {
        let childIdList = [];
        item.children.forEach(child => {
          childIdList.push(child.id);
        })
        obj[item.id] = childIdList;
      } else {
        obj[item.id] = [item.id];
      }
    });
    return obj;
  }

  saveEdit = () => {
    let { version } = this.props.form.getFieldsValue(['version']);
    let { checkKeys, secMenus, permissionId } = this.state;
    let { serviceId } = this.props;
    Object.keys(secMenus).forEach(key => {
      checkKeys.indexOf(parseInt(key)) > -1 && checkKeys.splice(checkKeys.indexOf(parseInt(key)), 1);
    });
    let obj = {
      id: permissionId,
      versionType: version,
      serviceId: serviceId,
      functionId: JSON.stringify(checkKeys),
    };

    if (!permissionId) delete obj.id;

    axios.FunctionalPermission.updateMerchantFunctionList(obj).then(res => {
      if (res.data.code !== 1000) {
        return false;
      }
      message.success('修改成功');
      this.closeModal();
    });
  }

  closeModal = () => {
    const { form, onCancel } = this.props;
    this.setState({
      checkKeys: [],
      defaultCheckKeys: [],
      permissionId: null,
    }, () => {
      form.resetFields();
      onCancel();
    });
  }

  selectVersion (e) {
    let { value } = e.target;
    let { proFunctionInfoList, flagshipFunctionInfoList, secMenus, defaultCheckKeys } = this.state;
    let ary = [];
    switch (value) {
      case 1:
      default: break;
      case 2: ary = proFunctionInfoList; break;
      case 3: ary = flagshipFunctionInfoList; break;
    }
    ary = Array.from(new Set([...ary, ...defaultCheckKeys]));

    this.setState({ checkKeys: ary }, () => {
      Object.keys(secMenus).forEach(key => {
        this.checkAllFirMenus(parseInt(key));
      });
    });
  }

  checkAllSecMenus = (e, parentId) => {
    let { secMenus, checkKeys, defaultCheckKeys } = this.state;
    let secMenusAry = secMenus[parentId];
    if (e.target.checked && checkKeys.indexOf(parentId) === -1) {
      checkKeys = checkKeys ? Array.from(new Set([...checkKeys, ...secMenusAry])) : secMenusAry;
      checkKeys = [...checkKeys, parentId];
    } else if (checkKeys.indexOf(parentId) > -1) {
      // 去除某一功能全选
      checkKeys = checkKeys.filter(item => {
        return !secMenusAry.includes(item) && item !== parentId;
      });
      checkKeys = checkKeys ? Array.from(new Set([...checkKeys, ...defaultCheckKeys])) : defaultCheckKeys;
      this.modifyVersion(checkKeys);
    }
    this.setState({ checkKeys });
  }

  checkOneSecMenu = (e, parentId) => {
    let { checkKeys } = this.state;
    let { value, checked } = e.target;
    value = parseInt(value);
    if (checked && checkKeys.indexOf(value) === -1) {
      checkKeys = [...checkKeys, value];
    } else if (checkKeys.indexOf(value) > -1) {
      checkKeys = checkKeys.filter(item => item !== value);
      this.modifyVersion(checkKeys);
    }
    this.setState({ checkKeys: checkKeys }, () => {
      this.checkAllFirMenus(parentId);
    });
  }

  // 判断一个数组内是否包含另一数组
  isContained (includeAry, includedAry) {
    let flag = 0;
    if(!(includeAry instanceof Array) || !(includedAry instanceof Array) || ((includeAry.length < includedAry.length))) {
      return false;
    }
    includeAry = Array.from(new Set(includeAry.sort()));
    includedAry = Array.from(new Set(includedAry.sort()));
    for (var i = 0; i < includedAry.length; i++) {
      for(var j = 0; j < includeAry.length; j++){
        flag = includeAry[j] === includedAry[i] ? flag + 1 : flag;
      }
    }
    return flag === includedAry.length;
  }

  checkAllFirMenus = (parentId, defaultKeys = null, isInit = false) => {
    let { checkKeys, secMenus } = this.state;
    checkKeys = defaultKeys || checkKeys;
    if (this.isContained(checkKeys, secMenus[parentId]) && checkKeys.indexOf(parentId) === -1) {
      checkKeys.push(parentId)
    } else if (!this.isContained(checkKeys, secMenus[parentId]) && checkKeys.indexOf(parentId) > -1) {
      checkKeys = checkKeys.filter(item => item !== parentId);
    }
    if (isInit) {
      this.setState({ defaultCheckKeys: checkKeys});
    }
    this.setState({ checkKeys });
  }

  modifyVersion (checkKeys) {
    let { proFunctionInfoList, flagshipFunctionInfoList } = this.state;
    let { version } = this.props.form.getFieldsValue();
    if (
      version !== 1 &&
      !(this.isContained(checkKeys, flagshipFunctionInfoList) || this.isContained(checkKeys, proFunctionInfoList))
    ) {
      this.props.form.setFieldsValue({ version: 1 });
    }
  }

  getRenderFormItem (functionList) {
    let { defaultCheckKeys } = this.state;
    return functionList.map(item => {
      return (
        <div className="m-margin-top__bottom" key={item.id}>
          <Checkbox disabled={defaultCheckKeys.includes(item.id)} value={item.id} onChange={(e) => this.checkAllSecMenus(e, item.id)}><strong>{item.functionName}</strong></Checkbox>
          <Row>
            { item.children && item.children.length > 0 && item.children.map(child => {
              return (
                <Col key={child.id} span={8}>
                  <Checkbox disabled={defaultCheckKeys.includes(child.id)} value={child.id} onClick={(e) => this.checkOneSecMenu(e, item.id)}>{child.functionName}</Checkbox>
                </Col>
              )
            }) }
          </Row>
        </div>
      )
    })
  }

  render () {
    const { functionList, checkKeys } = this.state;
    const { visible } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title="权限功能设置"
        width="70%"
        visible={visible}
        onOk={this.saveEdit}
        onCancel={this.closeModal}
        okText="确认"
        cancelText="取消"
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="系统版本">
            {getFieldDecorator('version', {initialValue: 1})(
              <Radio.Group onChange={this.selectVersion.bind(this)}>
                {versionOptions.length > 0 && versionOptions.map(item => 
                  <Radio value={item.value} key={item.value}>{item.label}</Radio>
                )}
              </Radio.Group>,
            )}
          </Form.Item>
          <Form.Item label="可选功能">
            <Checkbox.Group value={checkKeys} style={{ width: '100%' }}>
              { functionList.length > 0 && this.getRenderFormItem(functionList) }
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default Form.create()(SettingModal);