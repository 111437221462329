import React from 'react';
import {Icon} from 'antd';
class InputGroup extends React.Component{
  render () {
    return (
      <div className="m-input-group">
        <Icon className="m-input__icon" type={this.props.iconType}/>
        <input type={this.props.type} placeholder={this.props.placeholder} name={this.props.name} onChange={(e) => this.props.onChange(this.props.name, e)}/>
      </div>
    )
  }
}
export default InputGroup;