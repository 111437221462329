import React from 'react';
import {Icon,Input} from 'antd';
class EditTag extends React.Component{
  render () {
    const {isEdit, doubleClickTag,data, delTag, index, parentIndex, enterClick, tagBlur, updateTag} = this.props;
    return (
      <div className="m-edit-tag" onDoubleClick={doubleClickTag.bind(null, index, parentIndex)}>
        <Input size="small" id={`tag${parentIndex}_${index}`} value={data} className={`tag__edit ${isEdit ? '' : 'dn'}`} onPressEnter={enterClick.bind(null, index, parentIndex)} onChange={updateTag.bind(null, index, parentIndex)} onBlur={tagBlur.bind(null, index, parentIndex)}/>
        <div className={`tag__show ${isEdit ? 'dn' : ''}`}>
          <span className="tag__span">{data}</span>
          <Icon type="close" onClick={delTag.bind(null, index, parentIndex)}/>
        </div>
      </div>
    )
  }
}
export default EditTag;