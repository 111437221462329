import React from 'react';
import InputGroup from './components/inputGroup';
import './index.scss';
import { message } from 'antd';
import {TABS} from '../../store/action';
import {connect} from 'react-redux';
import axios from '../../api';
import utils from '../../util';
import config from '../../router/config';
class Login extends React.Component{
  constructor () {
    super();
    this.state = {
      userName: '',
      password: ''
    };
  }
  handleInputChange = (name, e) => {
    this.setState({[name]: e.target.value});
  }
  goLogin = (e) => {
    const {history} = this.props;
    if (!this.state.userName || !this.state.password) {
      message.error('请输入用户名和密码');
      return false;
    }
    axios.User.login({accountNumber: this.state.userName, password: this.state.password}).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        // message.error('用户名或密码错误');
        return false;
      }
      config.menus = data.result.menuList;
      utils.setSessionStorage('loginUserInfo', JSON.stringify(data.result));
      history.push('/main');
    });
  }
  componentDidMount(){
    //组件挂载时候，注册keypress事件
    document.addEventListener('keypress',this.handleEnterKey);
  }
  componentWillUnmount() {
    //组件卸载时候，注销keypress事件
    document.removeEventListener("keypress",this.handleEnterKey);
  }

  handleEnterKey = (e) => {
    if(e.keyCode === 13){
      e.preventDefault();
      this.goLogin();
    }
  }
  render () {
    return (
      <div className="u-login-page">
        <div className="u-login-form">
          <h2>后台管理系统登录</h2>
          <InputGroup iconType="user" type="text" placeholder="用户名" name="userName" onChange={this.handleInputChange}/>
          <InputGroup iconType="lock" type="password" placeholder="密码" name="password" onChange={this.handleInputChange}/>
          <button className="u-login-btn" type="button" onClick={this.goLogin}>登录</button>
        </div>
        <a className='m-footer' rel="noopener noreferrer" target='_blank' href='http://www.beian.miit.gov.cn'>浙ICP备17023963号-1</a>
      </div>
    )
  }
}

const mapStateProps = (state) => ({
  tabList: state.main.tabList,
  activeKey: state.main.activeKey
});

const mapDispatchProps = (dispatch) => ({
  pushTabs: (value) => {
    TABS.main = {
      tabList: value.tabList,
      activeKey: value.activeKey
    }
    dispatch(TABS);
  }
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(Login);