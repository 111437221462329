import XLSX from 'xlsx';
import {message} from 'antd'; 
export default {
  getSessionStorage (name) {
    if (!name) return;
    return window.sessionStorage.getItem(name);
  },
  setSessionStorage (name, content) {
    if (!name) return;
    if (typeof content !== 'string') {
      content = JSON.stringify(content);
    }
    window.sessionStorage.setItem(name, content);
  },
  removeSessionStorage (name) {
    if (!name) return;
    window.sessionStorage.removeItem(name);
  },
  // 验证文件尺寸大小
  validateFileSize(file) {
    if (file.size > 10 * 1024 * 1024) {
      message.error('请上传小于10M的文件');
      return false;
    }
    return true;
  },
/**
   * 导出Excel
   * @param {Array} sourceData 导出数据
   * @param {Array} columns 导出的表头
   * @param {Array} title 导出的表名
   */
  exportXlsx(sourceData, columns, title = "表单", otherRender = {}) {
    if(!sourceData || sourceData.length === 0){
      message.warning('暂无数据导出');
      return ;
    }
    const method = {
      // 类型转换
      anyToText(reactDOM, col, record) {
        let result = '';
        
        if (reactDOM === 'undefined') return result;
        
        if (typeof reactDOM === 'string' || typeof reactDOM == "number") {
          result = reactDOM;
        }
        
        if (Array.isArray(reactDOM)) {
          result += reactDOM.map(item => {
            return `${this.anyToText(item)}`
          })
        }
        
        if (reactDOM.type === 'img') {
          result += `${reactDOM.props.src},`;
        }
        
        if (reactDOM.props) {
          if (Array.isArray(reactDOM.props.children)) {
            result += reactDOM.props.children.map(item => {
              if (typeof item !== "number" && typeof item !== "string") {
                return `${this.anyToText(item)}/--/`;
              }
              return item
            })
            return result;
          }else if (typeof reactDOM.props.children == 'object') {
            const value = typeof record[col.dataIndex] === 'undefined' ? '' : record[col.dataIndex];
            if (otherRender[col['dataIndex']]) {
              const r = otherRender[col['dataIndex']](value ,record);
              return typeof r === 'undefined' ? '' : r;
            }
            return result;
          }
          return typeof reactDOM.props.children === 'undefined' ? result : reactDOM.props.children
        }

        return result;
      },
      // 导出xlsx
      exportXLSX() {
        const header = [];
        const data = sourceData.map((item, idx) => {
          const result = {};
          const lineData = columns.map(col => {
            const Key = col.title;
            const value = typeof item[col.dataIndex] === 'undefined' ? '' : item[col.dataIndex];
            if (!idx) {
              header.push(Key)
            }

            let renderValue = '';
            if(otherRender[col.dataIndex]){
              renderValue = otherRender[col.dataIndex](value, item, idx)
            }else{
              renderValue = col.render ? this.anyToText(col.render(value, item, idx), col, item) : value;
            }
            
            const result = renderValue.toString().split(',').join('').split('/--/').join(',');

            return { [Key]: result.endsWith(',') ? result.slice(0, result.length - 1) : result }
          })
          lineData.map(item => Object.assign(result, item))
          return result;
        })

        var wb = XLSX.utils.book_new();
        /* make worksheet */
        var ws = XLSX.utils.json_to_sheet(data, { header });

        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, `${title}.xlsx`, { compression: true });
      }
    }
    method.exportXLSX();
  },
  /**
   * 导入Excel（只读取Excel第一个工作表）
   * @param {*} file input file
   * @return {Array} 返回数组
   */
  getDataFromXlsx (file) {
    return new Promise(function(resolve) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = e => {
        let wb = XLSX.read(e.target.result, {
          type: 'binary',
        });
        // 只读取第一个工作表
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {header: 'A'});
        resolve(data);
      }
    });
  },
  /**
   * 拼接对象成字符串
   * @param {Object} 
   * @return {string} 返回request查询字符串
   */
  getQueryString (obj) {
    let ary = [];
    Object.entries(obj).forEach(item => {
      ary.push(`${item[0]}=${item[1]}`);
    });
    return ary.join('&');
  },
  /**
   * 单位分转换为元
   * @param {Integer} num 待格式化的金额
   * @return {String} 格式化后的金额
   */
  centConvertYuan(num) {
    return (num / 100).toFixed(2);
  },
  /**
   * 元转换为分
   * @param {Integer} num 待格式化的金额
   * @return {String} 格式化后的金额
   */
  yuanConvertCent(num) {
    return parseInt(Math.round(num * 100));
  },
  convertToMoney (num) {
    num = num >= 0 ?`￥${num}` : `-￥${Math.abs(num)}`;
    return num;
  }
}