import React from 'react';
import AllPages from '../app';
/**
 * 添加新标签页并打开
 * @param {*} tabList redux中tabList的值
 * @param {*} item 要添加的tab信息。ex: {authorityName: 标题, authorityUrl: 组件名称, param: 参数对象}
 */
export const commonPushTabs = (tabList, item) => {
  const tabIndex = tabList.findIndex(v => v.authorityUrl === item.authorityUrl);
  if (tabIndex !== -1) {
    tabList[tabIndex].params = item.params;
    return {
      activeKey: item.authorityUrl,
      tabList: tabList
    };
  }
  const ItemComponent = AllPages[item.authorityUrl];
  return {
    tabList: tabList.concat({'authorityName': item.authorityName, 'authorityUrl': item.authorityUrl, closable: true, content: <ItemComponent/>, params: item.params}),
    activeKey: item.authorityUrl
  };
}

export const commonCloseTab = (tabList, closeComponentName) => {
  let lastIndex = tabList.findIndex((v) => v.authorityUrl === closeComponentName) - 1;
  if (lastIndex < 0) {
    lastIndex = 0;
  }
  const panes = tabList.filter((v) => 
    v.authorityUrl !== closeComponentName
  );
  return {tabList: panes, activeKey: panes[lastIndex].authorityUrl};
}

export const pushParam = (tabList, componentName, param) => {
  let tabItem = tabList.find((v) => v.authorityUrl === componentName);
  tabItem.params = Object.assign({}, tabItem.params, param);
}