import React, { Component } from 'react';
import { Modal, Form, Button, Upload, Icon, message } from "antd";
import utils from "../../../../util";
import axios from "../../../../api";
import template from "../../../../assets/template.xlsx";

export class UploadModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isSuccess: false,
      failData: [],
    }
  }
  // 上传Excel
  upload = (e) => {
    const fileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const fileTail = ['xls', 'xlsx'];
    let { file } = e;
    let nameTail = file.name && file.name.split('.');
    if (file.type && (!fileType.includes(file.type) || !fileTail.includes(nameTail[nameTail.length - 1]))) {
      message.error('请上传xls, xlsx格式的文件！');
      return;
    }
    utils.getDataFromXlsx(file).then(res => {
      let listBill = [];
      if (!this.validateModel(res[0])) {
        message.error('与模板不一致，请重新下载模板填写后上传');
        return;
      }
      res.splice(0, 1);
      for (let i = 0; i < res.length; i++) {
        let errRow = i+2;
        let errMsg = null;
        let dataReg = /(2\d{3})\/(0[1-9]|1[0-2])\/([0-2][0-9]|3[0-1])\-(2\d{3})\/(0[1-9]|1[0-2])\/([0-2][0-9]|3[0-1])/;
        let { A: merchantCode, B: merchantName, C, D: billType, E: cost, F: note } = res[i];
        let [consumptionStartTime, consumptionEndTime] = C.split('-');
        let {y: startY, m: startM, d: startD, str: startTime} = this.convertDate(consumptionStartTime);
        let {y: endY, m: endM, d: endD, str: endTime} = this.convertDate(consumptionEndTime);
        
        if (!merchantCode || !merchantName || !C || !billType || !cost || !note ) errMsg = `第${errRow}行数据的值不能为空`;
        if (!dataReg.test(C)) errMsg = `第${errRow}行日期数据格式错误或日期超出正常月/日`;
        if (cost < 0) errMsg = `第${errRow}行应付金额不能为负数`;
        if (new Date(startY, startM, startD) > new Date(endY, endM, endD)) errMsg = `第${errRow}行开始日期不能大于结束日期`;
        if (errMsg) {
          message.error(errMsg);
          return;
        }
        listBill.push({ merchantCode, merchantName, consumptionStartTime: startTime, consumptionEndTime: endTime, billType, cost, note })
      }
      listBill.length > 0 && axios.Finance.addMerchantBill({listBill}).then(({data}) => {
        if (data.code === 2015)  this.setState({ failData: data.result });
        if (data.code !== 1000) return;
        message.success('导入成功');
        this.setState({ isSuccess: true });
        this.props.onCancel();
        this.props.updateList();
      })
    });
  }

  // 失败反馈
  getFailReason = () => {
    const header = [{
      title: '错误行数',
      dataIndex: 'line',
    }, {
      title: '错误原因',
      dataIndex: 'desc',
    }];
    utils.exportXlsx(this.state.failData, header, '账单导入失败反馈');
  }

  // 验证模板是否正确
  validateModel = (obj) => {
    const modelHeaderAry = ['商户编码', '商户名称', '消费时间', '费用类型', '应付金额', '交易备注'];
    return Object.values(obj).every((item, index) => {
      return modelHeaderAry[index] === item;
    });
  }

  convertDate = (str) => {
    let strArr = str && str.split('/');
    return {
      y: strArr[0],
      m: strArr[1],
      d: strArr[2],
      str: strArr.join('-'),
    };
  }
  // 关闭弹窗
  closeModal = () => {
    if (this.state.isSuccess) {
      this.props.updateList();
    }
    this.setState({ failData: [], isSuccess: false });
    this.props.onCancel();
  }
  // 下载模板
  getMerchantBillTpl = () => {
    window.location.href = template;
  }

  render() {
    const { visible } = this.props;
    const { failData } = this.state;
    return (
      <Modal width={700} visible={visible} title="导入账单" onCancel={this.closeModal} onOk={this.closeModal}>
        <Form>
          <Form.Item>
            <Button type="primary" onClick={this.getMerchantBillTpl}>下载模板</Button>
            <p>温馨提示：导入前请下载模版按要求填入导入数据再点击“点击上传”，否则可能会导致上传失败！</p>
          </Form.Item>
					<Form.Item label="上传">
            <Upload showUploadList={false} customRequest={this.upload}>
              <Button type="primary">
                <Icon type="upload" /> 上传
              </Button>
            </Upload>
          </Form.Item>
          { failData.length > 0 && (
            <Form.Item>
              <p style={{color: '#f20'}}>上传失败，详情请下载查看：</p>
              <Button type="danger" onClick={this.getFailReason}>下载失败信息</Button>
            </Form.Item>
          )}
				</Form>
      </Modal>
    )
  }
}

export default Form.create()(UploadModal);
