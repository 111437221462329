import React from 'react';
import { Form, Button, Row, Col, Input, Modal, message } from 'antd';
import {SPECIFICATION} from '../../../../store/action';
import {TABS} from '../../../../store/action';
import {connect} from 'react-redux';
import axios from '../../../../api';
import {commonCloseTab} from '../../../../util/tabs';
const confirm = Modal.confirm;
const tableInputLayout = {
  size: 'small',
}
const MENUKEY = 'AddSpecification';
class AddThirdForm extends React.Component{
  submit = () => {
    const {paramList} = this.props.specification;
    let repeatCount = 0;
    for (let i = 0; i < paramList.length; i++) {
      let v = paramList[i];
      if (!v.name) {
        message.error('规格参数名称不能为空');
        return false;
      }
      if (!this.validateNameIsRepeat(v.name)) {
        repeatCount ++;
      }
    }
    if (repeatCount !== 0) {
      message.error('规格参数名称不可以重复');
      return false;
    }
    this.sureSubmit();
  }
  sureSubmit = () => {
    const {specification} = this.props;
    const {id, brand, name, describe, sort, attrList, paramList} = specification;
    console.log(paramList);
    const _this = this;
    // 进行更新操作
    if (id) {
      const newParamList = [];
      paramList.forEach((v) => {
        const paramItem = {
          specificationParameterName: v.name,
          specificationParameterAlias: v.rename,
        };
        if (v.id) {
          paramItem.id = v.id;
        }
        newParamList.push(paramItem);
      });
      axios.Specification.updateParam({specificationId: id, goodsSpecificationParameter: JSON.stringify(newParamList)}).then(res => {
        const data = res.data;
        if (data.code !== 1000) {
          return false;
        }
        Modal.success({
          title: '提示',
          content: '修改成功',
          onOk() {
            const tabObj = commonCloseTab(_this.props.tabList, MENUKEY);
            _this.props.pushTabs(tabObj);
          }
        });
      });
    } else {
      let requestObj = {};
      requestObj.goodsSpecificationDO = {
        brandId: brand.toString(),
        categoryId: sort[sort.length - 1],
        remark: describe,
        specificationName: name
      };
      requestObj.goodsSpecificationAttributeDO = [];
      attrList.forEach((v) => {
        let attrItem = {
          attributeName: v.name,
          attributeAlias: v.rename,
          sortId: v.sort,
          parameterValueStr: []
        };
        v.valueList.forEach((item) => {
          if (item && item.value) {
            attrItem.parameterValueStr.push({attributeValue: item.value});
          }
        });
        requestObj.goodsSpecificationAttributeDO.push(attrItem);
      });
      requestObj.goodsSpecificationParameterDO = [];
      paramList.forEach((v) => {
        requestObj.goodsSpecificationParameterDO.push({specificationParameterName: v.name, specificationParameterAlias: v.rename});
      });
      axios.Specification.add({goodsSpecificationDO: JSON.stringify(requestObj.goodsSpecificationDO), goodsSpecificationAttributeDO: JSON.stringify(requestObj.goodsSpecificationAttributeDO), goodsSpecificationParameterDO: JSON.stringify(requestObj.goodsSpecificationParameterDO)}).then(res => {
        const data = res.data;
        if (data.code !== 1000) {
          return false;
        }
        Modal.success({
          title: '提示',
          content: '添加成功',
          onOk() {
            const tabObj = commonCloseTab(_this.props.tabList, MENUKEY);
            _this.props.pushTabs(tabObj);
          }
        });
      });
    }
  }
  // 删除某一条
  delParam = (index) => {
    const {specification} = this.props;
    const {paramList} = specification;
    const paramItem = paramList[index];
    const _this = this;
    if (paramList.length === 1) {
      message.error('请至少添加一条规格参数');
      return false;
    }
    if (paramItem.id) {
      confirm({
        title: '确定删除该规格参数？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          axios.Specification.validateParamIsUsed({id: paramItem.id}).then(res => {
            const data = res.data;
            if (data.code !== 1000) {

              return false;
            }
            paramList.splice(index, 1);
            _this.props.updateForm(Object.assign({},_this.props.specification));
          })
        }
      });
    } else {
      paramList.splice(index, 1);
      this.props.updateForm(Object.assign({},this.props.specification));
    }
  }
  // 返回上一步
  back = () => {
    this.props.updateForm(Object.assign({}, this.props.specification, {currentStep: 1}));
  }
  handleInputChange = (attrName, index, e) => {
    const {paramList} = this.props.specification;
    paramList[index][attrName] = e.target.value;
    this.props.updateForm(Object.assign({}, this.props.specification, {paramList}));
  }
  // 验证规格属性值
  validateName = (e) => {
    this.validateNameIsRepeat(e.target.value);
  }
  // 验证规格属性值
  validateNameIsRepeat = (value) => {
    const {paramList} = this.props.specification;
    const repeatAttrArr = paramList.filter((v) => v.name === value);
    if (repeatAttrArr.length > 1) {
      message.error('规格参数名不能重复');
      return false;
    }
    return true;
  }
  // 添加一个参数
  addSpecificationParam = () => {
    let uForm = this.props.specification;
    uForm.paramList.push({
      name: '',
      rename: ''
    });
    
    this.props.updateForm(Object.assign({}, this.props.specification));
  }
  render () {
    const {visible} = this.props;
    const formKeyItems = this.props.specification.paramList.map((k, index) => (
      <Row gutter={24} key={index} className="m-specification-row">
        <Col span={10} className="param-input">
          <Input {...tableInputLayout} value={k.name} onChange={this.handleInputChange.bind(this, 'name', index)} onBlur={this.validateName}/>
        </Col>
        <Col span={10} className="param-input">
          <Input {...tableInputLayout} value={k.rename} onChange={this.handleInputChange.bind(this, 'rename', index)}/>
        </Col>
        <Col span={4}><div className="m-table-handle-btn" onClick={this.delParam.bind(this, index)}>删除</div></Col>
      </Row>
    ));
    return (
      <Form style={visible ? {display: 'block'} : {display: 'none'}}>
        <div className="u-border-container add-specification-step-content">
          <Button type="primary" onClick={this.addSpecificationParam}>添加一个参数</Button>
          <Row gutter={24} className="m-input-th">
            <Col span={10} style={{textAlign:'center'}}>
              <span>参数名</span>
            </Col>
            <Col span={10} style={{textAlign:'center'}}>
              <span>别名</span>
            </Col>
            <Col span={4} style={{textAlign:'center'}}>
              <span>操作</span>
            </Col>
          </Row>
          <div className="m-table-input">
            {formKeyItems}
          </div>
          <div className="m-btn-group align-center">
            <Button onClick={this.back}>上一步</Button>
            <Button type="primary" onClick={this.submit}>确定</Button>
          </div>
        </div>
      </Form>
    )
  }
}

const mapStateProps = (state) => ({
  specification: state.specification,
  tabList: state.main.tabList,
  activeKey: state.main.activeKey
});

const mapDispatchProps = (dispatch) => ({
  updateForm: (value) => {
    SPECIFICATION.specification = value;
    dispatch(SPECIFICATION);
  },
  pushTabs: (value) => {
    TABS.main = {
      tabList: value.tabList,
      activeKey: value.activeKey
    }
    dispatch(TABS);
  }
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(Form.create('add_third_form')(AddThirdForm));