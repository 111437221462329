let defaults = {
    editFlag: false,
    params: ''
  }
  const addOrEditGoods = (state = defaults, action) => {
    switch (action.type) {
      case 'ADDOREDITGOODS':
        return Object.assign({}, state, action.addOrEditGoods);
      default: 
        return state;
    }
  }
  export default addOrEditGoods;