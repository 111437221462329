import React from 'react';
import {Modal, Row, Col, Tag, Table} from 'antd';
const attrColumn = [
  {
    title: '属性名',
    dataIndex: 'attributeName',
    key: 'attributeName',
  }, {
    title: '属性别名',
    dataIndex: 'attributeAlias',
    key: 'attributeAlias'
  }, {
    title: '选择项可选值',
    dataIndex: 'valueList',
    key: 'valueList',
    render: (valueList) => (
      <span>
        {valueList ? valueList.map(v => <Tag key={v.id} color='green'>{v.attributeValue}</Tag>) : '暂无选择项可选值'}
      </span>
    )
  }
];
const paramColumn = [
  {
    title: '参数名',
    dataIndex: 'specificationParameterName',
    key: 'specificationParameterName',
  }, {
    title: '参数别名',
    dataIndex: 'specificationParameterAlias',
    key: 'specificationParameterAlias'
  }
]
class ShowModal extends React.Component{
  render () {
    const {visible, onClose, data} = this.props;
    return (
      <Modal title="查看规格类型" width={700} visible={visible} onOk={onClose} onCancel={onClose}>
        {data && (
          <div className="m-specification-show">
            <Row>
              <Col span={4}>
                <span>规格类型名称：</span>
              </Col>
              <Col span={18}>
                <span>{data.specificationName}</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span>规格类型描述：</span>
              </Col>
              <Col span={18}>
                <span>{data.remark}</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span>所属分类：</span>
              </Col>
              <Col span={18}>
                <span>{data.goodsCategoryDO.firstCategoryName}-{data.goodsCategoryDO.secondCategoryName}-{data.goodsCategoryDO.thirdCategoryName}</span>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span>所属品牌：</span>
              </Col>
              <Col span={18}>
                {data.relationBrandDO ? data.relationBrandDO.map((v) => <Tag key={v.id} color="blue">{v.brandName}</Tag>) : '暂无关联品牌'}
              </Col>
            </Row>
            <Table rowKey="id" size="small" pagination={false} columns={attrColumn} dataSource={data.relationAttributeDO}></Table>
            <Table rowKey="id" size="small" pagination={false} columns={paramColumn} dataSource={data.parameterDO}></Table>
          </div>
        )}
      </Modal>
    )
  }
}
export default ShowModal;