import React from 'react';
import { Modal, Input, Form } from 'antd';
class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
    render() {
      const { visibled, handleReset,handleResetCancel } = this.props;
      const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <Modal
    title={"重置密码"}
    visible={visibled}
    onOk={handleReset}
    onCancel={handleResetCancel}
    width="50%"
  >
    <Form>
      <Form.Item
        {...formItemLayout}
        label="密码"
        extra="选填修改密码，不填则默认初始密码：123456"
      >
        {getFieldDecorator('password', {
          initialValue: '',
          rules: [{
            required: false, message: '选填修改密码',
          }],
        })(
          <Input />
        )}
      </Form.Item>
      </Form>
      </Modal>
    )
    }
  
}
const WrappedNormalResetPasswordForm = Form.create()(ResetPasswordForm);
export default WrappedNormalResetPasswordForm;