import React from 'react';
import {TABS} from '../../../store/action';
import { ADDOREDITGOODS } from '../../../store/action'
import {connect} from 'react-redux';
import routes from '../../../router/config';
import {commonPushTabs} from '../../../util/tabs';
import {Form, Input, Button, Table, message, Modal, Select } from 'antd';
import axios from '../../../api';
import utils from '../../../util';
const Option = Select.Option;
const confirm = Modal.confirm;
// 表格列字段
const columns = [
  {
    title: '商品图片',
    align: 'center',
    dataIndex: 'goodsLogo',
    key: 'goodsLogo',
    render: (text, record) => {return(<img style={{width:80,height:80}} alt="" src={record.imageList && record.imageList[0].imageBigUrl}/>)},
  },{
    title: '商品名称',
    dataIndex: 'goodsName',
    key: 'goodsName',
    render: (text) => <div>{text}</div>,
  }, {
    title: '商品编码',
    dataIndex: 'goodsCoding',
    key: 'goodsCoding',
    render: (text) => <p>{text}</p>
  }, {
    title: '分类',
    dataIndex: 'categoryName',
    key: 'categoryName',
    render: (text, record) => {if(record.categoryName){return(<p>{record.categoryName}</p>)}else{return(<p>暂无分类</p>)}}
  }, {
    title: '品牌',
    dataIndex: 'brandName',
    key: 'brandName',
    render: (text, record) => {if(record.brandName){return(<p>{record.brandName}</p>)}else{return(<p>暂无品牌</p>)}}
  }
];
const sortMap = new Map([
  ['first', 1],
  ['second', 2],
  ['third', 3],
]);
// 表格分页配置
class GoodsList1 extends React.Component{
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      selectedRowKeys: null,
       data: [],
       editVisible: false,
       searchFormCopy: {
        goodsName: '',
        brandId: '',
        oneCategoryId:'',
        twoCategoryId:'',
        categoryId: '',
       },
       selectRow: [],
       searchForm: {
        goodsName: '',
        firstSortList: [],
        secondSortList: [],
        thirdSortList: [],
        oneCategoryId: '',
        twoCategoryId: '',
        categoryId: '',
        brandList: [],
        brandId: '',
        barCode: ''
      },
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '500'],
        total: 0,
        onChange (page, size) {
          _this.handlePage(page, size);
        },
        onShowSizeChange (current, size) {
          _this.handlePage(current, size);
        }
      },
    }
  }
  componentDidMount() {
    this.getSortList('first');
    this.getGoodsList();
  }
  // 分页操作
  handlePage (page, size) {
    const newPagination = Object.assign({}, this.state.pagination, {current: page, pageSize: size});
    this.setState({
      pagination: newPagination
    }, () => {
      this.getGoodsList();
    });
  }
  
  // 获取分类列表
  getSortList = (levelLabel, parentLevelLabel = undefined) => {
    const level = sortMap.get(levelLabel);
    const parentId = parentLevelLabel ? this.state.searchForm[`${parentLevelLabel}Id`] : 0;
    axios.GoodsCategory.getCategoryList({level, parentId: parentId, name: ''}).then(res => {
      const data = res.data;
      if (data.code !== 1000 || !data.result) {
        const newSearchForm = Object.assign({}, this.state.searchForm, {[`${levelLabel}SortList`]: []});
        this.setState({
          searchForm: newSearchForm
        });
        return false;
      }
      const newSearchForm = Object.assign({}, this.state.searchForm, {[`${levelLabel}SortList`]: data.result});
      this.setState({
        searchForm: newSearchForm
      });
    });
  }
  // 分类搜索框
  changeSort = (type, childType, value) => {
    let chooseSortObj = {};
    if (type === 'first') {
      chooseSortObj = {oneCategoryId: undefined, twoCategoryId: undefined, categoryId: undefined, thirdSortList: [], brandList: []};
      this.props.form.resetFields(['oneCategoryId', 'twoCategoryId', 'categoryId', 'brandId']);
    } else if (type === 'second') {
      chooseSortObj = {twoCategoryId: undefined, categoryId: undefined, brandList: []};
      this.props.form.resetFields(['twoCategoryId', 'categoryId', 'brandId']);
    } else if (type === 'third') {
      this.props.form.resetFields(['categoryId', 'brandId']);
      chooseSortObj = {categoryId: undefined};
    }
    
    const newSearchForm = Object.assign({}, this.state.searchForm, chooseSortObj, {[`${type}Id`]: value});
    this.setState({
      searchForm: newSearchForm,
    }, () => {
      if (value) {
        this.getSortList(childType, type);
      }
    })
  }
  // 修改三级分类
  changeThirdSort = (value, options) => {
    const newSearchForm = Object.assign({}, this.state.searchForm, {categoryId: value});
    this.setState({
      searchForm: newSearchForm,
    }, () => {
      if (value) {
        this.props.form.resetFields(['brandId']);
        this.getBrandList(value);
      }
    })
  }
  getBrandList = (categoryThirdId) => {
    axios.GoodsBrand.getBrandsList({categoryFirstId: '', categorySecondId: '', categoryThirdId, brandName: '', page: 0, size: 0}).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        const newSearchForm = Object.assign({}, this.state.searchForm, {brandList: []});
        this.setState({
          searchForm: newSearchForm
        });
        return false;
      }
      const newSearchForm = Object.assign({}, this.state.searchForm, {brandList: data.result.list});
      this.setState({
        searchForm: newSearchForm
      });
    });
  }

  // 勾选表格多选框事件
  onSelectChange = (selectedRowKeys, row) => {
    this.setState({ 
      selectedRowKeys,
      selectRow: row
     });
  }

  // 删除记录按钮点击事件
  delete = (e) => {
    let that = this;
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要删除的规格');
      return false;
    }
    confirm({
      title: '提示',
      content: '确认要删除这写记录吗？',
      onOk() {
        axios.PublicGoods.del(that.state.selectedRowKeys.toString()).then(res => {
          const data = res.data;
          if (data.code !== 1000) {
            return false;
          }
          that.setState({
            selectedRowKeys: null
          });
          that.getGoodsList();
        });
      }
    })
  }
  // 打开添加页面
  openAdd = () => {
    let pathObj = routes.tabMenus.find((v) => v.authorityUrl === 'AddOrEditGoods');
    pathObj.params = {id: null, reload: true};
    this.props.pushTabs(commonPushTabs(this.props.tabList, pathObj));
    this.props.isEdit({editFlag: false, params: ''});
  }

  // 打开编辑页面
  openEdit = () => {
    if (this.state.selectedRowKeys ===  null || (this.state.selectedRowKeys.length > 1 || this.state.selectedRowKeys.length === 0)) {
      message.warning('请先选择一个商品进行编辑!');
      return false;
    }
    let pathObj = routes.tabMenus.find((v) => v.authorityUrl === 'AddOrEditGoods');
    if(this.state.selectedRowKeys[0]){
      pathObj.params = {id: this.state.selectedRowKeys[0], reload: true};
      this.props.pushTabs(commonPushTabs(this.props.tabList, pathObj));
      this.props.isEdit({editFlag: true, params: this.state.selectedRowKeys[0]});
    }
  }
  

  // 关闭编辑商品标签
  handleCancel = () => {
    this.setState({
      editVisible: false
    })
  }
  // 搜索
  handleSearch = (e) => {
    e.preventDefault();
    const values = Object.assign({}, this.state.searchForm, this.props.form.getFieldsValue());
    Object.keys(values).map(item => {
      if(values[item] === undefined){
        return values[item] = '';
      }
      return values;
    });
    const newPagination = Object.assign({}, this.state.pagination, {current: 1, pageSize: 10});
    this.setState({
      searchForm: values,
      pagination: newPagination
    },() => {
      this.getGoodsList();
    });
  }
  getGoodsList = () => {
    let { searchForm, pagination } = this.state;
    let obj = Object.assign({}, searchForm, { page: pagination.current, size: pagination.pageSize });
    axios.PublicGoods.getGoodsList({barCode: obj.barCode,goodsName: obj.goodsName, brandId: obj.brandId, oneCategoryId: obj.oneCategoryId, twoCategoryId: obj.twoCategoryId, categoryId: obj.categoryId, page: obj.page, size: obj.size}).then(res => {
      this.setState({
        loading: false,
        selectedRowKeys: []
      });
      const data = res.data;
      if (data.code !== 1000) {
        if (data.code !== 2001) {
        }
        this.setState({
          data: [],
          pagination: Object.assign(pagination, {total: 0})
        });
        return false;
      }
      const newPagination = Object.assign(pagination, {total: data.result.total})
      this.setState({
        data: data.result.list,
        pagination: newPagination
      });
    });
  }
  // 导出表格
  exportTable = () => {
    utils.exportXlsx(this.state.data, columns)
  }
  // 导出选中表格
  selectExport = () => {
    utils.exportXlsx(this.state.selectRow, columns)
  }
  render () {
    const { selectedRowKeys, searchForm, pagination } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const {
      getFieldDecorator
    } = this.props.form;
    // const SearchSelectLayout = {
    //   showSearch: true,
    //   style: {
    //     width: 150
    //   }
    // }
    return (
      <div>
        <Form layout="inline" onSubmit={this.handleSearch}>
          <Form.Item label="一级分类">
            {getFieldDecorator('oneCategoryId')(
              <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择一级分类" onChange={this.changeSort.bind(null, 'first', 'second')}>
                {searchForm.firstSortList.map((v) => (
                  <Option value={v.id} key={v.id}>{v.categoryName}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="二级分类">
            {getFieldDecorator('twoCategoryId')(
              <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择二级分类" onChange={this.changeSort.bind(null, 'second', 'third')}>
                {searchForm.secondSortList.map((v) => (
                  <Option value={v.id} key={v.id}>{v.categoryName}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="三级分类">
            {getFieldDecorator('categoryId')(
              <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择三级分类" onChange={this.changeThirdSort}>
                {searchForm.thirdSortList.map((v) => (
                  <Option value={v.id} key={v.id}>{v.categoryName}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="商品品牌">
          {getFieldDecorator('brandId')(
            <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择关联品牌">
              {searchForm.brandList.map((v) => (
                <Option value={v.id} key={v.id}>{v.brandName}</Option>
              ))}
            </Select>
          )}
          </Form.Item>
          <Form.Item label="商品名称">
          {getFieldDecorator('goodsName')(
            <Input placeholder="请输入商品名称"/>
          )}
          </Form.Item>
          <Form.Item label="条形码">
          {getFieldDecorator('barCode')(
            <Input placeholder="请输入条形码"/>
          )}
          </Form.Item>
          {/* <Form.Item label="供应商">
          {getFieldDecorator('supplierId')(
            <Select {...SearchSelectLayout} allowClear placeholder="请选择供应商">
              <Option value="1" key="1">自营</Option>
            </Select>
          )}
          </Form.Item> */}
          <Form.Item>
            <Button htmlType="submit" type="primary" ghost>搜索</Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={this.exportTable} htmlType="button" type="primary" ghost>导出当前页</Button>
          </Form.Item>
          <Form.Item>
            <Button htmlType="button" type="primary" ghost onClick={this.selectExport}>导出选中</Button>
          </Form.Item>
        </Form>
        <div className="m-btn-group">
          <Button type="primary" onClick={this.openAdd}>添加</Button>
          <Button type="primary" onClick={this.openEdit}>编辑</Button>
          {/* <Button type="danger" onClick={this.delete}>删除</Button> */}
        </div>
        <Table rowKey='id' size="middle" pagination={pagination} rowSelection={rowSelection} columns={columns} dataSource={this.state.data}/>
      </div>
    )
  }
}

const mapStateProps = (state) => ({
  tabList: state.main.tabList,
  activeKey: state.main.activeKey,
  editFlag: state.addOrEditGoods.editFlag
});

const mapDispatchProps = (dispatch) => ({
  pushTabs: (value) => {
    TABS.main = {
      tabList: value.tabList,
      activeKey: value.activeKey
    }
    dispatch(TABS);
  },
  isEdit: (value) => {
    ADDOREDITGOODS.addOrEditGoods = value;
      dispatch(ADDOREDITGOODS);
  }
});
const GoodsList = Form.create()(GoodsList1);
export default connect(
  mapStateProps,
  mapDispatchProps
)(GoodsList);