export const TABS = {
  type: 'TABS',
  main: {}
}

export const SPECIFICATION = {
  type: 'SPECIFICATION',
  specification: {}
}

export const ADDOREDITGOODS = {
  type: 'ADDOREDITGOODS',
  addOrEditGoods: {}
}