import React from 'react';
import {TABS} from '../../../store/action';
import {SPECIFICATION} from '../../../store/action';
import {connect} from 'react-redux';
import routes from '../../../router/config';
import {commonPushTabs} from '../../../util/tabs';
import {Button, Table, message, Modal, Tag} from 'antd';
import SearchForm from './components/searchForm';
import axios from '../../../api';
import ShowModal from './components/showModal';
const confirm = Modal.confirm;
const ADDMENUKEY = 'AddSpecification';
// 表格列字段
const columns = [
  {
    title: '规格类型',
    dataIndex: 'specificationName',
    key: 'specificationName',
  }, {
    title: '关联分类',
    dataIndex: 'goodsCategoryDO.categoryName',
    key: 'goodsCategoryDO.categoryName',
  }, {
    title: '关联品牌',
    dataIndex: 'brandList',
    key: 'brandList',
    render: brandList => (
      <span>
        {brandList ? brandList.map(brand => <Tag color="blue" key={brand.id}>{brand.brandName}</Tag>) : '暂无关联品牌'}
      </span>
    ),
  }, {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
  }, 
];
const sortMap = new Map([
  ['first', 1],
  ['second', 2],
  ['third', 3],
]);
class Specification extends React.Component{
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      selectedRowKeys: null,
      selectSpecificationInfo: null,
      infoVisible: false,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        onChange (page, size) {
          _this.handlePage(page, size);
        },
        onShowSizeChange (current, size) {
          _this.handlePage(current, size);
        }
      },
      searchForm: {
        firstSortList: [],
        secondSortList: [],
        thirdSortList: [],
        brandList: [],
        firstSortId: undefined,
        secondSortId: undefined,
        thirdSortId: undefined,
        brandId: undefined,
        goodsSpecificationName: ''
      },
      data: [],
      loading: true,
    }
  }

  componentDidMount() {
    this.getSortList('first');
    this.getSpecificationList();
  }
  // 关闭展示信息的弹框
  handleInfoClose = () => {
    this.setState({
      infoVisible: false,
      selectSpecificationInfo: null
    });
  }
  // 分页操作
  handlePage (page, size) {
    const newPagination = Object.assign({}, this.state.pagination, {current: page, pageSize: size});
    this.setState({
      pagination: newPagination
    }, () => {
      this.getSpecificationList();
    });
  }

  // 获取规格类型列表
  getSpecificationList = () => {
    const {searchForm, pagination} = this.state;
    this.setState({
      loading: true
    });
    const firstCode = searchForm.firstSortId ? searchForm.firstSortList.find((v) => v.id === searchForm.firstSortId).categoryCode : '';
    const secondCode = searchForm.secondSortId ? searchForm.secondSortList.find((v) => v.id === searchForm.secondSortId).categoryCode : '';
    const thirdCode = searchForm.thirdSortId ? searchForm.thirdSortList.find((v) => v.id === searchForm.thirdSortId).categoryCode : '';
    axios.Specification.getList({firstCode, secondCode, thirdCode, brandId: searchForm.brandId || '', goodsSpecificationName: searchForm.goodsSpecificationName, page: pagination.current, size: pagination.pageSize}).then(res => {
      const data = res.data;
      this.setState({
        loading: false
      });
      if (data.code !== 1000) {
        const newPagination = Object.assign(pagination, {total: 0});
        this.setState({
          data: [],
          pagination: newPagination
        });
        return false;
      }
      const newPagination = Object.assign(pagination, {total: data.result.total});
      this.setState({
        data: data.result.list,
        pagination: newPagination
      });
    })
  }

  // 获取分类列表
  getSortList = (levelLabel, parentLevelLabel = undefined) => {
    const level = sortMap.get(levelLabel);
    const parentId = parentLevelLabel ? this.state.searchForm[`${parentLevelLabel}SortId`] : 0;
    axios.GoodsCategory.getCategoryList({level, parentId: parentId, name: ''}).then(res => {
      const data = res.data;
      if (data.code !== 1000 || !data.result) {
        const newSearchForm = Object.assign({}, this.state.searchForm, {[`${levelLabel}SortList`]: []});
        this.setState({
          searchForm: newSearchForm
        });
        return false;
      }
      const newSearchForm = Object.assign({}, this.state.searchForm, {[`${levelLabel}SortList`]: data.result});
      this.setState({
        searchForm: newSearchForm
      });
    });
  }

  // 分类搜索框
  changeSort = (type, childType, value) => {
    let chooseSortObj = {};
    if (type === 'first') {
      chooseSortObj = {firstSortId: undefined, secondSortId: undefined, thirdSortId: undefined};
      this.searchForm.props.form.resetFields(['firstSortId', 'secondSortId', 'thirdSortId']);
    } else if (type === 'second') {
      chooseSortObj = {secondSortId: undefined, thirdSortId: undefined};
      this.searchForm.props.form.resetFields(['secondSortId', 'thirdSortId']);
    } else if (type === 'third') {
      this.searchForm.props.form.resetFields(['thirdSortId']);
      chooseSortObj = {thirdSortId: undefined};
    }
    
    const newSearchForm = Object.assign({}, this.state.searchForm, chooseSortObj, {[`${type}SortId`]: value});
    this.setState({
      searchForm: newSearchForm,
    }, () => {
      if (value) {
        this.getSortList(childType, type);
      }
    })
  }

  // 修改三级分类
  changeThirdSort = (value, options) => {
    const newSearchForm = Object.assign({}, this.state.searchForm, {thirdSortId: value});
    this.setState({
      searchForm: newSearchForm,
    }, () => {
      if (value) {
        this.getBrandList(value);
      }
    })
  }

  // 获取品牌列表
  getBrandList = (categoryThirdId) => {
    axios.GoodsBrand.getBrandsList({categoryFirstId: '', categorySecondId: '', categoryThirdId, brandName: '', page: 0, size: 0}).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        const newSearchForm = Object.assign({}, this.state.searchForm, {brandList: []});
        this.setState({
          searchForm: newSearchForm
        });
        return false;
      }
      const newSearchForm = Object.assign({}, this.state.searchForm, {brandList: data.result.list});
      this.setState({
        searchForm: newSearchForm
      });
    });
  }

  // 搜索按钮点击事件
  search = (e) => {
    e.preventDefault();
    const value = Object.assign({}, this.state.searchForm, this.searchForm.props.form.getFieldsValue());
    const newPagination = Object.assign({}, this.state.pagination, {current: 1, pageSize: 10});
    console.log(value);
    this.setState({
      searchForm: value,
      pagination: newPagination
    }, () => {
      this.getSpecificationList();
    })
  }

  // 勾选表格多选框事件
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  // 删除记录按钮点击事件
  delete = (e) => {
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要删除的数据');
      return false;
    }
    const _this = this;
    confirm({
      title: '提示',
      content: '确认要删除这些记录吗？',
      onOk() {
        axios.Specification.del(_this.state.selectedRowKeys.toString()).then(res => {
          const data = res.data;
          if (data.code !== 1000) {
            return false;
          }
          _this.setState({
            selectedRowKeys: null
          });
          _this.getSpecificationList();
        });
      }
    })
  }

  // 打开添加页面
  openAdd = () => {
    let pathObj = routes.tabMenus.find((v) => v.authorityUrl === ADDMENUKEY);
    pathObj.params = {id: null, reload: true};
    const resetUpdateForm = {
      id: null,
      currentStep: 0,
      name: '',
      describe: '',
      sort: [],
      brand: [],
      attrList: [
        {
          name: '',
          rename: '',
          valueList: [
            {value: '', isEdit: true}
          ],
          sort: ''
        }
      ],
      paramList: [{
        name: '',
        rename: ''
      }]
    }
    this.props.updateForm(Object.assign({}, resetUpdateForm));
    this.props.pushTabs(commonPushTabs(this.props.tabList, pathObj));
  }
  
  // 打开查看详情页面
  openShow = () => {
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要查看的数据');
      return false;
    }
    if(this.state.selectedRowKeys.length > 1) {
      message.warning('请选择一个您要查看的数据');
      return false;
    }
    const chooseKey = this.state.selectedRowKeys[0];
    axios.Specification.getInfo({id: chooseKey}).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        return false;
      }
      this.setState({
        infoVisible: true,
        selectSpecificationInfo: data.result
      });
    });
  }
  

  // 打开编辑页面
  openEdit = () => {
    if (!this.state.selectedRowKeys || this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要编辑的数据');
      return false;
    }
    if(this.state.selectedRowKeys.length > 1) {
      message.warning('请选择一个您要编辑的数据');
      return false;
    }
    const chooseKey = this.state.selectedRowKeys[0];
    const item = this.state.data.find((v) => v.id === chooseKey);
    const pathObj = routes.tabMenus.find((v) => v.authorityUrl === ADDMENUKEY);
    pathObj.params = {id: item.id, reload: true};
    this.getInfoById(item.id, (data) => {
      this.props.pushTabs(commonPushTabs(this.props.tabList, pathObj));
    });
  }

  // 根据id获取规格类型信息
  getInfoById = (id, callback) => {
    axios.Specification.getInfo({id}).then(res => {
      const data = res.data;
      if (data.code !== 1000) {
        return false;
      }
      const {result} = data;
      const {id, specificationName, remark, goodsCategoryDO, relationBrandDO, relationAttributeDO, parameterDO} = result;
      const attrList = [];
      const paramList = [];
      if (relationAttributeDO) {
        relationAttributeDO.forEach((v) => {
          const valueList = [];
          if (v.valueList) {
            v.valueList.forEach((item) => {
              valueList.push({id: item.id, value: item.attributeValue, isEdit: false});
            });
          }
          valueList.push({value: '', isEdit: true});
          attrList.push({id: v.id, name: v.attributeName, rename: v.attributeAlias, sort: v.sortId, valueList});
        })
      }
      if (parameterDO) {
        parameterDO.forEach((v) => {
          paramList.push({id: v.id, name: v.specificationParameterName, rename: v.specificationParameterAlias});
        });
      }
      const updateForm = {
        currentStep: 0,
        id,
        name: specificationName,
        describe: remark,
        sort: goodsCategoryDO ? [goodsCategoryDO.firstCategoryId, goodsCategoryDO.secondCategoryId, goodsCategoryDO.thirdCategoryId] : [],
        brand: relationBrandDO ? relationBrandDO.map((v) => v.id.toString()) : [],
        attrList: relationAttributeDO ? attrList : [],
        paramList: paramList
      }
      this.props.updateForm(Object.assign({}, updateForm));
      if (callback) {
        callback(updateForm);
      }
    });
  }
  
  render () {
    const { selectedRowKeys, searchForm, pagination, loading, selectSpecificationInfo, infoVisible } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <SearchForm data={searchForm} changeThirdSort={this.changeThirdSort} changeSort={this.changeSort} search={this.search} wrappedComponentRef={(form) => this.searchForm = form}/>
        <div className="m-btn-group">
          <Button type="primary" onClick={this.openAdd}>添加</Button>
          <Button type="primary" onClick={this.openEdit}>编辑</Button>
          <Button type="primary" onClick={this.openShow}>查看详情</Button>
          <Button type="danger" onClick={this.delete}>删除</Button>
        </div>
        <Table rowKey='id' loading={loading} size="middle" pagination={pagination} rowSelection={rowSelection} columns={columns} dataSource={this.state.data}/>
        <ShowModal data={selectSpecificationInfo} visible={infoVisible} onClose={this.handleInfoClose}/>
      </div>
    )
  }
}

const mapStateProps = (state) => ({
  tabList: state.main.tabList,
  activeKey: state.main.activeKey,
  specification: state.specification,
});

const mapDispatchProps = (dispatch) => ({
  updateForm: (value) => {
    SPECIFICATION.specification = value;
    dispatch(SPECIFICATION);
  },
  pushTabs: (value) => {
    TABS.main = {
      tabList: value.tabList,
      activeKey: value.activeKey
    }
    dispatch(TABS);
  }
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(Specification);
