import axios from './axios';
export default {
  getRoleInfoList(data) {
    return axios.get(`/role/getRoleInfoList`);
  },
  saveRoleInfo(data) {
    return axios.post('/role/saveRoleInfo',data);
  },
  updateRoleInfo(data) {
    return axios.post('/role/updateRoleInfo',data);
  },
  deleteRoleInfo(ids) {
    return axios.delete(`/role/deleteRoleInfo/${ids}`);
  }
}