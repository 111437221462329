let defaults = {
  currentStep: 0,
  name: null,
  describe: null,
  sort: [],
  brand: [],
  attrList: [
    {
      name: '',
      rename: '',
      valueList: [
        {value: '', isEdit: true}
      ],
      sort: ''
    }
  ],
  paramList: [{
    name: '',
    rename: ''
  }]
}
const specification = (state = defaults, action) => {
  switch (action.type) {
    case 'SPECIFICATION':
      return Object.assign({}, state, action.specification);
    default: 
      return state;
  }
}
export default specification;