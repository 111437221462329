import axios from './axios';
export default {
  /**
   * 获取分类列表
   * @param {Object} data 参数对象
   * level: '', // 分类等级
   * parentId: 0, // 分类上级id
   * name: '' // 商品分类名称
   */
  getCategoryList (data) {
    return axios.get(`/goodsCategory/listByLevelAndParentIdAndName?level=${data.level}&parentId=${data.parentId}&name=${data.name}`);
  },
  /** 
   * 保存分类
   * name	String	商品分类名称,必填
   * sortId	Integer	商品分类排序,必填
   * parentId	Integer	商品分类上级ID,必填,如果新增的是一级分类,参数传0
   */
  saveCategory (data) {
    return axios.post(`/goodsCategory/save`,data);
  },
  /**
   * 更新分类
   * @param {*} data 
   */
  updateCategory (data) {
    return axios.put(`/goodsCategory/update`,data);
  },
}