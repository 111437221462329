import axios from 'axios';
import { message } from 'antd';
import Cookie from 'js-cookie';
const url = process.env.NODE_ENV === 'development' ? 'http://localhost:7001/api/': '/api/';
// const api = axios.create({
//   baseURL: url,
//   timeout: 3000,
// })
axios.defaults.baseURL = url;
axios.defaults.withCredentials = true;
// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers.csrfToken = Cookie.get('csrfToken');
    return config;
  },
  err => {
      return Promise.reject(err);
  }
);
axios.interceptors.response.use( (response) => {
  const data = response.data;
  if (data.code !== 1000) {
    if (data.code !== 2001) {
      message.error(data.chDesc)
    }
  }
  return response;
})

export {url};
export default axios;