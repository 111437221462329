import React from 'react';
import { Form, Button, Row, Col, Input, message, Modal } from 'antd';
import {SPECIFICATION} from '../../../../store/action';
import {connect} from 'react-redux';
import EditTag from '../components/editTag';
import axios from '../../../../api';
const confirm = Modal.confirm;
const tableInputLayout = {
  size: 'small',
}
let index = 1;
class AddSecondForm extends React.Component{
  submit = () => {
    const {attrList, id} = this.props.specification;
    let repeatCount = 0;
    let valueIsNullCount = 0;
    for (let i = 0; i < attrList.length; i++) {
      let v = attrList[i];
      if (!v.name) {
        message.error('规格属性名称不能为空');
        return false;
      }
      if (!this.validateNameIsRepeat(v.name)) {
        repeatCount ++;
      }
      const nullValueList = v.valueList.filter(item => !item.value);
      if (nullValueList.length <= 1 && v.valueList.length === 1) {
        valueIsNullCount ++;
      }
    }
    if (repeatCount !== 0) {
      message.error('规格属性名称不可以重复');
      return false;
    }
    if (valueIsNullCount !== 0) {
      message.error('规格属性选择项可选值不可以为空');
      return false;
    }

    // 有id进行更新操作
    if (id) {
      const attrParamList = [];
      attrList.forEach((v) => {
        const attrItem = {
          attributeAlias: v.rename,
          attributeName: v.name,
        };
        const valueList = [];
        v.valueList.forEach((item) => {
          if (item.value) {
            const valueItem = {attributeValue: item.value};
            if (item.id) {
              valueItem.id = item.id;
            }
            valueList.push(valueItem);
          }
        });
        attrItem.parameterValueStr = valueList;
        if (v.id) {
          attrItem.id = v.id;
        }
        attrParamList.push(attrItem);
      });
      axios.Specification.updateAttribute({specificationId: id, goodsSpecificationAttribute: JSON.stringify(attrParamList)}).then(res => {
        const data = res.data;
        if (data.code !== 1000) {
          return false;
        }
        this.props.updateForm(Object.assign({}, this.props.specification, {currentStep: 2}));
      });
    } else {
      this.props.updateForm(Object.assign({}, this.props.specification, {currentStep: 2}));
    }
  }
  // 返回上一步
  back = () => {
    this.props.updateForm(Object.assign({}, this.props.specification, {currentStep: 0}));
  }
  // 验证规格属性值
  validateName = (e) => {
    this.validateNameIsRepeat(e.target.value);
  }
  // 验证规格属性值
  validateNameIsRepeat = (value) => {
    const {attrList} = this.props.specification;
    const repeatAttrArr = attrList.filter((v) => v.name === value);
    if (repeatAttrArr.length > 1) {
      message.error('规格属性名不能重复');
      return false;
    }
    return true;
  }
  // 更新input
  handleInputChange = (attrName, index, e) => {
    const {attrList} = this.props.specification;
    attrList[index][attrName] = e.target.value;
    this.props.updateForm(Object.assign({}, this.props.specification, {attrList}));
  }

  // 双击规格类型
  doubleClickTag = (index, parentIndex, e) => {
    let attrList = this.props.specification.attrList;
    let attrValueList = attrList[parentIndex].valueList;
    attrValueList[index] = Object.assign({}, attrValueList[index], {isEdit: true});
    this.props.updateForm(Object.assign({},this.props.specification));
    setTimeout(() => {
      document.getElementById(`tag${parentIndex}_${index}`).focus();
    }, 0);
  }
  // 失焦添加一个选择项可选值
  tagBlur = (index, parentIndex, e) => {
    let attrList = this.props.specification.attrList;
    let attrValueList = attrList[parentIndex].valueList;
    const valueLength = attrValueList.length;
    // 判断规格值是否可用
    if (e.target.value.trim() === '' && (index !== valueLength - 1 || valueLength === 1)) {
      message.error('规格可选值不能为空');
      document.getElementById(`tag${parentIndex}_${index}`).focus();
      return false;
    }
    // 最后一个可选值输入判断
    if (index === valueLength - 1 && e.target.value.trim() === '' ) {
      return false;
    }
    // 校验可选值是否重复
    const repeatValueList = attrValueList.filter((v) => v.value === e.target.value);
    if (repeatValueList.length > 1) {
      message.error('属性的选择项可选值不可重复');
      document.getElementById(`tag${parentIndex}_${index}`).focus();
      return false;
    }
    attrValueList[index] ={value: e.target.value, isEdit: false};
    if (attrValueList[valueLength - 1].value) {
      attrValueList.push({value: '', isEdit: true});
    }
    this.props.updateForm(Object.assign({},this.props.specification));
    if (index === valueLength - 1) {
      setTimeout(() => {
        document.getElementById(`tag${parentIndex}_${valueLength}`).focus();
      }, 0);
    }
  }
  // 回车添加一个选择项可选值
  enterClickTag = (index, parentIndex, e) => {
    document.getElementById(`tag${parentIndex}_${index}`).blur();
  }

  handleChangeTag = (index, parentIndex, e) => {
    let attrList = this.props.specification.attrList;
    let attrValueList = attrList[parentIndex].valueList;
    attrValueList[index].value = e.target.value;
    this.props.updateForm(Object.assign({}, this.props.specification));
  }

  // 选择项可选值点击删除按钮事件
  delTag = (index,parentIndex) => {
    let attrList = this.props.specification.attrList;
    let valueList = attrList[parentIndex].valueList;
    const valueItem = valueList[index];
    const _this = this;
    if (valueList.length === 1) {
      message.error('唯一一个规格可选值不可删除');
      return false;
    }
    if (valueItem.id) {
      confirm({
        title: '确定删除该可选值？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          axios.Specification.validateAttrValueIsUsed({id: valueItem.id}).then(res => {
            const data = res.data;
            if (data.code !== 1000) {
              return false;
            }
            valueList.splice(index, 1);
            // document.getElementById(`tag${parentIndex}_${valueList.length - 1}`).value = '';
            _this.props.updateForm(Object.assign({},_this.props.specification));
          })
        }
      });
    } else {
      valueList.splice(index, 1);
      // document.getElementById(`tag${parentIndex}_${valueList.length - 1}`).value = '';
      this.props.updateForm(Object.assign({},this.props.specification));
    }
  }
  // 删除一个规格属性
  delAttr = (index) => {
    const {specification} = this.props;
    const {attrList} = specification;
    const attrItem = attrList[index];
    const _this = this;
    if (attrList.length === 1) {
      message.error('请至少添加一条规格属性');
      return false;
    }
    if (attrItem.id) {
      confirm({
        title: '确定删除该规格属性？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          axios.Specification.validateAttrIsUsed({id: attrItem.id}).then(res => {
            const data = res.data;
            if (data.code !== 1000) {
              return false;
            }
            attrList.splice(index, 1);
            _this.props.updateForm(Object.assign({},_this.props.specification));
          })
        }
      });
    } else {
      attrList.splice(index, 1);
      this.props.updateForm(Object.assign({},this.props.specification));
    }
  }
  // 添加一个规格属性
  addSpecificationValue = () => {
    const specificationForm = this.props.specification;
    specificationForm.attrList.push({index: ++index,
      name: '',
      rename: '',
      valueList: [{value: '', isEdit: true}],
      sort: ''});
    this.props.updateForm(specificationForm);
  }
  render () {
    const {specification, visible} = this.props;
    const formItems = specification.attrList.map((k, index) => (
      <Row gutter={24} key={index} className="m-specification-row">
        <Col span={5}>
          <Input {...tableInputLayout} value={k.name} onChange={this.handleInputChange.bind(this, 'name', index)} onBlur={this.validateName}/>
        </Col>
        <Col span={5}>
          <Input {...tableInputLayout} value={k.rename} onChange={this.handleInputChange.bind(this, 'rename', index)}/>
        </Col>
        <Col span={10}>
          <div className="edit-tag-list">
            {k.valueList.map((v, valueIndex) => 
              <EditTag updateTag={this.handleChangeTag} data={v.value} key={valueIndex} parentIndex={index} index={valueIndex} isEdit={v.isEdit ? true : false} enterClick={this.enterClickTag} doubleClickTag={this.doubleClickTag} tagBlur={this.tagBlur} delTag = {this.delTag}/>
            )}
          </div>
        </Col>
        <Col span={2}>
          <Input {...tableInputLayout} value={k.sort} onChange={this.handleInputChange.bind(this, 'sort', index)}/>
        </Col>
        <Col span={2}><div className="m-table-handle-btn" onClick={this.delAttr.bind(this, index)}>删除</div></Col>
      </Row>
    ));
    return (
      <Form style={visible ? {display: 'block'} : {display: 'none'}}>
        <div className="u-border-container add-specification-step-content">
          <Button type="primary" onClick={this.addSpecificationValue}>添加一个规格属性</Button>
          <Row gutter={24} className="m-input-th">
              <Col span={5} style={{textAlign:'center'}}>
                <span>属性名</span>
              </Col>
              <Col span={5} style={{textAlign:'center'}}>
                <span>属性别名</span>
              </Col>
              <Col span={10} style={{textAlign:'center'}}>
                <span>选择项可选值</span>
              </Col>
              <Col span={2} style={{textAlign:'center'}}>
                <span>排序</span>
              </Col>
              <Col span={2} style={{textAlign:'center'}}>
                <span>操作</span>
              </Col>
          </Row>
          <div className="m-table-input">
            {formItems}
          </div>
          <div className="m-btn-group align-center">
            <Button onClick={this.back}>上一步</Button>
            <Button type="primary" onClick={this.submit}>下一步</Button>
          </div>
        </div>
      </Form>
    )
  }
}

const mapStateProps = (state) => ({
  specification: state.specification
});

const mapDispatchProps = (dispatch) => ({
  updateForm: (value) => {
    SPECIFICATION.specification = value;
    dispatch(SPECIFICATION);
  }
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(Form.create({
  name: 'add_second_form',
})(AddSecondForm));