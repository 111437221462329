import React from 'react';
import { Layout, Tabs } from 'antd';
import LeftMenu from '../../component/layout/leftMenu';
import Head from '../../component/layout/head';
import {TABS} from '../../store/action';
import {connect} from 'react-redux';
import {commonPushTabs, commonCloseTab} from '../../util/tabs';
const {Content} = Layout;
const TabPane = Tabs.TabPane;

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  // 左边菜单点击事件
  onMenuClick = (item) => {
    let tabList = Object.assign(this.props.tabList);
    this.props.pushTabs(commonPushTabs(tabList, item));
  }

  // 顶部标签页切换事件
  onTabChange = (activeKey) => {
    const tabItem = this.props.tabList.find((v) => v.authorityUrl === activeKey);
    tabItem.params = Object.assign({}, tabItem.params, {reload: false});
    this.props.pushTabs({tabList: this.props.tabList, activeKey: activeKey});
    // this.setState({activeKey});
  }

  // 顶部标签页编辑事件
  onTabEdit = (targetKey, action) => {
    if (action === 'remove') {
      this.onTabRemove(targetKey);
    }
  }

  // 顶部标签页关闭事件
  onTabRemove = (targetKey) => {
    const tabObj = commonCloseTab(this.props.tabList, targetKey);
    this.props.pushTabs(tabObj);
  }

  render () {
    return (
      <Layout className="m-content">
        <LeftMenu onMenuClick={this.onMenuClick}/>
        <Layout>
          <Head/>
          <Layout className="m-container">
            <Content className="m-main">
              {/* <Routes /> */}
              <Tabs hideAdd type="editable-card" activeKey={this.props.activeKey} onChange={this.onTabChange} onEdit = {this.onTabEdit} class="m-header-tab">
                {this.props.tabList.map(pane => <TabPane tab={pane.authorityName} key={pane.authorityUrl} closable={pane.closable}>{pane.content}</TabPane>)}
              </Tabs>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

const mapStateProps = (state) => ({
  tabList: state.main.tabList,
  activeKey: state.main.activeKey
});

const mapDispatchProps = (dispatch) => ({
  pushTabs: (value) => {
    TABS.main = {
      tabList: value.tabList,
      activeKey: value.activeKey
    }
    dispatch(TABS);
  }
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(Container);