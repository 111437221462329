import GoodsCategory from './goodsCategory';
import User from './user';
import GoodsTag from './goodsTag';
import GoodsBrand from './goodsBrand.js';
import Specification from './specification';
import Role from './role';
import Authority from './authority';
import Merchant from './merchant';
import Upload from './upload';
import Address from './address';
import PublicGoods from './publicGoods'
import FunctionalPermission from './functionalPermission'
import Finance from './finance';

export default{

  GoodsCategory, User, GoodsTag, Specification, Role, GoodsBrand, Authority, Merchant, Upload, Address, PublicGoods, FunctionalPermission,
  Finance
}