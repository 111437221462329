import React from 'react';
import { Form, Input, Button, Table, Select, Modal, message, Tag } from 'antd';
import ModelForm from './form';
import axios from '../../../api'
// import util from '../../../util';
const Option = Select.Option;
const columns = [{
  title: '品牌名称',
  align: 'center',
  dataIndex: 'brandName',
  key: 'brandName',
}, {
  title: '英文名称',
  align: 'center',
  dataIndex: 'brandEnName',
  key: 'brandEnName'
}, {
  title: '关联分类',
  align: 'center',
  dataIndex: 'categoryList',
  key: 'categoryList',
  render: categoryList => (
    <span>
      {categoryList ? categoryList.map(category => <Tag color="blue" key={category.id}>{category.categoryName}</Tag>) : '暂无关联分类'}
    </span>
  )
}, {
  title: '备注',
  align: 'center',
  dataIndex: 'remark',
  key: 'remark'
}];
const sortMap = new Map([
  ['First', 1],
  ['Second', 2],
  ['Thrid', 3],
]);
class MerchantList1 extends React.Component{
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      visibleAdd: false,
      isEdit: false,
      showSelect: false,
      selectedRowKeys: [], // Check here to configure the default column
      cities: [],
      defaultValue: '',// 表单置空
      districts: [],
      loading: true,
      updateForm: {
        brandEnName: '',
        brandName: '',
        id: '',
        brandWebsite: '',
        brandImgUrl: '',
        categoryIdList: '',
        remark: '',
      },
      searchForm: {
        page: 1,
        size: 10,
        brandName: '',
        categoryFirstId: '',
        categorySecondId: '',
        categoryThirdId: '',
      },
      firstSortList: [],
      secondSortList: [],
      thridSortList: [],
      data: [],
      newDataTags: [],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        onChange (page, size) {
          _this.handlePage(page, size);
        },
        onShowSizeChange (current, size) {
          _this.handlePage(current, size);
        }
      },
    }
  }

  componentDidMount () {
    this.getBrandsList();
    this.getLevelOneList('First');
  }
  // 杜绝input框可输入内容
  handleKeyDown(event){
    let that = this;
    if(8 === event.keyCode){
        that.state.newDataTags.pop();
        that.setState({
            newDataTags: that.state.newDataTags
        });
    }
  }
  // 选择分类
  onChangeSort = ( value, selectedOptions) => {
    if(selectedOptions.length === 3){
      if(this.state.newDataTags === undefined) {
        this.setState({
          newDataTags: []
        }, ()=> {
          this.state.newDataTags.push(selectedOptions[selectedOptions.length -1]);
          this.setState({
            showSelect: false,
            newDataTags: this.state.newDataTags
          });
        })
      } else {
        this.state.newDataTags.push(selectedOptions[selectedOptions.length -1]);
        this.setState({
          showSelect: false,
          newDataTags: this.state.newDataTags
        });
      }
    }
  }
  // 手动关闭移出tag
  removeTags = (index, e) => {
    e.preventDefault();
      let tagsList = this.state.newDataTags;
      tagsList.forEach((item, index1) => {
          if(item.id === index){
            tagsList.splice(index1,1);
          }
      });
      this.setState({
          newDataTags: tagsList
      });
  }
  // 自动回去焦点
  autoFocus = (e) => {
    this.setState({
        showSelect: !this.state.showSelect
    });
    document.getElementById("tagHiddenId").focus();
  }
  // 根据商品分类等级，商品分类上级ID获取商品分类列表
  getLevelOneList(levelLabel, parentLevelLabel = undefined) {
    const level = sortMap.get(levelLabel);
    const parentId = parentLevelLabel ? this.state.searchForm[`category${parentLevelLabel}Id`] : 0;
    axios.GoodsCategory.getCategoryList({level, parentId: parentId, name: ''}).then(res => {
      const data = res.data;
      let chooseSortObj = {};
      
      if (data.code !== 1000 || !data.result) {
        const newSearchForm = Object.assign({}, this.state.searchForm, chooseSortObj);
        if(levelLabel === 'First'){
          this.props.form.resetFields(['categoryFirstId', 'categorySecondId', 'categoryThirdId']);
          this.setState({
            searchForm: newSearchForm,
            firstSortList: [],
          });
        }else if(levelLabel === 'Second'){
          this.props.form.resetFields(['categorySecondId', 'categoryThirdId']);
          this.setState({
            searchForm: newSearchForm,
            secondSortList: []
          });
        }else if(levelLabel === 'Thrid'){
          this.props.form.resetFields(['categoryThirdId']);
          this.setState({
            searchForm: newSearchForm,
            thridSortList: [],
          });
        }
        
        return false;
      }
      if(levelLabel === 'First'){
        chooseSortObj = {categoryFirstId: '', categorySecondId: '', categoryThirdId: ''};
        const newSearchForm = Object.assign({}, this.state.searchForm, chooseSortObj);
        this.setState({
          searchForm: newSearchForm,
          firstSortList: data.result,
        });
      }
      if(levelLabel === 'Second'){
        chooseSortObj = {categorySecondId: '', categoryThirdId: ''};
        const newSearchForm = Object.assign({}, this.state.searchForm, chooseSortObj);
        this.setState({
          searchForm: newSearchForm,
          secondSortList: data.result,
        });
      }
      if(levelLabel === 'Thrid'){
        chooseSortObj = {categoryThirdId: ''};
        const newSearchForm = Object.assign({}, this.state.searchForm, chooseSortObj);
        this.setState({
          searchForm: newSearchForm,
          thridSortList: data.result,
        });
      }
    })
  }
  // 单选或多选
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }
  //分页事件
  handlePage = (page, size) => {
    const newPagination = Object.assign({}, this.state.pagination, {current: page, pageSize: size});
    this.setState({
      pagination: newPagination
    }, () => {
      this.getBrandsList();
    })
  }
  // 搜索
  handleSearch = (e) => {
    e.preventDefault();
    const values = this.props.form.getFieldsValue();
    Object.keys(values).map(item => {
     if(values[item] === undefined){
        return values[item] = '';
      }
      return values;
    });
    const newPagination = Object.assign({}, this.state.pagination, {current: 1, pageSize: 10});
    this.setState({
      searchForm: values,
      pagination: newPagination
    },() => {
      this.getBrandsList();
    });
  }
  // 获取列表页
  getBrandsList = () => {
    let { searchForm, pagination } = this.state;
    Object.keys(searchForm).map(item => {
      if(searchForm[item] === undefined){
        return searchForm[item] = '';
      }
      return searchForm;
    });
    let obj = Object.assign({}, searchForm,  {page: pagination.current, size: pagination.pageSize});
    this.setState({ selectedRowKeys: [] });
    axios.GoodsBrand.getBrandsList(obj).then(res => {
      this.setState({
        loading: false
      });
      const data = res.data;
      if (data.code !== 1000) {
        if (data.code === 2001) {
          this.setState({
            data: [],
            pagination: Object.assign(pagination, {total: 0})
          });
          return false;
        }
        this.setState({
          data: [],
          pagination: Object.assign(pagination, {total: 0})
        });
        return false;
      }
      const newPagination = Object.assign(pagination, {total: data.result.total})
      this.setState({
        data: data.result.list,
        pagination: newPagination
      });
    });
  }

  // 显示新增模态框
  showAddModal = () => {
    this.setState({
      visibleAdd: true,
      isEdit: false,
      showSelect: false,
      newDataTags: [],
      defaultValue: '',
      updateForm: {
        brandImgUrl: '',
        categoryIdList: []
      }
    })
    this.refs.ModelForm.resetFields()
  }
  
  // 取消新增模态框
  handleCancel = (e) => {
    this.setState({
      visibleAdd: false,
      isEdit: false,
    });
  }
  // 选取分类
  handleChangeSort = (type, childType, value) => {
    // console.log(type, childType, value)
    if (value) {
      if(type === 'First') {
        this.props.form.resetFields(['categorySecondId', 'categoryThirdId'])
      }
      if (type === 'Second') {
        this.props.form.resetFields(['categoryThirdId'])
      }
      let newSearchForm = {};
      if(type === 'First'){
        newSearchForm = Object.assign({}, this.state.searchForm, {[`category${type}Id`]: value,categorySecondId: '',categoryThirdId: '',});
      }else if(type === 'Second'){
        newSearchForm = Object.assign({}, this.state.searchForm, {[`category${type}Id`]: value,categoryThirdId: '',});
      }else if(type === 'Thrid'){
        newSearchForm = Object.assign({}, this.state.searchForm, {[`category${type}Id`]: value,});
      }
      this.setState({
        searchForm: newSearchForm,
      }, () => {
        this.getLevelOneList(childType, type);
      });
    } else {
      if (type === 'First') {
        this.props.form.resetFields(['categorySecondId', 'categoryThirdId'])
        this.setState({
          secondSortList: [],
          thridSortList: []
        })
      }
      if (type === 'Second') {
        this.props.form.resetFields(['categoryThirdId'])
        this.setState({
          thridSortList: []
        })
      }
    } 
  }
  // 删除确认
  deleteConfirm = () => {
    const { selectedRowKeys } = this.state;
    const _this = this;
    if(selectedRowKeys.length >= 1){
      Modal.confirm({
            title: '删除',
            content: '确定永久删除吗？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              axios.GoodsBrand.delBrandsDatas(_this.state.selectedRowKeys.toString()).then(res => {
                const data = res.data;
                if (data.code !== 1000) {
                  return false;
                }
                message.success('删除成功！');
                _this.setState({
                  selectedRowKeys: null
                });
                _this.getBrandsList();
              });
            }
      });
    } else {
      // message.error('请选择一项进行删除！');
    }
    
  }
  //编辑按钮
  handleEdit = () => {
    const { selectedRowKeys } = this.state;
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      message.warning('请先选择要编辑的标签');
      return false;
    }
    if(selectedRowKeys.length > 1) {
      message.warning('请选择一个您要编辑的标签');
      return false;
    }
    const chooseKey = this.state.selectedRowKeys[0];
    const item = this.state.data.find((v) => v.id === chooseKey);
    const { id, brandName, brandEnName, brandWebsite, brandImgUrl, categoryIdList, remark, categoryList} = item;
    // console.log(item);
    this.setState({ 
      visibleAdd: true,
      isEdit: true,
      newDataTags: categoryList,
      updateForm: {
        brandEnName,
        brandName,
        id,
        brandWebsite,
        brandImgUrl,
        categoryIdList,
        remark,
      }
    });
  }
  handleChangePreview = (files) => {
    const {file} = files;
    const form = new FormData();
    form.append('file', file);
    form.append('catalog', 1);
    axios.Upload.uploadImg(form).then(res => {
        const data = res.data;
        if (data.code !== 1000) {
            return false;
        }
        this.setState({
          updateForm: Object.assign({}, this.state.updateForm, {brandImgUrl: data.result})
        });
    });
  }
  // 保存编辑品牌
  handleUpdate = () => {
    const form = this.handleAdd.props.form;
    const { updateForm } = this.state;
    let arrBrandRelationCategoryIds = [];
    (this.state.newDataTags ? this.state.newDataTags : []).map(item => {
      arrBrandRelationCategoryIds.push(item.id);
      return arrBrandRelationCategoryIds;
    });
    if (arrBrandRelationCategoryIds.length > 0) {
      updateForm.categoryIdList = arrBrandRelationCategoryIds
    }
    form.validateFields((err, values) => {
      if (values.brandWebsite &&  values.brandWebsite.length > 500) {
        message.warn('官网地址不能超过500个字符');
        return false;
      }
      const value = Object.assign({},updateForm, values);
      value.brandImgUrl = updateForm.brandImgUrl;
      value.categoryIdList = updateForm.categoryIdList;
      if (!err) {
        axios.GoodsBrand.editBrandsDatas(value).then(res => {
          const data = res.data;
          if (data.code !== 2001 && data.code !== 1000) {
            this.setState({
                visibleAdd: false
            });
            return false;
          }else if (data.code === 1000) {
            message.info("修改商品列表成功！");
            this.setState({
              visibleAdd: false,
              selectedRowKeys: null,
            });
            form.resetFields();
            this.getBrandsList();
            return false;
          }
        });
          return;
      }
    });
  }
  // 新增品牌类表
  handleCreate = () => {
    let arrBrandRelationCategoryIds = [];
    this.state.newDataTags.map(item => {
      arrBrandRelationCategoryIds.push(item.id);
      return arrBrandRelationCategoryIds;
    });
    const form = this.handleAdd.props.form;
    form.setFieldsValue({ brandImgUrl: this.state.updateForm.brandImgUrl });
    form.setFieldsValue({ categoryIdList: arrBrandRelationCategoryIds});
    form.validateFields((err, values) => {
      if (!err) {
          // console.log(values);
          if (values.brandWebsite && values.brandWebsite.length > 500) {
            message.warn('官网地址不能超过500个字符');
            return false;
          }
          axios.GoodsBrand.addBrandsData(values).then(res => {
              const data = res.data;
              if (data.code !== 2001 && data.code !== 1000) {
                  this.setState({
                      visibleAdd: false
                  });
                  return false;
              }else if (data.code === 1000) {
                  message.info("添加商品品牌成功！");
                  this.setState({
                    visibleAdd: false,
                  });
                  form.resetFields();
                  this.getBrandsList();
                  return false;
              }
          });
          return;
      }
    });
  }
  render () {
    const { selectedRowKeys, pagination, data } = this.state;
    const { firstSortList, secondSortList, thridSortList, defaultValue  } = this.state;
    const {
      getFieldDecorator
    } = this.props.form;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <ModelForm
          ref='ModelForm'
          handleKeyDown = { this.handleKeyDown.bind(this) }
          newDataTags = { this.state.newDataTags }
          defaultValue = { defaultValue }
          onChangeSort = { this.onChangeSort }
          removeTags ={ this.removeTags }
          autoFocus = { this.autoFocus }
          showSelect = { this.state.showSelect }
          options = {this.state.firstSortList}
          wrappedComponentRef={(form) => this.handleAdd = form}
          modelTitle={this.state.isEdit ? '编辑商品品牌' : '新增商品品牌'}
          visibleAdd={this.state.visibleAdd}
          handleCancel={this.handleCancel}
          handleCreate={this.handleCreate}
          handleUpdate={this.handleUpdate}
          brandMsg={this.state.updateForm}
          handleChangePreview = {this.handleChangePreview}
        />
        <div className="search-header">
            <Form layout="inline"  onSubmit={this.handleSearch}>
              <Form.Item label="一级分类">
                {getFieldDecorator('categoryFirstId')(
                 <Select
                 allowClear
                 showSearch
                 optionFilterProp="children"
                 placeholder="请选择一级分类" 
                 style={{ width: 180 }}
                 onChange={this.handleChangeSort.bind(this, 'First', 'Second')}
               >
                 {firstSortList.map(province => <Option key={province.id} label={province.categoryName}>{province.categoryName}</Option>)}
               </Select>)}
              </Form.Item>
              <Form.Item label="二级分类">
                {getFieldDecorator('categorySecondId')(
                 <Select
                 allowClear
                 showSearch
                 optionFilterProp="children"
                 placeholder="请选择二级分类"
                 style={{ width: 180 }}
                 onChange={this.handleChangeSort.bind(this, 'Second', 'Thrid')}
               >
                 {secondSortList.map(city  => <Option key={city.id} label={city.categoryName}>{city.categoryName}</Option>)}
               </Select>)}
              </Form.Item>
              <Form.Item label="三级分类">
                {getFieldDecorator('categoryThirdId')(
                 <Select
                 allowClear
                 showSearch
                 optionFilterProp="children"
                 placeholder="请选择三级分类"
                 style={{ width: 180 }}
               >
                 {thridSortList.map(district  => <Option key={district.id} label={district.categoryName}>{district.categoryName}</Option>)}
               </Select>)}
              </Form.Item>
              <Form.Item label="品牌名称">
                {getFieldDecorator('brandName')(<Input className="m-input"  placeholder="品牌名称" />)}
              </Form.Item>
              <Form.Item>
                <Button type="primary" ghost icon="search" htmlType="submit">搜索</Button>
              </Form.Item>
            </Form>
            <div className="line-feed">
              <Button type="primary"  onClick={this.showAddModal}>添加</Button>
              <Button type="primary" onClick={this.handleEdit}>编辑</Button>
              {/* <Button type="danger" onClick={this.deleteConfirm}>删除</Button> */}
            </div>
        </div>
        <div className="search-table">
          <Table size="middle"  rowKey='id' pagination={pagination} rowSelection={rowSelection} columns={columns} dataSource={data}  loading={this.state.loading} />
        </div>
      </div>
    )
  }
};
const GoodsBrand = Form.create()(MerchantList1);
export default GoodsBrand;