import axios from './axios';
export default {
    getBrandsList(data) {
        return axios.get(`/goodsBrand/getGoodsBrandByConditionWithPage?page=${data.page}&size=${data.size}&brandName=${data.brandName}&categoryFirstId=${data.categoryFirstId}&categorySecondId=${data.categorySecondId}&categoryThirdId=${data.categoryThirdId}`);
    },
    addBrandsData(data) {
        return axios.post(`/goodsBrand/saveGoodsBrand`,data);
    },
    delBrandsDatas(ids) {
        return axios.delete(`/goodsBrand/deleteGoodsBrandById/${ids}`);
    },
    editBrandsDatas(data) {
        return axios.put('/goodsBrand/updateGoodsBrand', data);
    }
}