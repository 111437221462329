import React from 'react';
import { Modal, Input, Form, Select, message } from 'antd';
import axios from '../../../../api';
class EditClassify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.isOpen || false,
      classifyItem: props.classifyMsg
    };
    this.handleCancel = this.handleCancel.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate() {

  }
  handleOk = (e) => {
    const { classifyMsg, isAdd } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      let updateQuery = {
        id: isAdd ? undefined : classifyMsg.id,
        name: values.name,
        sortId: parseInt(values.sortId),
        parentId: !isAdd ? undefined : parseInt(values.parentId),
        categoryLevel: !isAdd ? undefined : parseInt(classifyMsg.level),
      }
      if(isAdd) {
        this.addData(updateQuery)
        return
      }
     
      console.log(updateQuery)
      this.submitData(updateQuery)
    });



  }

  submitData(updateQuery) {
    const { handleOkModal, classifyMsg } = this.props;
    axios.GoodsCategory.updateCategory(updateQuery).then(data => {
      const res = data.data
      if (res.code === 1000 && res.result) {
        message.success('操作成功');
        const query = {
          level: classifyMsg.level,
          parentId: classifyMsg.categoryParentId,
          name: ''
        }
        handleOkModal(query);
        this.props.form.resetFields();
        return;
      }
      // message.error('操作失败,'+res.chDesc);
    });
  }
  addData (addQuery) {
    const { handleOkModal, classifyMsg, isAdd } = this.props;
      axios.GoodsCategory.saveCategory(addQuery).then(data => {
        const res = data.data
        if(res.code === 1000 && res.result){
          message.success('操作成功').then(() => {
           
            
          });
          const query = {
            level: classifyMsg.level,
            parentId: classifyMsg.categoryParentId,
            name: ''
          }
          handleOkModal(query,isAdd);
        this.props.form.resetFields();
          return
        }
        // message.error('操作失败,'+res.chDesc);
      })
  }
  handleCancel = (e) => {
    const { handleCancelModal } = this.props;
    this.props.form.resetFields();
    handleCancelModal()
  }
  handleSubmit = (e) => {
    // console
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
      }
    });
  }
  handleSelectChange = (value) => {
    console.log('handleSelectChange', value);

  }
  resetFormValue = () => {

  }
  render() {
    const { isOpen, isAdd, categoryListParent, classifyMsg } = this.props;
    // console.log(categoryListParent, classifyMsg)
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    return (
      <div>
        <Modal
          title={isAdd ? "新增分类" : "编辑分类"}
          visible={isOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="50%"
        >
          <Form onSubmit={this.handleSubmit}>
            <Form.Item
              {...formItemLayout}
              label="分类名称"
            >
              {getFieldDecorator('name', {
                initialValue: classifyMsg && classifyMsg.categoryName,
                rules: [{
                  required: true, message: '请填写分类名称',
                }],
              })(
                <Input />
              )}
            </Form.Item>
            {
              isAdd && <Form.Item
              {...formItemLayout}
              label="上级分类"
            >
              {getFieldDecorator('parentId', {
                initialValue: classifyMsg && parseInt(classifyMsg.categoryParentId),
                rules: [{ required: true, message: '请选择上级分类' }],
              })(
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="请选择上级分类"
                  onChange={this.handleSelectChange}
                >
                  {
                    categoryListParent.map((item, index) => (
                      <Option key={index} value={item.value}>{item.label}</Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
            }
           
            <Form.Item
              {...formItemLayout}
              label="排序"
            >
              {getFieldDecorator('sortId', {
                initialValue: classifyMsg && classifyMsg.sortId,
                rules: [{
                  required: true,
                  pattern: new RegExp(/^[1-9]\d*$/, "g"),
                  message: '请输入正确的排序数字'
                }],
              })(
                <Input />
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const WrappedNormalLoginForm = Form.create()(EditClassify);
export default WrappedNormalLoginForm;