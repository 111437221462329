import React from 'react';
import {Form, Input, Button, Select} from 'antd';
const Option = Select.Option;
class SearchForm extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {
       
    }
  }
  
  render () {
    const {data, search, changeSort, changeThirdSort} = this.props;
    const {getFieldDecorator} = this.props.form;
    return (
      <Form layout="inline" onSubmit={search}>
        <Form.Item label="一级分类">
          {getFieldDecorator('firstSortId', {
            initialValue: data.firstSortId
          })(
            <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择一级分类" onChange={changeSort.bind(null, 'first', 'second')}>
              {data.firstSortList.map((v) => (
                <Option value={v.id} key={v.id}>{v.categoryName}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="二级分类">
          {getFieldDecorator('secondSortId', {
            initialValue: data.secondSortId
          })(
            <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择二级分类" onChange={changeSort.bind(null, 'second', 'third')}>
              {data.secondSortList.map((v) => (
                <Option value={v.id} key={v.id}>{v.categoryName}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="三级分类">
          {getFieldDecorator('thirdSortId', {
            initialValue: data.thirdSortId
          })(
            <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择三级分类" onChange={changeThirdSort}>
              {data.thirdSortList.map((v) => (
                <Option value={v.id} key={v.id}>{v.categoryName}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="关联品牌">
          {getFieldDecorator('brandId', {
            initialValue: data.brandId
          })(
            <Select optionFilterProp="children" showSearch allowClear style={{width:150}} placeholder="请选择关联品牌">
              {data.brandList.map((v) => (
                <Option value={v.id} key={v.id}>{v.brandName}</Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="类型名称">
          {getFieldDecorator('goodsSpecificationName', {
            initialValue: data.goodsSpecificationName
          })(
            <Input placeholder="请输入类型名称"/>
          )}
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" ghost>搜索</Button>
        </Form.Item>
        <Form.Item>
          <Button htmlType="button" type="primary" ghost>导出全部</Button>
        </Form.Item>
        <Form.Item>
          <Button htmlType="button" type="primary" ghost>导出选中</Button>
        </Form.Item>
      </Form>
      // <Form layout="inline" onSubmit={search}>
      //   <Form.Item label="服务支持名称">
      //     {getFieldDecorator('name', {
      //       initialValue: data.name
      //     })(
      //       <Input placeholder="请输入服务支持名称"/>
      //     )}
      //   </Form.Item>
      //   <Form.Item>
      //     <Button htmlType="submit" type="primary" ghost>搜索</Button>
      //   </Form.Item>
      // </Form>
    )
  }
}
export default Form.create('search_form')(SearchForm);