import React from 'react';
import {Modal, Form, Input, Icon, Upload} from 'antd';
const { TextArea } = Input;
class EditTabModal extends React.Component{
  render () {
    const {editTitle, editVisible, handleUpdate, handleCancel, updateServiceMsg, uploadImg, uploadAction} = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        sm: { span: 6 },
      },
      wrapperCol: {
        sm: { span: 18 },
      },
    }
    const uploadButton = (
      <div>
        <Icon type={updateServiceMsg && updateServiceMsg.uploadLoading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">上传</div>
      </div>
    );
    return (
      <Modal destroyOnClose title={editTitle} visible={editVisible} onOk={handleUpdate} onCancel={handleCancel}>
        <Form>
          <Form.Item {...formItemLayout} label="服务支持名称">
            {getFieldDecorator('serviceSupportName', {
              initialValue: updateServiceMsg.serviceSupportName,
                rules: [{
                  required: true, message: '请填写服务支持名称',
              }],
              })(
                <Input />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="服务支持图标">
            <Upload action={uploadAction} listType="picture-card" className="avatar-uploader" customRequest={uploadImg} showUploadList={false}>
              {updateServiceMsg.serviceSupportImgUrl ? <img src={updateServiceMsg.serviceSupportImgUrl} alt="图标"/> : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item {...formItemLayout} label="简介">
            {getFieldDecorator('profile', {
              initialValue: updateServiceMsg.profile,
              })(
                <TextArea rows={4}/>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="排序">
            {getFieldDecorator('sortId', {
              initialValue: updateServiceMsg.sortId,
              rules:[{
                required:false,
                pattern: new RegExp(/^(0)|([1-9]\d*)$/, "g"),
                message: '请输入正确的排序数字'
            }],
            })(
              <Input />
            )}
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}
export default Form.create('edit_service_form')(EditTabModal);