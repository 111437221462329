import axios from 'axios';
export default {
    getGoodsStandardVals (categoryId) {
        return  axios.get(`/goodsSpecification/getSpecificationAttributeListByCategoryId/${categoryId}`);
    },
    addValueByAttributeId (data) {
        return axios.post(`/goods/addValueByAttributeId`,data);
    },
    getGoodsList(data) {
        return axios.get(`/goods/getGoodsListByConditionWithPage`, {params: data});
    },
    addGoodsList (data) {
        return axios.post(`/goods/saveOrUpdateGoods`,data);
    },
    getInfo(data) {
        return axios.get(`/goods/getGoodsById/${data.id}`);
    },
    del(ids) {
        return axios.delete(`/goods/deleteImageById/${ids}`);
    },
    updateGoods(data) {
        return axios.put('/goods/updateGoods', data);
    },
    checkBarCode(barCode) {
        return axios.get(`/goods/checkBarCode?barCode=${barCode}`)
    }
}