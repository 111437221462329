import React from 'react';
import { Form, Input, Button, Table, Select, message  } from 'antd';
import UpdateForm from './components/updateForm';
import axios from '../../../api';
import moment from 'moment';
const columns = [{
  title: '商户编码',
  dataIndex: 'merchantCode',
}, {
  title: '商户名称',
  dataIndex: 'merchantName',
}, {
  title: '联系人',
  dataIndex: 'merchantContact',
}, {
  title: '联系电话',
  dataIndex: 'merchantContactPhone',
}, {
  title: '门店数',
  dataIndex: 'merchantStoreCount',
}, {
  title: '状态',
  dataIndex: 'merchantStatus',
}, {
  title: '创建时间',
  dataIndex: 'createTime',
  render: createTime => moment(createTime).format('YYYY-MM-DD')
}, {
  title: '备注',
  dataIndex: 'remark',
}];

class MerchantList extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      data: [],
      loading: false,
      editId: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    }
  }

  componentDidMount() {
    this.getMerchantPageList();
  }

  // 关闭修改弹框
  onCancel = () => {
    this.setState({
      formVisible: false
    })
  }

  // 成功添加或修改
  onOk = () => {
    this.setState({
      formVisible: false
    })
    this.getMerchantPageList();
  }

  // 点击添加
  showAddModal = () => {
    this.setState({
      editId: null,
      formVisible: true
    })
  }

  // 点击编辑
  showEditModal = () => {
    const {selectedRowKeys} = this.state;
    if (selectedRowKeys.length > 1 || selectedRowKeys.length <= 0) {
      message.info('请选择一条数据进行编辑');
      return false;
    }
    this.setState({
      editId: selectedRowKeys[0],
      formVisible: true
    })
  }

  // 表格单选，多选
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }
  
  // 条件搜索
  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          pagination: {current: 1, pageSize: 10}
        },()=>{
          this.getMerchantPageList();
        });       
      }
    });
  }

  getMerchantPageList () {
    const {pagination} = this.state;
    const {getFieldsValue} = this.props.form;
    const {merchantCode, merchantName, merchantStatus} = getFieldsValue(['merchantCode', 'merchantName', 'merchantStatus']);
    const searchQuery = {
      merchantCode: merchantCode || '',
      merchantName: merchantName || '',
      merchantStatus: merchantStatus || '',
      page: pagination.current,
      size: pagination.pageSize,
    }
    axios.Merchant.getMerchantList(searchQuery).then(res => {
      this.setState({
        loading: false,
        selectedRowKeys: []
      });
      const {data} = res;
      if (data.code !== 1000) {
        const newPagination = Object.assign(pagination, {total: 0});
        this.setState({
          data: [],
          pagination: newPagination
        });
        return false;
      }
      const newPagination = Object.assign(pagination, {total: data.result.total});
      this.setState({
        data: data.result.list,
        pagination: newPagination
      });
    })
  }
    //分页
    onPageChange = ({current, pageSize}) => {
      this.setState({
        pagination: {current, pageSize}
      }, () => {
        this.getMerchantPageList();
      });
    }
  render () {
    const { selectedRowKeys, data, pagination, formVisible, editId } = this.state;
    const {
      getFieldDecorator
    } = this.props.form;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <UpdateForm merchantId={editId} visible={formVisible} onCancel={this.onCancel} onOk={this.onOk}/>
        <div className="search-header">
            <Form layout="inline"  onSubmit={this.handleSearch}>
              <Form.Item label="商户编码">
                {getFieldDecorator('merchantCode')(<Input className="m-input" placeholder="商户编码" />)}
              </Form.Item>
              <Form.Item label="商户名称">
                {getFieldDecorator('merchantName')(<Input className="m-input"  placeholder="商户名称" />)}
              </Form.Item>
              <Form.Item label="状态">
                {getFieldDecorator('merchantStatus')(
                  <Select
                  allowClear
                  style={{ width: 160 }}
                  placeholder="请选择状态"
                >
                  <Select.Option value="0">禁用</Select.Option>
                  <Select.Option value="1">启用</Select.Option>
                </Select>
                )}
              </Form.Item>
              <Form.Item>
                <Button  type="primary" ghost icon="search" htmlType="submit">搜索</Button>
              </Form.Item>
            </Form>
            <div className="line-feed">
              <Button type="primary"  onClick={this.showAddModal}>新增</Button>
              <Button type="primary" onClick={this.showEditModal}>编辑</Button>
            </div>
        </div>
        <div className="search-table">
          <Table rowKey="id" size="middle" rowSelection={rowSelection} columns={columns} dataSource={data} pagination={pagination} onChange={this.onPageChange} />
        </div>
      </div>
    )
  }
};
export default Form.create()(MerchantList);