import React from 'react';
import { Modal, Input, Form, Radio, Select } from 'antd';
class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.isOpen || false,
      categoryListParent: [
        { label: "管理员", value: "1" }
      ]
    };
    //this.handleCancel = this.handleCancel.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate() {
  }

  handleSelectChange = (value) => {

  }
  resetFormValue = () => {

  }
  validateToNextPassword=(rule, value, callback)=>{
    let passwordValue = this.props.form.getFieldValue('password');
    if(value !== passwordValue){      
          callback("两次密码输入不一致");  
    }
    else{
       callback();
    }

}
validateToMobile=(rule, value, callback)=>{
  if(!(/^1[34578]\d{9}$/.test(value)) && value){      
        callback("请输入正确手机号");  
  }
  else{
     callback();
  }

}

  render() {
    const { isOpen, isAdd , content, roleList,handleOk,handleCancel } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    return (
      <div>
        <Modal
          title={isAdd ? "新增用户信息" : "编辑用户信息"}
          visible={isOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width="50%"
        >
          <Form>
            {/* <Form.Item
              {...formItemLayout}
              label="登录名"
            >
              {getFieldDecorator('accountNumber', {
                initialValue: content && content.accountNumber,
                rules: [{
                  required: true, message: '请填写登录名',
                }],
              })(
                <Input />
              )}
            </Form.Item> */}
            <Form.Item
              {...formItemLayout}
              label="姓名"
            >
              {getFieldDecorator('realName', {
                initialValue: content && content.realName,
                rules: [{
                  required: true, message: '请填写姓名',
                }],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="联系电话"
            >
              {getFieldDecorator('contactPhone', {
                initialValue: content && content.contactPhone,
                rules: [{
                  required: true, message: '请填写联系电话',
                }, {
                  validator: this.validateToMobile,
                }],
              })(
                <Input placeholder="请填写联系电话，作为登录账号名"/>
              )}
            </Form.Item>
              {
                isAdd &&<Form.Item
                {...formItemLayout}
                label="密码"
                extra="初始默认密码：123456"
              >
                {getFieldDecorator('password', {
                  initialValue: content && content.password,
                  rules: [{
                    required: true, message: '请填写密码',
                  }],
                })(
                  <Input type="password" />
                )}
              </Form.Item>
              }
           {
             isAdd && <Form.Item
              {...formItemLayout}
              label="重复密码"
            >
              {getFieldDecorator('resetPassword', {
                initialValue: content && content.password,
                rules: [{
                  required: true, message: '请再次填写密码',
                }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input type="password" />
              )}
            </Form.Item>
           }
            <Form.Item
              {...formItemLayout}
              label="是否有效"
            >
              {getFieldDecorator('adminManagerStatus', {
                 initialValue: content && content.adminManagerStatus,
                rules: [{ required: true, message: '请选择有效类型' }],
              })(
                <Radio.Group>
                  <Radio value="0">是</Radio>
                  <Radio value="1">否</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="角色名称"
            >
              {getFieldDecorator('roleId', {
                initialValue: content && content.roleId,
                rules: [{ required: true, message: '请选择角色名称' }],
              })(
                <Select
                  showSearch
                  allowClear
                  placeholder="请选择角色名称"
                  onChange={this.handleSelectChange}
                >
                  {
                    roleList.map((item, index) => (
                      <Option key={index} value={item.id}>{item.roleName}</Option>
                    ))
                  }
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
const WrappedNormalEditForm = Form.create()(EditForm);
export default WrappedNormalEditForm;