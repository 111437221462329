import React from 'react';
import { Button, Table, message, Modal } from 'antd';
import EditForm from './components/editForm';
import axios from '../../../api';
const confirm = Modal.confirm;
let roleIdList = [];
// 表格列字段
const columns = [
  {
    title: '角色名称',
    dataIndex: 'roleName',
    key: 'roleName',
  }, {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
  }
];
class RoleManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      queryInfo: {
        pageSize: 20
      },
      isAdd: true,
      isOpen: false,
      item:{
        id: '',
        loginName: '',
        userName: '',
        mobile: '',
        roleName: '',
        isUsed: '',
        lastLoginTime: ''
      },
      selectedRowKeys: [],
      authorityRoleList: [],
      checkedKeys:[],
      autoExpandParent: false,
      expandedKeys:[],
    }
  }
  componentDidMount() {
    this.getAuthorityList();
    this.getRoleList();
  }
  //获取列表数据
  getRoleList () {
    axios.Role.getRoleInfoList().then(res => {
      const data = res.data;
      if (data.code === 1000 && data.result) {
        this.setState({
          data: data.result,
        })
        return false;
      }
      if(data.code === 2001){
        this.setState({
          data: [],
        })
        return false;
      }
    })
  }
  // 递归获取全部权限id
  getAllRoleId = (data) => {
    data.forEach(v => {
      if (v.children) {
        this.getAllRoleId(v.children);
      }
      roleIdList = roleIdList.concat(v.id.toString());
    });
  }
  //获取全部权限列表
  getAuthorityList() {
    roleIdList = [];
    axios.Authority.getAuthorityInfoList().then(res => {
      const data = res.data;
      if (data.code === 1000 && data.result) {
        this.getAllRoleId(data.result);
        this.setState({
          authorityList: data.result,
          expandedKeys: roleIdList
        })
        return false;
      }
      if(data.code === 2001){
        this.setState({
          authorityList: [],
          expandedKeys: []
        })
        return false;
      }
    })
  }
  //获取角色关联的权限列表
  getRoleAuthorityByRoleId(roleValues){
    const _roleId = this.state.selectedRowKeys[0];
    let o2 = Object.assign({}, roleValues)
    axios.Authority.getRoleAuthorityIdListByRoleId(_roleId).then(res => {
      const data = res.data;
      if (data.code === 1000 && data.result) {
        o2.list = data.result;
        this.setState({
          item: o2,
          checkedKeys: data.result,
          treeSubmitKeys:data.result
        })
        return false;
      }
      if(data.code === 2001){
        o2.list = []
        this.setState({
          item: o2,
          checkedKeys: []
        })
        return false;
      }
    })
  }
  //刷新列表
  refreshList() {
    this.getRoleList();
  }
  //新增
  handleAdd = () => {
    this.setState({
      isOpen: true,
      isAdd: true,
      treeSubmitKeys: [],
      checkedKeys:[],
      item:null,
    })
  }
  handleEdit = () => {
    if (this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要编辑的记录');
      return false;
    }
    if (this.state.selectedRowKeys.length > 1) {
      message.warning('编辑记录只能选择一条');
      return false;
    }
    const chooseKey = this.state.selectedRowKeys[0];
    const items = this.state.data.find((v) => v.id === chooseKey);

    this.getRoleAuthorityByRoleId(items)
    this.setState({
      isOpen: true,
      isAdd: false,
    })
  }
  //删除
  handleDelete = () => {
    const _this = this;
    if (this.state.selectedRowKeys.length === 0) {
      message.warning('请先选择要删除的记录');
      return false;
    }
    let _ids = _this.state.selectedRowKeys.toString();
  
    confirm({
      title: '提示',
      content: '确认要删除选中记录吗？',
      onOk() {
        axios.Role.deleteRoleInfo(_ids).then(res => {
          const data = res.data;
          if (data.code === 1000 && data.result) {
            message.success("删除成功")
            _this.setState({
              selectedRowKeys: []
            });
            _this.refreshList();
            return false;
          }       
        });
      }
    })
  }
  // 勾选表格多选框事件
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }
  handleOk = (e) => {
    this.editForm.props.form.validateFields((err, values) => {
      if (err) {
        return false;
      }
      let obj = {
        roleName:values.roleName,
        remark: values.remark,
        list:this.state.treeSubmitKeys
      }
      this.submitData(obj);
    });
    
  }
  handleCancel = (values) => {
    this.setState({
      isAdd: true,
      isOpen: false,
    })
    this.editForm.props.form.resetFields();
  }
  onCheck = (checkedKeys, e) =>{
    const {checkedNodes} = e;
    var checkNodes = checkedNodes.filter((v) => !v.props.children);
    const checkChildrenKeys = [];
    checkNodes.forEach((v) => checkChildrenKeys.push(v.key));
    this.setState({ 
      treeSubmitKeys: checkChildrenKeys,
      checkedKeys
     },() => {
     });
  }
  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
    });
  }
  submitData = (data) => {
    const { item, isAdd } = this.state;
    const { roleName, remark, list } = data;
    const requestData = {
      roleName, 
      remark, 
      list, 
      id: isAdd ? undefined : item.id
    };
    let methodName = 'saveRoleInfo';
    let _msg = '添加成功'
    if (!isAdd) {
      methodName = 'updateRoleInfo';
      _msg = '更新成功'
    }
    axios.Role[methodName](requestData).then(res => {
      const data = res.data;
      if (data.code === 1000 && data.result) {
        message.success(_msg)
        this.editForm.props.form.resetFields();
        this.setState({
          isOpen:false,
          selectedRowKeys: [],
        },()=>{
          this.refreshList();
        })
      return false;
      }
      return false;
     
    });
  }
  render() {
    const { selectedRowKeys, data, item , isAdd, isOpen, authorityList, checkedKeys, expandedKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <div className="m-btn-group">
          <Button type="primary" onClick={this.handleAdd}>添加</Button>
          <Button type="primary" onClick={this.handleEdit}>编辑</Button>
          {/* <Button type="danger" onClick={this.handleDelete}>删除</Button> */}
        </div>
        <div>
          <Table
            pagination={false}
            loading={this.state.loading}
            rowKey={record => record.id}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
        </div>
        <EditForm wrappedComponentRef={(form) => this.editForm = form} isAdd={isAdd} isOpen={isOpen} content={item} authorityList={authorityList} handleOk={this.handleOk} handleCancel = {this.handleCancel} onCheck={this.onCheck} checkedKeys={checkedKeys}  expandedKeys={expandedKeys} onExpand={this.onExpand}/>
      </div>
    )
  }
}

export default RoleManager;