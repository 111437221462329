import axios from 'axios';
export default {
  getFunctionList () {
    return axios.get('/functionInfo/getAllMerchantFunctionList');
  },
  getVersionList () {
    return axios.get('/functionInfo/getAllVersionFunctionList');
  },
  getFunctionListByServiceId (data) {
    return axios.get(`/functionInfo/getMerchantFunctionListByServiceId?serviceId=${data.serviceId}`);
  },
  updateMerchantFunctionList (data) {
    return axios.post(`/merchantFunction/saveOrUpdateMerchantFunctionDO`, data);
  },
}