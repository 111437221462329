import React from 'react';
import {Form, Input, Button, Select} from 'antd';
const Option = Select.Option;
class SearchForm extends React.Component{
  constructor(props) {
    super(props);
  
    this.state = {
       
    }
  }
  
  render () {
    const {data, search} = this.props;
    const {getFieldDecorator} = this.props.form;
    return (
      <Form layout="inline" onSubmit={search}>
        <Form.Item label="姓名">
          {getFieldDecorator('name', {
            initialValue: data&&data.name
          })(
            <Input placeholder="请输入姓名"/>
          )}
        </Form.Item>
        <Form.Item label="联系电话">
          {getFieldDecorator('contactPhone', {
            initialValue: data&&data.contactPhone
          })(
            <Input placeholder="请输入联系电话"/>
          )}
        </Form.Item>
        <Form.Item label="是否有效">
        {getFieldDecorator('managerStatus', {
            initialValue: data&&data.managerStatus
          })(
              <Select
                showSearch
                style={{ width: 200 }}
              >
                <Option value=''>全部</Option>
                <Option value="0">是</Option>
                <Option value="1">否</Option>
              </Select>
               )}
            </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" ghost>搜索</Button>
        </Form.Item>
      </Form>
    )
  }
}
export default Form.create('search_userManager_form')(SearchForm);