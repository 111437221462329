import axios from './axios';
export default {
  /**
   * 获取商户列表
   * @param {Object} data 参数对象
   */
  getMerchantList (data) {
    return axios.get(`/merchantInfo/getMerchantInfoByConditionWithPage?merchantCode=${data.merchantCode}&merchantName=${data.merchantName}&merchantStatus=${data.merchantStatus}&page=${data.page}&size=${data.size}`);
  },
  /** 
   * 新增或修改
   */
  saveOrUpdateMerchantInfo (data) {
    return axios.post(`/merchantInfo/saveOrUpdateMerchantInfo`,data);
  },
 /**
   * 根据商户id获取商户信息
   * @param {*} id 
   */
  getMerchantInfoById (id) {
    return  axios.get(`/merchantInfo/getMerchantInfoById/${id}`);
  },
}