import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './app/login';
import Main from './app/main';
import utils from './util';
export default () => (
    <Router>
        <Switch>
            <Route exact path="/" render={() => {
                if (utils.getSessionStorage('loginUserInfo')) {
                    return <Redirect to="/main" push />
                }
                return <Redirect to="/login" push />
            }}/>
            <Route path="/main" render = {() => {
                if (utils.getSessionStorage('loginUserInfo')) {
                    return <Main/>;
                }
                return <Redirect to="/login" push />
            }}/>
            <Route path="/login" component={Login} />
            {/* <Route component={NotFound} /> */}
        </Switch>
    </Router>
)