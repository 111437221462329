import React from 'react';
import {Form, Input, Cascader, Select, Button} from 'antd';
import {SPECIFICATION} from '../../../../store/action';
import {connect} from 'react-redux';
import {pushParam} from '../../../../util/tabs';
import axios from '../../../../api';
const {TextArea} = Input;
const Option = Select.Option;
const MENUKEY = 'AddSpecification';
const sortFieldNames = {
  label: 'categoryName',
  value: 'id'
}
class AddFirstForm extends React.Component{
  // 下一步提交
  submit = () => {
    // const {specification} = this.props;
    this.props.form.validateFields((err, values) => {
      if (err) {
        return false;
      }
      // axios.Specification.validateSpecificationName({goodsSpecificationName: values.name, goodsSpecificationId: specification.id || ''}).then(res => {
      //   const data = res.data;
      //   if (data.code !== 1000) {
      //     return false;
      //   }
      this.sureSubmit(values);
      // });
    });
  }

  // 验证通过提交
  sureSubmit = (values) => {
    const {specification} = this.props;
    const {id} = specification;
    const {name, describe, sort, brand} = values;
    if (id) {
      const requestData = {
        id,
        specificationName: name,
        remark: describe,
        categoryId: sort[2],
        brandIdStr: brand.toString()
      };
      axios.Specification.updateSpecification(requestData).then(res => {
        const data = res.data;
        if (data.code !== 1000) {
          return false;
        }
        this.goNextStep(values);
      });
    } else {
      this.goNextStep(values);
    }
  }

  // 跳转下一步
  goNextStep = (values) => {
    const {tabList} = this.props;
    pushParam(tabList, MENUKEY, {reload: false});
    this.props.updateForm(Object.assign({}, this.props.specification, values, {currentStep: 1}));
  }
  
  render () {
    const {specification, visible, sortOption, brandList, changeSort, loadSort} = this.props;
    const {getFieldDecorator} = this.props.form;
    const formItemLayout = {
      labelCol: {
        sm: {span: 4}
      },
      wrapperCol: {
        sm: {span: 20}
      }
    };
    return (
      <Form style={visible ? {display: 'block'} : {display: 'none'}}>
        <div className="add-specification-step-content" style={{width:500}}>
          <Form.Item {...formItemLayout} label="类型名称">
            {getFieldDecorator('name', {
              initialValue: specification.name,
              rules: [{
                required: true, message: '请填写类型名称',
              }],
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="类型备注">
            {getFieldDecorator('describe', {
              initialValue: specification.describe,
              rules: [{
                required: false, message: '请填写类型备注',
              }],
            })(
              <TextArea rows={4}/>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="关联分类">
            {getFieldDecorator('sort', {
              initialValue: specification.sort,
              rules: [{ type: 'array',len: 3, required: true, message: '请选择关联分类' }],
            })(
              <Cascader placeholder="请选择一级分类/二级分类/三级分类" onChange={changeSort.bind(null)} changeOnSelect fieldNames={sortFieldNames} options={sortOption} allowClear={false} loadData={loadSort.bind(null)}/>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="关联品牌">
            {getFieldDecorator('brand', {
              initialValue: specification.brand,
            })(
              <Select showSearch optionFilterProp="children" mode="multiple">
                {brandList.map ((item) => 
                  <Option key={item.id} value={item.id.toString()}>{item.brandName}</Option>
                )}
              </Select>
            )}
          </Form.Item>
          <div className="m-btn-group align-center">
            <Button type="primary" onClick={this.submit}>下一步</Button>
          </div>
        </div>
      </Form>
    )
  }
}

const mapStateProps = (state) => ({
  specification: state.specification,
  tabList: state.main.tabList
});

const mapDispatchProps = (dispatch) => ({
  updateForm: (value) => {
    SPECIFICATION.specification = value;
    dispatch(SPECIFICATION);
  }
});

export default connect(
  mapStateProps,
  mapDispatchProps
)(Form.create('add_first_form')(AddFirstForm));