import axios from './axios';
export default {
  addMerchantBill(data) {
    return axios.post(`/merchantBill/saveMerchantBill`,data);
  },
  getMerchantBill(query) {
    return axios.get(`/merchantBill/getMerchantBillList?${query}`);
  },
  getMerchantCashFlowList(query) {
    return axios.get(`/merchantCashFlow/getMerchantCashFlowList?${query}`);
  },
  getMerchantPriceList(query) {
    return axios.get(`/merchantPrice/getMerchantPriceList?${query}`);
  },
}