import React, { Component } from 'react';
import axios from '../../../api';
import { Form, Input, Button, Table, Select, message } from 'antd';
import SettingModal from './components/settingModal';
import moment from 'moment';
const { Option } = Select;
const merchantStatusOptions = [
  {value: 0, label: '禁用'},
  {value: 1, label: '启用'},
];

class FunctionList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tableData: [],
      selectedRowKeys: [],
      modalVisible: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
    this.columns = [{
      title: '商户编码',
      dataIndex: 'merchantCode',
    }, {
      title: '商户名称',
      dataIndex: 'merchantName',
    }, {
      title: '联系人',
      dataIndex: 'merchantContact',
    }, {
      title: '联系电话',
      dataIndex: 'merchantContactPhone',
    }, {
      title: '门店数',
      dataIndex: 'merchantStoreCount',
    }, {
      title: '状态',
      dataIndex: 'merchantStatus',
      render: (val) => {
        return val ? '启用' : '禁用';
      }
    }, {
      title: '创建时间',
      dataIndex: 'createTime',
      render: createTime => moment(createTime).format('YYYY-MM-DD')
    }, {
      title: '备注',
      dataIndex: 'remark',
    }];
  }

  componentDidMount () {
    this.getList();
  }

  getList () {
    let {pagination} = this.state;
    let { merchantCode, merchantName, merchantStatus } = this.props.form.getFieldsValue();
    const queryParams = {
      merchantCode: merchantCode || '',
      merchantName: merchantName || '',
      merchantStatus: merchantStatus || '',
      page: pagination.current,
      size: pagination.pageSize,
    };
    this.setState({selectedRowKeys: []})
    axios.Merchant.getMerchantList(queryParams).then(({data}) => {
      if (data.code !== 1000) {
        this.setState({ tableData: [], pagination: {...pagination, total: 0} });
        return false;
      } 
      let { total, list: tableData } = data.result;
      this.setState({
        tableData,
        pagination: {...pagination, total},
      });
    });
  }

  // 搜索
  search (e) {
    e.preventDefault();
    this.reGetList(true);
  }

  //分页
  onPageChange = (pagination) => {
    this.reGetList(false, pagination);
  }

  reGetList (isInitPage, pagination = null) {
    let current = isInitPage ? 1 : pagination.current;
    this.setState({ pagination: {...this.state.pagination, current} }, () => {
      this.getList();
    });
  }

  // 展示设置弹窗
  openModal = () => {
    const {selectedRowKeys} = this.state;
    if (selectedRowKeys.length > 1 || selectedRowKeys.length <= 0) {
      message.info('请选择一条数据进行编辑');
      return false;
    }
    this.setState({
      serviceId: selectedRowKeys[0],
      modalVisible: true
    })
  }

  // 关闭设置弹框
  onCancel = () => {
    this.setState({ modalVisible: false })
  }

  // 表格单选，多选
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  render () {
    const { selectedRowKeys, tableData, pagination, modalVisible, serviceId } = this.state;
    const { getFieldDecorator } = this.props.form;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <>
        <Form layout="inline"  onSubmit={this.search.bind(this)}>
          <Form.Item label="商户编码">
            {getFieldDecorator('merchantCode')(<Input placeholder="商户编码" />)}
          </Form.Item>
          <Form.Item label="商户名称">
            {getFieldDecorator('merchantName')(<Input placeholder="商户名称" />)}
          </Form.Item>
          <Form.Item label="状态">
            {getFieldDecorator('merchantStatus')(
              <Select
                allowClear
                style={{ width: 160 }}
                placeholder="请选择状态"
              >
                { merchantStatusOptions.length > 0 && merchantStatusOptions.map(d => 
                    <Option key={d.value}>{d.label}</Option>
                )}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button  type="primary" ghost icon="search" htmlType="submit">搜索</Button>
          </Form.Item>
        </Form>
        <div className="line-feed">
          <Button type="primary" onClick={this.openModal}>权限设置</Button>
        </div>
        <Table 
          className="m-margin-top__bottom"
          rowKey="id"
          rowSelection={rowSelection}
          columns={this.columns} 
          dataSource={tableData} 
          pagination={pagination}
          onChange={this.onPageChange}
        />
        {/* 权限功能设置弹窗 */}
        <SettingModal serviceId={serviceId} visible={modalVisible} onCancel={this.onCancel}/>
      </>
    )
  }
}

export default Form.create()(FunctionList);