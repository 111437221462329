import React from 'react';
import EditClassify from './components/editClassify';
import './index.scss';
import { Card, Col, Row, Input, Button, Icon } from 'antd';
import axios from '../../../api';
class GoodsSort extends React.Component {
  constructor(props) {
    super(props.isOpen);
    this.state = {
      userName: '',
      password: '',
      isOpen: false,
      isAdd: true,
      categoryListParent: [{ label: "所有", value: 0 }],
      categoryList: [],
      categoryListTwo: [],
      categoryListThree: [],
      secondParentList: [],
      threeParentList: [],
      firstParentList: [{ label: "所有", value: 0 }],
      currentIndex: 0,
      currentIndex2: 0,
      currentIndex3: 0,
      classifyMsg: null,
      firstSearchId: 0,
      secondSearchId: '',
      threeSearchId: '',
      firstValue: '',
      secondValue: '',
      threeValue: '',
      submitQuery: {
        name: '',
        sortId: 1,
        parentId: 0
      }
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.handleOut = this.handleOut.bind(this);
  }
  componentWillMount() {
    const query = {
      level: 1,
      parentId: 0,
      name: ''
    }
    this.getDataCategoryList(query);
  }
  getCategoryList(data) {
    if (data.length > 0) {
      this.setState({
        categoryList: data,
        firstSearchId: 0,
        secondSearchId: data[0].id,
      }, () => {
        this.getSecondCategoryList(data[0].id);
      })
      return
    }
    this.setState({
      categoryList: [],
      categoryListTwo: [],
      categoryListThree: []
    })
  }
  getSecondCategoryList(parentId) {
    const secondQuery = {
      level: 2,
      parentId: parentId,
      name: ''
    }
    this.getDataCategoryList(secondQuery);
  }
  getCategorySecondList(data) {
    if (data.length > 0) {
      this.setState({
        categoryListTwo: data,
        threeSearchId: data[0].id
      }, () => {
        this.getThreeCategoryList(data[0].id)
      })
      return
    }
    this.setState({
      categoryListTwo: data,
      categoryListThree: []
    })

  }
  getCategoryThreeList(data) {
    this.setState({
      categoryListThree: data,
    })
  }
  getThreeCategoryList(parentId) {
    const threeQuery = {
      level: 3,
      parentId: parentId,
      name: ''
    }
    this.getDataCategoryList(threeQuery);
  }
  getDataCategoryList(query) {
    axios.GoodsCategory.getCategoryList(query).then(data => {
      let arr = [];
      if (data.data.code === 1000 && data.data.result) {
        arr = data.data.result;
      }
      if (query.level === 1) {
        this.getCategoryList(arr)
        return;
      }
      if (query.level === 2) {
        this.getCategorySecondList(arr)
        return;
      }
      if (query.level === 3) {
        this.getCategoryThreeList(arr)
        return;
      }
    });
  }
  // 获取上级分类
  getAllPreClassifyList(levels, parentIds) {
    let allQuery = {
      level: levels,
      parentId: parentIds,
      name: ''
    }
    axios.GoodsCategory.getCategoryList(allQuery).then(data => {
      let arr = [];
      if (data.data.code === 1000 && data.data.result) {
        arr = data.data.result;
        //mockCategoryListParent = _array.map((v,index) => ({ label: v.categoryName, value: v.id }))
      }
      if (allQuery.level === 1) {
        this.setState({
          categoryListParent: arr.map((v, index) => ({ label: v.categoryName, value: v.id }))
        }, ()=> {
          if (this.state.isAdd){
            this.setState({
              isOpen: true,
            })
          }
        })
        return;
      }
      if (allQuery.level === 2) {
        this.setState({
          categoryListParent: arr.map((v, index) => ({ label: v.categoryName, value: v.id }))
        }, () => {
          if (this.state.isAdd){
            this.setState({
              isOpen: true,
            })
          }
        })
        return;
      }
    });
  }
  // 添加
  showModal = (flag, e) => {
    let _array = [{ label: "所有", value: 0 }]
    this.setState({
      isAdd: true,
      classifyMsg: {
        categoryName: '',
        categoryParentId: flag === 2 ? this.state.secondSearchId : (flag === 3 ? this.state.threeSearchId : 0),
        goodsCount: '',
        id: '',
        sortId: '',
        level: flag,
      },
    }, () => {
      if (flag === 2 && flag) {
        this.getAllPreClassifyList(1, this.state.firstSearchId);
        return;
      }
      if (flag === 3 && flag) {
        this.getAllPreClassifyList(2, this.state.secondSearchId);
        return;
      }
      this.setState({
        categoryListParent: _array,
        isOpen: true
      })
      // console.log(this.state)
    });
  }
  // 编辑
  showeditModal = (item, flag) => {
    let mockCategoryListParent = [{ label: "所有", value: 0 }];
    this.setState({
      classifyMsg: {...item,level:flag},
      isOpen: true,
      isAdd: false
    }, () => {
      if (flag === 2 && flag) {
        this.getAllPreClassifyList(1, this.state.firstSearchId);
        return;
      }
      if (flag === 3 && flag) {
        this.getAllPreClassifyList(2, this.state.secondSearchId);
        return;
      }

      this.setState({
        categoryListParent: mockCategoryListParent,
      })
    });
  }
  handleOkModal = (e, isAdd) => {
    if (e) {
      this.setState({
        isOpen: false,
      });
    }
    if(isAdd){
      this.setState({
        currentIndex: 0,
      });
    }
    this.getDataCategoryList(e);
  }
  onselectChangeItem = (e) => {

  }
  handleCancelModal = (e) => {
    this.setState({
      isOpen: false,
    });
  }
  handleNextData = (item, index, isCLassifyNum) => {
    const actionData = new Map([
      ['1', () => {
        this.setState({
          currentIndex: index,
          secondSearchId: item.id,
          secondValue: '',
          threeValue: '',
        });
        this.getSecondCategoryList(item.id);
      }],
      ['2', () => {
        this.setState({
          currentIndex2: index,
          threeSearchId: item.id,
          threeValue: '',
        });

        this.getThreeCategoryList(item.id);
      }],
      ['3', () => {
        this.setState({
          currentIndex3: index,
        });
      }],
    ])
    let action = actionData.get(`${isCLassifyNum}`);
    action.call(this);
  }
  handleEnter = (e) => {
    e.target.children[1].style.display = 'block';
  }
  handleOut = (e) => {
    e.target.children[1].style.display = 'none';
  }
  handleEditData = (e, item, pos, flag) => {
    e.stopPropagation();
    this.showeditModal(item, flag)
    //api请求处理，回调处理是否删除成功，deleteModel()
  }
  classifyMsg = () => {
    this.setState({
      classifyMsg: this.state.classifyMsg
    })
  }

  // 获取输入框的值
  getFirstValue = (e) => {
    this.setState({
      firstValue: e.target.value
    });
  }
  getSecondValue = (e) => {
    this.setState({
      secondValue: e.target.value
    });

  }
  getThreeValue = (e) => {
    this.setState({
      threeValue: e.target.value
    });
  }
  //搜索更新
  SearchClassify = (value, isCLassifyNum) => {
    const searchQuery = {
      level: 1,
      parentId: 0,
      name: value
    }
    const actionData = new Map([
      ['1', () => {
        // 搜索更新一级分类数据
        this.getDataCategoryList(searchQuery);
      }],
      ['2', () => {
        searchQuery.level = 2;
        searchQuery.parentId = this.state.secondSearchId;
        this.getDataCategoryList(searchQuery);
        // 搜索更新二级分类数据
      }],
      ['3', () => {
        searchQuery.level = 3;
        searchQuery.parentId = this.state.threeSearchId;
        this.getDataCategoryList(searchQuery);
        // 搜索更新二级分类数据
      }],
    ])
    let action = actionData.get(`${isCLassifyNum}`)
    action.call(this)
  }
  render() {
    const Search = Input.Search;
    const { isOpen, isAdd, categoryListParent, categoryList, categoryListTwo, categoryListThree, classifyMsg, firstValue, secondValue } = this.state;
    const listItems = categoryList.map((item, index) =>
      <li onClick={() => this.handleNextData(item, index, 1)} key={item.id.toString()} className={this.state.currentIndex === index ? 'current' : ''}>
        <p>{item.categoryName}</p>
        <div className="option-area">
          <Icon type="edit" className="icon-style" onClick={(e) => this.handleEditData(e, item, index, 1)} />
        </div>
      </li>
    );
    const listItems2 = categoryListTwo.map((item, index) =>
      <li onClick={() => this.handleNextData(item, index, 2)} key={item.id.toString()} className={this.state.currentIndex2 === index ? 'current' : ''}>
        <p>{item.categoryName}</p>
        <div className="option-area">
          <Icon type="edit" className="icon-style" onClick={(e) => this.handleEditData(e, item, index, 2)} />
        </div>
      </li>
    );
    const listItems3 = categoryListThree.map((item, index) =>
      <li onClick={() => this.handleNextData(item, index, 3)} key={item.id.toString()} className={this.state.currentIndex3 === index ? 'current' : ''}>
        <p>{item.categoryName}</p>
        <div className="option-area">
          <Icon type="edit" className="icon-style" onClick={(e) => this.handleEditData(e, item, index, 3)} />
        </div>
      </li>
    );
    return (
      <div className="goods-sort-box">
        <EditClassify isOpen={isOpen} isAdd={isAdd} classifyMsg={classifyMsg} categoryListParent={categoryListParent} handleOkModal={this.handleOkModal} handleCancelModal={this.handleCancelModal} onselectChange={this.onselectChangeItem} />

        <Row>
          <Col span={8}>
            <Card title="一级分类" bordered={false}>
              <Search placeholder="输入名称查找" id="firstValue" onChange={this.getFirstValue} value={firstValue} onSearch={(value) => this.SearchClassify(value, 1)} />
              <Button type="dashed" icon="plus" block onClick={this.showModal.bind(this, 1)}>添加一级分类</Button>
              <ul className="classify-list">
                {listItems}
              </ul>
              {/* <ClassifyList  categoryList={categoryList}/> */}
            </Card>
          </Col>
          <Col span={8}>
            <Card title="二级分类" bordered={false}>
              <Search placeholder="输入名称查找" value={secondValue} onChange={this.getSecondValue} onSearch={(value) => this.SearchClassify(value, 2)} />
              <Button type="dashed" icon="plus" block onClick={this.showModal.bind(this, 2)}>添加二级分类</Button>
              <ul className="classify-list">
                {listItems2}
              </ul>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="三级分类" bordered={false}>
              <Search placeholder="输入名称查找" value={this.state.threeValue} onChange={this.getThreeValue} onSearch={(value) => this.SearchClassify(value, 3)} />
              <Button type="dashed" icon="plus" block onClick={this.showModal.bind(this, 3)}>添加三级分类</Button>
              <ul className="classify-list">
                {listItems3}
              </ul>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
export default GoodsSort;